import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  SimpleGrid,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";
import User from "assets/images/user.png";

const Testimonial = ({ children }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={"lg"}
      p={8}
      rounded={"xl"}
      align={"center"}
      pos={"relative"}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: "solid transparent",
        borderLeftWidth: 16,
        borderRight: "solid transparent",
        borderRightWidth: 16,
        borderTop: "solid",
        borderTopWidth: 16,
        borderTopColor: useColorModeValue("white", "gray.800"),
        pos: "absolute",
        bottom: "-16px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }) => {
  return (
    <Text
      as={"h3"}
      fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
      fontFamily="TrenchThin"
      fontWeight="300"
    >
      {children}
    </Text>
  );
};

const TestimonialText = ({ children }) => {
  return (
    <Text
      textAlign={"center"}
      color={useColorModeValue("gray.600", "gray.400")}
      mb="5"
      fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
      fontFamily="ManropeLight"
    >
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({ src, name, title }) => {
  return (
    <Flex align={"center"} mt={8} direction={"column"}>
      <Avatar alt={name} mb={2} />
      <Stack spacing={-1} align={"center"}>
        <Text
          fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
          fontFamily="TrenchThin"
          fontWeight="300"
        >
          {name}
        </Text>
        <Text
          color={useColorModeValue("gray.600", "gray.400")}
          fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
          fontFamily="ManropeLight"
        >
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

export default function WithSpeechBubbles() {
  return (
    <Box bg={useColorModeValue("gray.100", "brand.500")}>
      <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={"center"}>
          <Heading fontFamily="TrenchThin" fontWeight="400">
            Our Customer{" "}
            <Text as="sapn" color="brand.500">
              Speaks
            </Text>
          </Heading>
        </Stack>
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 3, lg: 3 }}
          spacing={{ base: 1, md: 4, lg: 4 }}
        >
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Seamless transaction</TestimonialHeading>
              <TestimonialText>
                Swaptrex gives me fast and seemless experience when making
                online purchases
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={User}
              name={"Famose Oyejide"}
              title={"Personal Shopper"}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Reliable Payments</TestimonialHeading>
              <TestimonialText>
                When i think about online payment at POS, i think about Swaptrex
                cards
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={User}
              name={"Ola Fashade"}
              title={"Software Developer"}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Mindblowing Service</TestimonialHeading>
              <TestimonialText>
                I have had to recommend Swaptrex cards to many. Swaptrex is the
                right choice for payment
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={User}
              name={"Femi Oyeniyi"}
              title={"Personal Shopper"}
            />
          </Testimonial>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
