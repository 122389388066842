import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Skeleton,
  Stack,
  Flex,
  Link,
  SkeletonText,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Card from "components/card/Card.js";
import Paginate from "components/navigation/Paginate.js";
import { ItemContent } from "components/menu/ItemContent";

export default function Index() {
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [notificationLoading, setNotificationLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(20);
  const [orderBy, setOrderBy] = useState("createdAt");

  const toast = useToast();
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.800", "white");
  let mainText = useColorModeValue("brand.500", "white");

  const getNotifications = async () => {
    try {
      const { data: response } = await axios.get(
        SERVER_URL +
          "/notifications?from=" +
          from +
          "&limit=" +
          limit +
          "&orderBy=" +
          orderBy +
          "&sortOrder=" +
          sortOrder,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setNotifications(response.data.notifications);
      }
      setNotificationLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
     <Box mb={{ sm: "8px", md: "0px" }} width="100%" mt="20">
          <Link
            color={mainText}
            href='#'
            bg='inherit'
            borderRadius='inherit'
            fontWeight='bold'
            fontFamily="ManropeSemiBold"
            fontSize={{base:'md',sm:'md',md:"lg",lg:"xl"}}
            _hover={{ color: { mainText } }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}>
            Notifications
          </Link>
      </Box>

      <Box>
        {notificationLoading ? (
          <LoadingNotification />
        ) : (
          <Notification notifications={notifications} />
        )}

        {notifications.length > 0 && (
          <Box width="100%" mt="5">
            <Paginate
              pageCount={pageCount}
              setFrom={setFrom}
              getRecords={getNotifications}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

const Notification = (props) => {
  const { notifications } = props;
  let menuBg = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  return (
    <>
      <Flex flexDirection="column">
        {notifications.length > 0 &&
          notifications.map((notification) => {
            return (
              <Card width="100%" p="5" columns={3} mt="3" key={notification._id}>
                <Flex>
                  <ItemContent
                    info={notification.message}
                    date={notification.createdAt} />
                </Flex>
              </Card>
            );
          })}
      </Flex>
    </>
  );
};

const LoadingNotification = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};
