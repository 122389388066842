import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Box,
  SimpleGrid,
  Skeleton,
  Stack,
  SkeletonText,
  useToast,
} from "@chakra-ui/react";
import UsersTable from "views/admin/users/components/UsersTable";
import Card from "components/card/Card.js";
import TextFilter from "components/filter/TextFilter";
import DateFilter from "components/filter/DateFilter";
const { DateTime } = require("luxon");

export default function Users() {
  const toast = useToast();
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const [date, setDate] = useState(DateTime.now().toFormat("yyyy-MM-dd"));

  const initFilterSubmit = async () => {
    try {
      setUsersLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/search",
        { searchText, createdAt: date },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsersLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setUsersLoading(false);
        setUsers(response.data);
        return;
      }
    } catch (error) {
      setUsersLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const initMakeAdmin = async (userId) => {
    try {
      setLoading(true);
      const { data: response } = await axios.patch(
        SERVER_URL + "/user/makeadmin/" + userId,
        { userType: "admin" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "User updated",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        initFilterSubmit();
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {}, []);

  return (
    <Box
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      overflow="hidden"
      width={{ base: "100%", sm: "100%", md: "100%" }}
    >
      <SimpleGrid columns={{sm:1,md:2}} w="100%" p="10px" spacing="2">
        <TextFilter
          searchText={searchText}
          setSearchText={setSearchText}
          searchTextLabel="Email/Phone/Last Name"
          initFilterSubmit={initFilterSubmit}
        />
        <DateFilter
          date={date}
          setDate={setDate}
          initFilterSubmit={initFilterSubmit}
        />
      </SimpleGrid>

      {usersLoading ? (
        <LoadingUsers />
      ) : (
        <UsersTable users={users} initMakeAdmin={initMakeAdmin} />
      )}
    </Box>
  );
}

const LoadingUsers = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};
