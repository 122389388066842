/* eslint-disable */
import React, { useState } from "react";
import {
  Stack,
  HStack,
  Center,
  Circle,
  VStack,
  Text,
  Box,
  Button,
  FormControl,
  Textarea,
  Heading,
  Icon,
  Modal,
  SimpleGrid,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components

import { FiArrowLeft, FiArrowRight  } from "react-icons/fi";
import axios from "axios";
import Card from "components/card/Card";
import Information from "./Information";
import { useSelector } from "react-redux";

export default function UsersTable(props) {
  const { transactions, getTransactions } = props;
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();

  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const cardShadow = "md";
  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.800", "white");

  const processWithdrawal = async (transferId, action) => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/transfer/processWithdrawal/" + transferId,
        { action, reason },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onClose();
        onCancelClose();
        getTransactions();
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "An error occured",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onClose();
        onCancelClose();
        getTransactions();
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      onClose();
      onCancelClose();
      getTransactions();
    }
  };
  return (
    <>
      {transaction !== null && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirm</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Card mb={{ base: "0px", "2xl": "20px" }}>
                  <Text
                    color={textColorPrimary}
                    fontWeight="bold"
                    fontSize="2xl"
                    mt="10px"
                    mb="4px"
                  >
                    Are you sure you want to process transaction
                  </Text>
                  <Text
                    color={textColorSecondary}
                    fontSize="md"
                    me="26px"
                    mb="40px"
                  >
                    Please scrutinize the transaction below, ensure the
                    transaction is looking good before approval
                  </Text>
                  <SimpleGrid columns="2" gap="20px">
                    <Information
                      boxShadow={cardShadow}
                      title="Amount"
                      value={transaction.amount+" "+transaction.currency}
                    />
                    <Information
                      boxShadow={cardShadow}
                      title="Address"
                      value={transaction.address}
                    />
                  </SimpleGrid>

                  <SimpleGrid columns="2" gap="20px" mt="3">
                    <Information
                      boxShadow={cardShadow}
                      title="First Name"
                      value={transaction.user.firstName}
                    />
                    <Information
                      boxShadow={cardShadow}
                      title="Last Name"
                      value={transaction.user.lastName}
                    />
                  </SimpleGrid>

                  <SimpleGrid columns="2" gap="20px" mt="3">
                    <Information
                      boxShadow={cardShadow}
                      title="Email"
                      value={transaction.user.email}
                    />
                    <Information
                      boxShadow={cardShadow}
                      title="Phone"
                      value={transaction.user.phone}
                    />
                  </SimpleGrid>

                 
                </Card>
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="red"
                  mr={3}
                  onClick={() => {
                    onCancelOpen();
                  }}
                >
                  Reject
                </Button>
                <Button
                  colorScheme="blue"
                  isLoading={loading}
                  loadingText="Wait.."
                  onClick={() => {
                    processWithdrawal(transaction._id, "approve");
                  }}
                >
                  Approve
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal isOpen={isCancelOpen} onClose={onCancelClose} size={"md"}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader color={textColorSecondary}>
                <Icon
                  as={FiArrowLeft}
                  onClick={onCancelClose}
                  width="20px"
                  height="20px"
                  color="white"
                />
              </ModalHeader>
              <ModalCloseButton color={textColorSecondary} />
              <ModalBody>
                <SimpleGrid>
                  <Box mt="4">
                    <Heading
                      as="h4"
                      color={textColorSecondary}
                      fontSize={{ base: "xl", lg: "2xl" }}
                    >
                      Decline Withdrawal
                    </Heading>
                  </Box>
                  <Box mt="3">
                    <Text color={textColorSecondary}>
                      Enter a reason for Withdrawal Decline
                    </Text>
                  </Box>

                  <Box mt="1">
                    <FormControl id="reason" isRequired>
                      <Textarea
                        onChange={(e) => {
                          setReason(e.target.value);
                        }}
                        placeholder="Reason for decline"
                        size="sm"
                        borderRadius="20"
                      />
                    </FormControl>
                  </Box>

                  <Box mt="3">
                    <Button
                      variant="primary"
                      color={"white"}
                      isLoading={loading}
                      loadingText={"Processing..."}
                      w="full"
                      borderRadius="20"
                      onClick={(e) => {
                        e.preventDefault();
                        processWithdrawal(transaction._id, "decline");
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </SimpleGrid>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}

      {transactions.map((transaction) => (
        <Card width="100%" p="5" columns={3} mt="3" key={transaction._id}>
          <Stack direction={{ base: "row", lg: "row" }}>
            <Box width="80%">
              <HStack columns={2} spacing="3">
                <Box w="10%" textAlign={"end"}>
                  <Center width="100%">
                    <Circle size="40px" bg="brand.500" color="white">
                      <Icon as={FiArrowRight} w="25px" h="25px" color="#fff" />
                    </Circle>
                  </Center>
                </Box>
                <Box w="50%">
                  <VStack p="0" w="100%">
                    <Box w="100%" textAlign={"start"}>
                      <Heading
                        as="h4"
                        color={textColorSecondary}
                        fontFamily="ManropeSemiBold"
                        fontWeight="normal"
                        fontSize={{
                          base: "sm",
                          sm: "sm",
                          md: "md",
                          lg: "lg",
                        }}
                      >
                        {transaction.amount} {transaction.currency}
                      </Heading>
                    </Box>
                    <Box w="100%" textAlign={"start"}>
                      <Text
                        color={textColorPrimary}
                        fontSize={{
                          base: "sm",
                          sm: "sm",
                          md: "md",
                          lg: "md",
                        }}
                      >
                        {transaction.address}
                      </Text>
                    </Box>
                  </VStack>
                </Box>
                <Box width="40%">
                  {transaction.user.phone} {transaction.user.email}
                </Box>
              </HStack>
            </Box>
            <Box width="20%" align="center" justify="center">
              <Button
                onClick={() => {
                  setTransaction(transaction);
                  onOpen();
                }}
              >
                Process
              </Button>
            </Box>
          </Stack>
        </Card>
      ))}
    </>
  );
}
