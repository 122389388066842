import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Flex,
  Box,
  Image,
  FormControl,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import axios from "axios";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/images/illustration2.png";
import Logo from "assets/images/logogold.png";
import { useDispatch } from "react-redux";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function Login() {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+234");
  const [password, setPassword] = useState("");
  const [showActivation, setShowActivation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const inputColor = useColorModeValue("brand.500", "gray.100");

  const login = async () => {
    try {
      if(!validateCaptcha(captchaToken)) {
        setCaptchaToken("");
        toast({
          title: "Invalid Captcha Entered",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }

      const userPhone = countryCode + "" + phone;
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/login",
        { phone: userPhone, password },
        {
          headers: {
            Authorization: "",
          },
          mode: "cors",
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        if (
          response.hasOwnProperty("data") &&
          response.data.hasOwnProperty("resendActivation") &&
          response.data.resendActivation
        ) {
          setEmail(response.data.email);
          setShowActivation(true);
        }
        setLoading(false);
        return;
      }
      if (response.success) {
        setLoading(false);
        if (response.data.twoFaActivated) {
          navigate("/auth/twofactor/" + response.data._id);
          return;
        } else {
          navigate("/auth/smstwofactor/" + response.data._id);
          return;
        }
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const resendActivation = async () => {
    try {
      setLoading(true);
      if (email === "") {
        toast({
          title: "We could not resend activation code, try again",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
      }

      const { data: response } = await axios.post(
        SERVER_URL + "/user/resendActivation",
        { email },
        {
          headers: {
            Authorization: "",
          },
          mode: "cors",
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setShowActivation(false);
        navigate("/auth/activate", { state: { email: email } });
        return;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(4, '#292a73', '#ffffff', 'upper');
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | Access your account</title>
        <meta
          name="description"
          content="Log in to your account to manage your virtual card, access your information, and enjoy exclusive offers"
        />
        <meta
          name="keywords"
          content="Virtual Card, virtual payment,virtual card,virtual credit card,virtual debit card,virtual visa card,virtual pay,virtual payment cards,virtual visa,generate a virtual credit card,instant virtual credit card,virtual pay card,virtual card for online payment,instant virtual card,mastercard virtual card,virtual mastercard,virtual bank card,virtual visa card instant,online virtual credit card,instant virtual debit card,generate virtual credit card,create virtual credit card"
        />
      </Helmet>
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          maxW={{ base: "100%", md: "100%" }}
          w="100%"
          h="100%"
          mb="10"
          alignItems="start"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex minH={"100vh"} width={"100%"} mt="10" justify={"center"}>
            <Stack
              spacing={8}
              mx={"auto"}
              w={{ base: "95%", sm: "95%", md: "70%", lg: "65%" }}
            >
              <Stack align={"center"}>
                <Image src={Logo} alt="Swaptrex" height="auto" width="60px" />
                {/*<Heading
                  fontSize={{ base: "19px", sm: "22px", md: "23px" }}
                  mb="5"
                >
                  Swaptrex
               </Heading>*/}
                <Text
                  fontFamily="ManropeLight"
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                >
                  We are happy to see you come back
                </Text>
                <Heading
                  fontFamily="ManropeSemiBold"
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                  textAlign={"center"}
                >
                  Sign In
                </Heading>
              </Stack>
              <Box p={2} width="100%">
                <Stack spacing={4} width="100%">
                  <HStack>
                    <Box width="30%">
                      <FormControl id="code" isRequired>
                        {/*<FormLabel fontFamily="ManropeSemiBold">
                          Phone
                         </FormLabel>*/}
                        <Input
                          type="text"
                          maxLength={4}
                          value="+234"
                          color={inputColor}
                          readOnly
                        />
                      </FormControl>
                    </Box>
                    <Box width="70%">
                      <FormControl id="phone">
                        {/*<FormLabel fontFamily="ManropeSemiBold">
                          &nbsp;
</FormLabel>*/}
                        <Input
                          type="text"
                          maxLength={10}
                          color={inputColor}
                          placeholder="8012345678"
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                      </FormControl>
                    </Box>
                  </HStack>
                  {/*<FormControl id="email" isRequired>
                  <FormLabel fontFamily="ManropeSemiBold">Email address</FormLabel>
                  <Input type="email"  maxLength={100} onChange={(e)=>{
                             setEmail(e.target.value);
                            }}/>
                          </FormControl>*/}
                  <FormControl id="password" isRequired>
                    {/*<FormLabel fontFamily="ManropeSemiBold">Password</FormLabel>*/}
                    <InputGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        maxLength={100}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        color={inputColor}
                        placeholder="Password"
                      />
                      <InputRightElement h={"full"}>
                        <Button
                          variant={"ghost"}
                          bg={useColorModeValue("brand.500", "gray.100")}
                          color={useColorModeValue("white", "brand.500")}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                        >
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <Stack spacing={10} pt={2}>
                    {showActivation && (
                      <Text
                        align={"center"}
                        onClick={(e) => {
                          e.preventDefault();
                          resendActivation();
                        }}
                      >
                        <small tetxtDecoration="underline">
                          Click here to resend activation code
                        </small>
                      </Text>
                    )}

                  <Flex columns={{ sm: 2, md: 2 }} direction={{sm:"row", md:"row"}}>
                    <Box>
                      <LoadCanvasTemplate reloadText="Reload" />
                    </Box>
                    <Box>
                      <Input
                        borderRadius="0"
                        type={"text"}
                        defaultValue={captchaToken}
                        height="30"
                        maxLength={6}
                        onChange={(e) => {
                          setCaptchaToken(e.target.value);
                        }}
                        color={inputColor}
                        placeholder="Enter Captcha"
                      />
                    </Box>
                  </Flex>

                    <Button
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      w="100%"
                      h="50"
                      isLoading={loading}
                      loadingText="Please Wait"
                      mb="24px"
                      onClick={(e) => {
                        e.preventDefault();
                        login();
                      }}
                    >
                      <b>Login</b>
                    </Button>
                  </Stack>
                  <Stack pt={0}>
                    <Text align={"center"}>
                      Don't have an account yet?{" "}
                      <Link color={"blue.400"} to="/auth/signup">
                        <u>
                          <b>Sign Up</b>
                        </u>
                      </Link>
                    </Text>
                  </Stack>
                  <Stack pt={0}>
                    <Text align={"center"}>
                      Forgot Password?{" "}
                      <Link color={"blue.400"} to="/auth/forgot">
                        <u>
                          <b>Reset Password</b>
                        </u>
                      </Link>
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Flex>
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default Login;
