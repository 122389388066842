import React from "react";
import {
  Box,
  Container,
  SimpleGrid,
  Heading,
  Image,
  Center,
  VStack,
  Text,
  Show,
} from "@chakra-ui/react";
import MockUp from "assets/images/appmockup1.png";

function HowItWorks() {
  return (
    <Container maxW={"100%"} p={10} background="brand.200">
      <Box width="100%" textAlign={"center"}>
        <Heading
          fontSize={{ base: "xl", sm: "2xl", md: "4xl" }}
          lineHeight={"100%"}
          fontFamily="TrenchThin"
          fontWeight="400"
          color="white"
        >
          <Text as="span" color="orange.600">
            Swaptrex
          </Text>{" "}
          in quick and easy steps
        </Heading>
        <Text
          mt="5"
          textAlign="center"
          fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
          fontFamily="ManropeLight"
          color="white"
        >
          Here are the simple steps to get started
        </Text>
      </Box>

      <SimpleGrid width="100%" columns={{ sm: 1, md: 2 }} mt="50">
        <Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <VStack width={{ sm: "100%", md: "89%" }}>
              <Box
                width="100%"
                borderRadius={"10"}
                background="brand.400"
                padding="10"
                pt="5"
                pb="5"
              >
                <Text
                  color="#ffffff"
                  fontWeight={"bold"}
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                  fontFamily="ManropeLight"
                >
                  Create an account
                </Text>
                <Text
                  color="#ffffff"
                  fontSize={{ base: "xs", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeLight"
                >
                  Complete the signup process on our website
                </Text>
              </Box>

              <Box
                width="100%"
                borderRadius={"10"}
                background="gray.200"
                padding="10"
                pt="5"
                pb="5"
              >
                <Text
                  color="black"
                  fontWeight={"bold"}
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                  fontFamily="ManropeLight"
                >
                  Fund your wallet
                </Text>
                <Text
                  color="black"
                  fontSize={{ base: "xs", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeLight"
                >
                  Transfer funds to your wallet using multiple options
                </Text>
              </Box>

              <Box
                width="100%"
                borderRadius={"10"}
                background="gray.200"
                padding="10"
                pt="5"
                pb="5"
              >
                <Text
                  color="black"
                  fontWeight={"bold"}
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                  fontFamily="ManropeLight"
                >
                  Fund your card
                </Text>
                <Text
                  color="black"
                  fontSize={{ base: "xs", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeLight"
                >
                  Move funds from your wallet to your virtual card
                </Text>
              </Box>

              <Box
                width="100%"
                borderRadius={"10"}
                background="gray.200"
                padding="10"
                pt="5"
                pb="5"
              >
                <Text
                  color="black"
                  fontWeight={"bold"}
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                  fontFamily="ManropeLight"
                >
                  Enjoy
                </Text>
                <Text
                  color="black"
                  fontSize={{ base: "xs", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeLight"
                >
                  Experience borderless spending
                </Text>
              </Box>
            </VStack>
          </Box>
        </Box>
        <Box>
          <Box width="100%" display="flex" alignItems="start">
            <Box
              width="100%"
              minHeight={{ sm: "300", md: "400" }}
              borderRadius="15px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Center width="100%" height="100%">
                <Image
                  src={MockUp}
                  borderRadius="10"
                  width={{ base: "100%", sm: "100%", md: "80%" }}
                  height="auto"
                  alt="Swaptrex Virtual Card"
                />
              </Center>
            </Box>
          </Box>
        </Box>
      </SimpleGrid>
    </Container>
  );
}

export default HowItWorks;
