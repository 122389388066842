import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Image,
  Stack,
  Button,
  Heading,
  CircularProgress,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/images/illustration1.png";
import Logo from "assets/images/logogold.png";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function Verify() {
  const textColor = useColorModeValue("navy.700", "white");
  const [loading, setLoading] = useState(false);
  const [activated, setActivated] = useState({});
  const { code } = useParams();
  const toast = useToast();

  const verifyEmail = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/verifyEmail",
        { code },
        {
          headers: {
            Authorization: "",
          },
          mode: "cors",
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (response.success) {
        setLoading(false);
        setActivated(response);
      }
      setActivated(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        mb="10"
        w="100%"
        h="100%"
        alignItems="start"
        justifyContent="center"
        flexDirection="column"
      >
        <Flex minH={"100vh"} width={"100%"} mt="10" justify={"center"}>
          {!loading &&
            activated.hasOwnProperty("success") &&
            activated.success && (
              <Stack
                spacing={8}
                mx={"auto"}
                w={{ base: "95%", sm: "95%", md: "70%", lg: "65%" }}
              >
                <Stack align={"center"}>
                  <Stack
                    as={Box}
                    textAlign={"center"}
                    spacing={{ base: 8, md: 14 }}
                    py={{ base: 2, md: 6 }}
                  >
                    <Image
                      src={Logo}
                      alt="Swaptrex"
                      height="auto"
                      width="70px"
                      mb="5"
                    />
                    <Heading
                      fontWeight={600}
                      fontSize={{ base: "xl", sm: "2xl", md: "4xl" }}
                      lineHeight={"70%"}
                    >
                      Hurray!!! <br />
                      <br />
                      <Text
                        as={"span"}
                        fontSize={{ base: "sm", sm: "xl", md: "2xl" }}
                        color={"green.400"}
                      >
                        Your email has been verified successfully
                      </Text>
                    </Heading>
                    <Text color={"gray.500"}>
                      You can now proceed to your account
                    </Text>
                    <Stack
                      direction={"column"}
                      spacing={3}
                      align={"center"}
                      alignSelf={"center"}
                      position={"relative"}
                    >
                      <Link to="/user/dashboard">
                        <Button
                          variant="brand"
                          bg={"green.400"}
                          rounded={"full"}
                          px={6}
                          _hover={{
                            bg: "green.500",
                          }}
                        >
                          Back to Dashboard
                        </Button>{" "}
                      </Link>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            )}

          {loading && !activated.hasOwnProperty("success") && (
            <CircularProgress isIndeterminate color="green.300" />
          )}

          {!loading &&
            activated.hasOwnProperty("success") &&
            !activated.success && (
              <Stack
                spacing={8}
                mx={"auto"}
                w={{ base: "95%", sm: "95%", md: "70%", lg: "65%" }}
              >
                <Stack align={"center"}>
                  <Stack
                    as={Box}
                    textAlign={"center"}
                    spacing={{ base: 8, md: 14 }}
                    py={{ base: 2, md: 6 }}
                  >
                    <Heading
                      fontWeight={600}
                      fontSize={{ base: "xl", sm: "2xl", md: "4xl" }}
                      lineHeight={"70%"}
                    >
                      Ooops!!! <br />
                      <br />
                      <Text
                        as={"span"}
                        fontSize={{ base: "sm", sm: "xl", md: "2xl" }}
                        color={"green.400"}
                      >
                        {activated.message}
                      </Text>
                    </Heading>

                    {/*<Text as={'span'} fontSize={{ base: 'sm', sm: 'md', md: 'lg' }} color={'blue'} textDecoration="underline">
                 Resend Activation Link
               </Text>*/}
                  </Stack>
                </Stack>
              </Stack>
            )}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Verify;
