import React from "react";
import { Icon } from "@chakra-ui/react";
import { BiHome, BiCreditCardAlt, BiTransfer, BiHistory, BiUser } from "react-icons/bi";

import UserDashboard from "views/user/dashboard";
import VirtualCard from "views/user/virtualcard";
import Transfer from "views/user/transfer";
import Transactions from "views/user/transactions";
import Profile from "views/user/profile";

const routes = [
  {
    name: "Dashboard",
    layout: "/user",
    path: "/dashboard",
    icon: <Icon as={BiHome} width="20px" height="20px" color="white" />,
    component: <UserDashboard />,
    collapse:false
  },
  {
    name: "Virtual Card",
    layout: "/user",
    path: "/virtualcard",
    icon: <Icon as={BiCreditCardAlt} width="20px" height="20px" color="white" />,
    component: <VirtualCard />,
    collapse:false
  },
  {
    name: "Transfer",
    layout: "/user",
    path: "/transfer",
    icon: <Icon as={BiTransfer} width="20px" height="20px" color="white" />,
    component: <Transfer />,
    collapse:false
  },
  {
    name: "Transactions",
    layout: "/user",
    path: "/transactions",
    icon: <Icon as={BiHistory} width="20px" height="20px" color="white" />,
    component: <Transactions />,
    collapse:false
  },
  {
    name: "Profile",
    layout: "/user",
    path: "/profile",
    icon: <Icon as={BiUser} width="20px" height="20px" color="white" />,
    component: <Profile />,
    collapse:false,
    subRoutes:['addaccount','accounts','security','password','changephone']
  }];

export default routes;

