// Chakra imports
import {
  Select,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import { useSelect } from 'react-select-search';
// Assets
export default function CustomSelect(props) {
    const {options,setBankCode,setBankName,accountNumber,getAccountInfo,loading} = props;
    const [snapshot, valueProps, optionProps] = useSelect({
        options,
        value:null,
        multiple:false,
        disabled:false,
    });
  const inputColor = useColorModeValue("brand.500", "gray.100");
  return (
    <Select placeholder='Select Bank' borderRadius="20" color={inputColor} onChange={(e)=>{
      setBankCode(e.target.value);
      setBankName(e.target.selectedOptions[0].text);
      if(accountNumber!==null){
        getAccountInfo();
      }
    }}
    isReadOnly={loading}>
     {
          options.length>0 && snapshot.options.map((option) => (
          <option value={option.bankCode} {...optionProps} 
          >{option.bankName}</option>
      ))
     }
   </Select>
  );
}
