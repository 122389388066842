// Chakra imports
import {
  Box,
  VStack,
  HStack,
  SimpleGrid,
  Flex,
  Button,
  SkeletonText,
  Circle,
  Icon,
  Text,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
// Custom components
import Card from "components/card/Card.js";
import { FiUser, FiMail, FiPhone, FiEdit } from "react-icons/fi";
import React from "react";
// Assets

export default function Basic(props) {
  const { user, resendEmailVerification, loading } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.800", "white");
  return (
    <Card align="center" p="20px">
      <Stack direction={{ base: "column", sm: "column", md: "row", lg: "row" }}>
        <Box w={{ base: "100%", sm: "100%", md: "70%", lg: "70%" }}>
          <VStack width="100%">
            <Box width="100%">
              <Flex>
                <Box
                  textAlign="right"
                  ml={{ base: "0px", sm: "0px", md: "13px", lg: "13px" }}
                >
                  <Circle height="50px" width="50px" background="brand.400" mt="1">
                    <Icon as={FiUser} height="40px" width="40px" color="#fff" />
                  </Circle>
                </Box>
                <Box ml="8px">
                  <Text
                    color={textColorPrimary}
                    my={{ base: "auto", "2xl": "10px" }}
                    mx="auto"
                    textAlign="start"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                    fontFamily="ManropeRegular"
                  >
                    Name
                  </Text>
                  <Text
                    textAlign="start"
                    color={textColorSecondary}
                    fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                    fontFamily="ManropeRegular"
                    mt={{ base: "5px", "2xl": "5px" }}
                  >
                    {user !== null ? (
                      user.firstName + " " + user.lastName
                    ) : (
                      <SkeletonText noOfLines={2} />
                    )}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box width="100%">
              <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }} mt="5">
                <Box>
                  <HStack>
                    <Box
                      ml={{ base: "2px", sm: "2px", md: "20px", lg: "20px" }}
                    >
                      <Circle width="30px" height="30px" background="brand.400">
                        <Icon
                          as={FiMail}
                          width="20px"
                          height="20px"
                          color="#fff"
                        />
                      </Circle>
                    </Box>
                    <Box>
                      <Text
                        color={textColorPrimary}
                        fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                        fontFamily="ManropeRegular"
                        my={{ base: "auto", "2xl": "10px" }}
                        textAlign="start"
                      >
                        Email
                      </Text>
                      <Text
                        fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                        fontFamily="ManropeRegular"
                        color={textColorSecondary}
                        my={{ base: "auto", "2xl": "10px" }}
                        textAlign="start"
                      >
                        {user !== null ? (
                          user.email
                        ) : (
                          <SkeletonText noOfLines={2} />
                        )}
                        <br />
                        {/*<Text fontSize="xs">{user.emailVerified? 'Email Verified': 'Not Verified'}</Text> */}
                        {user !== null && !user.emailVerified && (
                          <Button
                            colorScheme="orange"
                            size="xs"
                            onClick={(e) => {
                              e.preventDefault();
                              resendEmailVerification();
                            }}
                            isLoading={loading}
                          >
                            Verify Now
                          </Button>
                        )}
                      </Text>
                    </Box>
                  </HStack>
                </Box>

                <Box mt={{ base: "10px", sm: "10px", md: "0px", lg: "0px" }}>
                  <HStack>
                    <Box>
                      <Circle width="30px" height="30px" background="brand.400">
                        <Icon
                          as={FiPhone}
                          width="20px"
                          height="20px"
                          color="#fff"
                        />
                      </Circle>
                    </Box>
                    <Box>
                      <Text
                        color={textColorPrimary}
                        fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                        fontFamily="ManropeRegular"
                        my={{ base: "auto", "2xl": "10px" }}
                        textAlign="start"
                      >
                        Phone
                      </Text>
                      <Text
                        fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                        fontFamily="ManropeRegular"
                        color={textColorSecondary}
                        my={{ base: "auto", "2xl": "10px" }}
                        textAlign="start"
                      >
                        {user !== null ? (
                          user.phone !== null ? (
                            user.phone
                          ) : (
                            "(-----)"
                          )
                        ) : (
                          <SkeletonText noOfLines={2} />
                        )}{" "}
                        &nbsp;{" "}
                        <Link to="/user/changephone">
                          <Icon as={FiEdit} />
                        </Link>
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </SimpleGrid>
            </Box>
          </VStack>
        </Box>
      </Stack>
    </Card>
  );
}
