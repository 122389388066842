import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Flex,
  Icon,
  SimpleGrid,
  Image,
  Center,
  useColorModeValue,
  createIcon,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Pattern from "assets/images/pattern5.png";
import Card1 from "assets/images/longcard.png";
import Card2 from "assets/images/card2.png";
import { FiBell, FiShield, FiGlobe } from "react-icons/fi";

export default function CallToActionWithAnnotation() {
  return (
    <>
      <Container
        maxW="100%"
        background="#7b7dd0"
        backgroundPosition="right center"
        backgroundSize="30% auto"
        backgroundRepeat="repeat"
      >
        <Container maxW={"100%"}>
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 8, md: 6 }}
            py={{ base: 20, md: 36 }}
          >
            <Heading
              fontSize={{ base: "xl", sm: "2xl", md: "4xl" }}
              lineHeight={"100%"}
              color="white"
              fontFamily="TrenchThin"
              fontWeight="400"
            >
              Empowering{" "}
              <Text as={"span"} color="brand.600">
               Cross-Border 
              </Text> Spending Convenience
            </Heading>
            <Center w="100%" minH="40px">
              <Text
                color={"white"}
                textAlign="center"
                width={{ base: "100%", sm: "90%", md: "80%" }}
                fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                fontFamily="ManropeLight"
              >
                Swaptrex let you create USD-denominated virtual card for your
                online purchases in few easy steps. Our virtual cards are made
                specially with the right limits for you
              </Text>
            </Center>

            <Center width="100%" minH="300px" mt="5" mb="5">
              <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} width="80%">
                <Feature
                  icon={<Icon as={FiBell} w={10} h={10} />}
                  title={"Visibility into Purchases"}
                  text={
                    "Real-time notification on every transactions as it happens. See what you spend in real-time"
                  }
                />
                <Feature
                  icon={<Icon as={FiShield} w={10} h={10} />}
                  title={"One Click Fraud Prevention"}
                  text={
                    "We provide every thing you need to protect your account and card from fraud and compromise"
                  }
                />
                <Feature
                  icon={<Icon as={FiGlobe} w={10} h={10} />}
                  title={"Globally Accepted"}
                  text={
                    "You can use your virtual debit card anywhere that accepts credit cards"
                  }
                />
              </SimpleGrid>
            </Center>

            <Stack
              direction={"column"}
              spacing={3}
              align={"center"}
              alignSelf={"center"}
              position={"relative"}
            >
              <Link to="/auth/signup">
                <Button
                  color={"brand.500"}
                  bg={"white"}
                  rounded={"full"}
                  px={6}
                  mt="5"
                  _hover={{
                    bg: "#e28743",
                  }}
                >
                  Get Started
                </Button>
              </Link>
            </Stack>
          </Stack>
        </Container>
      </Container>
    </>
  );
}

const Feature = ({ title, text, icon }) => {
  return (
    <Box boxShadow="dark-lg" p="6" rounded="md" bg="#2f3084">
      <Center>
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color="brand.500"
          rounded={"full"}
          bg={"gray.100"}
          mb={1}
          shadow="md"
        >
          {icon}
        </Flex>
      </Center>
      <Center>
        <Text
          color="white"
          textAlign="center"
          fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
          fontFamily="TrenchThin"
          fontWeight="300"
        >
          {title}
        </Text>
      </Center>
      <Text
        color="white"
        textAlign="center"
        fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
        fontFamily="ManropeLight"
      >
        {text}
      </Text>
    </Box>
  );
};

const Arrow = createIcon({
  displayName: "Arrow",
  viewBox: "0 0 72 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
      fill="currentColor"
    />
  ),
});
