import React, { useState } from "react";
// Chakra imports
import { Box, HStack, VStack, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import VirtualCardBg from "assets/images/virtualcardbg.png";
// Assets

export default function VCard(props) {
  const { card, viewCard } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
   
  let transactionFee = (card!==null && card?.cardData?.balance!==null)?  ((card?.cardData?.balance/100) * 0.008): 0;
  transactionFee = transactionFee >=5 ? 5: (transactionFee <= 0.8 ? 0.8 : transactionFee);
  const chargeable = (card!==null && card?.cardData?.balance!==null) ? ((card?.cardData?.balance/100) - 1 - transactionFee): 0; 

  const CardNumberDisplay = ({ cardNumber }) => {
    const splitCardNumber = cardNumber.match(/.{1,4}/g);
    return (
      <div>
        {splitCardNumber &&
          splitCardNumber.map((group, index) => (
            <span key={index}>{group} </span>
          ))}
      </div>
    );
  };

  return (
    <>
      <Card
        align="center"
        p="0px"
        backgroundImage={`url(${VirtualCardBg})`}
        backgroundPosition="cover"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        height="250px"
        width="100%"
        zIndex="-100"
      >
        <VStack mt="80px" width="100%">
          <Box width="100%" pl="5">
            <Text
              color="#fff"
              fontWeight="bold"
              textAlign="start"
              fontSize="md"
              mt={{ base: "6px" }}
            >
              <Text as="span">
                USD {chargeable > 0 ? new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(chargeable) : new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(0.00)
                  }
                </Text>
                <Text as="span" mt="-2" ml="1" fontSize="16" fontWeight="normal" color="#E9D2F4">
                <sup>Ledger Balance $&nbsp;
                  {
                    card !== null
                    ? 
                      new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(card?.cardData?.balance / 100)
                    : "0.00"
                  }
                </sup>
                </Text>
            </Text>
            <Text
              color={textColorSecondary}
              fontSize="md"
              my={{ base: "0px", lg: "0px" }}
              mx="auto"
              textAlign="start"
            >
              {card !== null && viewCard ? (
                <CardNumberDisplay cardNumber={card?.cardData?.card_number} />
              ) : card !== null && !viewCard ? (
                "**** **** **** " + card?.card?.lastFour
              ) : (
                "**** **** **** 1234"
              )}
            </Text>
          </Box>
          <HStack columns={3} pl="5" spacing={"2"} width="100%">
            <Box width="60%">
              <Text
                color={textColorSecondary}
                my={{ base: "auto", "2xl": "10px" }}
                fontSize={{ base: "xs", sm: "sm", md: "md", lg: "sm" }}
                mx="auto"
                textAlign="start"
              >
                Card Holder
              </Text>
              <Text
                color="#ffffff"
                fontSize={{ base: "xs", sm: "sm", md: "md", lg: "sm" }}
                my={{ base: "auto", "2xl": "10px" }}
                mx="auto"
                textAlign="start"
              >
                {card !== null ? card?.cardData?.card_name : "John Doe"}
              </Text>
            </Box>
            <Box width="20%">
              <Text
                color={textColorSecondary}
                fontSize={{ base: "xs", sm: "sm", md: "sm", lg: "sm" }}
                my={{ base: "auto", "2xl": "10px" }}
                mx="auto"
                textAlign="start"
              >
                Expiry
              </Text>
              <Text
                color="#ffffff"
                fontSize={{ base: "xs", sm: "sm", md: "sm", lg: "sm" }}
                my={{ base: "auto", "2xl": "10px" }}
                mx="auto"
                textAlign="start"
              >
                {card !== null && viewCard
                  ? card?.cardData?.expiry_month + "/" + card?.cardData?.expiry_year
                  : "**/****"}
              </Text>
            </Box>
            <Box width="20%">
              <Text
                color={textColorSecondary}
                fontSize={{ base: "xs", sm: "sm", md: "sm", lg: "sm" }}
                my={{ base: "auto", "2xl": "10px" }}
                mx="auto"
                textAlign="start"
              >
                CVV
              </Text>
              <Text
                color="#ffffff"
                fontSize={{ base: "xs", sm: "sm", md: "sm", lg: "sm" }}
                my={{ base: "auto", "2xl": "10px" }}
                mx="auto"
                textAlign="start"
              >
                {card !== null && viewCard ? card?.cardData?.cvv : "***"}
              </Text>
            </Box>
          </HStack>
        </VStack>
      </Card>
      {card !== null && viewCard ? (
        <Text mt="2">
          <Text fontWeight={"bold"} as="span">
            Billing Address:
          </Text>{" "}
          {card?.cardData?.billing_address?.billing_address1}
          {", "}
          {card?.cardData?.billing_address?.billing_city}
          {", "}
          {card?.cardData?.billing_address?.state}
          {", "}
          {card?.cardData?.billing_address?.billing_country}
          {", "}
          {card?.cardData?.billing_address?.billing_zip_code}
        </Text>
      ) : (
        ""
      )}
    </>
  );
}
