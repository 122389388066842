import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Box,
  Center,
  SimpleGrid,
  Heading,
  HStack,
  Stack,
  Skeleton,
  Text,
  Flex,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  List,
  ListItem,
  ListIcon,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  Input,
  Button,
  Circle,
  SkeletonText,
  useToast,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";

import VCard from "views/user/virtualcard/components/VCard";
import Technical from "views/user/dashboard/components/Technical";
import Paginate from "components/navigation/Paginate.js";
import KycInfo from "views/user/profile/components/KycInfo";
import Transactions from "views/user/virtualcard/components/Transactions";
import Card from "components/card/Card.js";
import CardBg from "assets/images/cardbg.png";
import {
  FiPause,
  FiPlay,
  FiEye,
  FiCheck,
  FiArrowUp,
  FiArrowDown,
} from "react-icons/fi";

export default function VirtualCard() {
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [userLoading, setUserLoading] = useState(true);
  const [cardLoading, setCardLoading] = useState(true);
  const [fundCardLoading, setFundCardLoading] = useState(false);
  const [withdrawCardLoading, setWithdrawCardLoading] = useState(false);
  const [walletLoading, setWalletLoading] = useState(true);
  const [transactionLoading, setTransactionLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [transactionInfo, setTransactionInfo] = useState(false);
  const [buyTransactionInfo, setBuyTransactionInfo] = useState(false);
  const [cardTransactionLoading, setCardTransactionLoading] = useState(true);
  const [cardIssued, setCardIssued] = useState("false");

  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState("createdAt");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isWithdrawOpen,
    onOpen: onWithdrawOpen,
    onClose: onWithdrawClose,
  } = useDisclosure();

  const {
    isOpen: isFeeOpen,
    onOpen: onFeeOpen,
    onClose: onFeeClose,
  } = useDisclosure();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [amount, setAmount] = useState(0);
  const [errorBuy, setErrorBuy] = useState(false);
  const [errorBuyMessage, setErrorBuyMessage] = useState("");

  const [settings, setSettings] = useState(null);

  const [viewCard, setViewCard] = useState(false);
  const [card, setCard] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [user, setUser] = useState(null);

  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = useColorModeValue("gray.700", "gray.100");
  const inputColor = useColorModeValue("brand.500", "gray.100");
  const backgroundColor = useColorModeValue("white", "brand.700");
  const bg = useColorModeValue("brand.500", "gray.100");
  const color = useColorModeValue("white", "brand.500");
  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");

  const getUser = async () => {
    try {
      setUserLoading(true);
      const { data: response } = await axios.get(SERVER_URL + "/user/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setUser(response?.data);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setUserLoading(false);
    }
  };

  const getVirtualCard = async () => {
    try {
      setCardLoading(true);
      const { data: response } = await axios.get(
        SERVER_URL + "/wallet/virtualcard",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCardLoading(false);
      if (response.error) {
        setCardIssued(response?.issued);
        return;
      }
      if (!response.success) {
        setCardIssued(response?.issued);
        return;
      }
      if (response.success) {
        setCard(response?.data);
        setCardIssued(response?.issued);
      }
    } catch (error) {
      /*toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });*/
      setCardLoading(false);
    }
  };

  const freezeVirtualCard = async () => {
    try {
      setCardLoading(true);
      const { data: response } = await axios.get(
        SERVER_URL + "/wallet/freezeVirtualCard",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCardLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getVirtualCard();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setCardLoading(false);
    }
  };

  const unfreezeVirtualCard = async () => {
    try {
      setCardLoading(true);
      const { data: response } = await axios.get(
        SERVER_URL + "/wallet/unfreezeVirtualCard",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCardLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getVirtualCard();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setCardLoading(false);
    }
  };

  const getWallet = async () => {
    try {
      setWalletLoading(true);
      const { data: response } = await axios.get(SERVER_URL + "/wallet/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWalletLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setWallet(response?.data);
      }
    } catch (error) {
      /*toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });*/
      setWalletLoading(false);
    }
  };

  const getSettings = async () => {
    try {
      setCardTransactionLoading(true);
      const { data: response } = await axios.get(SERVER_URL + "/settings", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCardTransactionLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setSettings(response?.data);
        return;
      }
    } catch (error) {
      setCardTransactionLoading(false);
      /*toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });*/
    }
  };

  const addFunds = async () => {
    try {
      if (amount > wallet.balance) {
        toast({
          title: "You don't have sufficient wallet balance",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setFundCardLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/wallet/addfunds",
        { amount: amount },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFundCardLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onClose();
        window.location.reload();
        getVirtualCard();
        getWallet();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setFundCardLoading(false);
    }
  };

  const withdrawFunds = async () => {
    try {
      const cardBalance = card !== null ? parseFloat(card.cardData.balance) : 0;
      if (parseFloat(amount) > parseFloat(cardBalance)) {
        toast({
          title: "You don't have sufficient card balance",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (parseFloat(cardBalance) * 0.01 - parseFloat(amount) < 1) {
        toast({
          title: "Card requires 1 USD minimum balance",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setWithdrawCardLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/wallet/withdrawfunds",
        { amount: amount },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWithdrawCardLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onWithdrawClose();
        window.location.reload();
        getVirtualCard();
        getWallet();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setWithdrawCardLoading(false);
    }
  };

  const getTransactions = async () => {
    try {
      const { data: response } = await axios.get(
        SERVER_URL +
          "/transaction/cardtransactions?from=" +
          from +
          "&limit=" +
          limit +
          "&orderBy=" +
          orderBy +
          "&sortOrder=" +
          sortOrder,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setTransactions(response.data.transactions);
      }
      setTransactionLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getUser();
    getVirtualCard();
    getWallet();
    getSettings();
    getTransactions();
  }, []);


  return (
    <>
      <Modal isOpen={isFeeOpen} onClose={onFeeClose} size={"md"}>
        <ModalOverlay />
        <ModalContent color={textColorSecondary} background={backgroundColor}>
          <ModalHeader>
            <Heading
              color={textColorSecondary}
              fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
              fontFamily="ManropeSemiBold"
            >
              Fees Structure
            </Heading>
          </ModalHeader>
          <ModalCloseButton color={textColorSecondary} />
          <ModalBody>
            <Box width="100%">
              <List mt="3" spacing={2} width="100%">
                <ListItem
                  fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeRegular"
                >
                  <ListIcon as={FiCheck} color="brand.500" />
                  <Text as="span">
                    <Text as="span"> Card Loading Fee: </Text>{" "}
                    <Text as="span">2.2% {/*(Max $20) */}</Text>
                  </Text>
                </ListItem>
                <ListItem
                  fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeRegular"
                >
                  <ListIcon as={FiCheck} color="brand.500" />
                  <Text as="span">
                    <Text as="span"> POS Transaction Fee: </Text>{" "}
                    <Text as="span">0.8% (Min $0.8, Max $5)</Text>
                  </Text>
                </ListItem>
                <ListItem
                  fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeRegular"
                >
                  <ListIcon as={FiCheck} color="brand.500" />
                  <Text as="span">
                    <Text as="span"> Card Minimum Balance: </Text>{" "}
                    <Text as="span">$1</Text>
                  </Text>
                </ListItem>
              </List>
              <Text
                mt="4"
                textAlign="center"
                color={textColorPrimary}
                fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
              >
                Be sure to take into account the <b>0.8% ($0.8 minimum)</b> POS transaction fee and
                card minimum balance of <b>$1</b> when loading funds onto your card
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent color={textColorSecondary} background={backgroundColor}>
          <ModalHeader color={textColorSecondary}>Add Fund</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box align="center" width="100%">
              <Stack spacing={4} w={"full"} maxW={"md"} p={3}>
                <FormControl isInvalid={error}>
                  <FormLabel fontWeight="bold" color={textColorSecondary}>
                    USD Amount
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color={inputColor}
                      fontSize="1.2em"
                      children="$"
                    />
                    <Input
                      type="number"
                      placeholder="Enter USD amount"
                      isInvalid={error}
                      borderRadius="20"
                      color={inputColor}
                      errorBorderColor="crimson"
                      onChange={(e) => {
                        setAmount(e.target.value);
                        let fee =
                          parseFloat(e.target.value) * settings.sellRate >=
                          settings.sellFeeCap
                            ? settings.sellFeeCap
                            : parseFloat(e.target.value) * settings.sellRate;
                        let amountIncoming = parseFloat(e.target.value) - fee;
                        amountIncoming = !isNaN(amountIncoming)
                          ? amountIncoming
                          : 0;
                        
                          if(isNaN(fee)){
                            fee = 0;
                          }

                        setTransactionInfo(
                          "Loading Fee: " +
                            new Intl.NumberFormat('en-US', {style: 'decimal' ,minimumFractionDigits: 2, maximumFractionDigits: 3}).format(fee)
                           +
                            " USD, Card will be funded with " +
                            new Intl.NumberFormat('en-US', {style: 'decimal' ,minimumFractionDigits: 2, maximumFractionDigits: 3}).format(amountIncoming)
                            +
                            " USD");

                        if (parseFloat(e.target.value) > wallet.balance) {
                          setError(true);
                          setErrorMessage("Insufficient wallet balance");
                        } else {
                          setError(false);
                        }
                      }}
                    />
                  </InputGroup>
                  <FormHelperText>
                    {transactionInfo}{" "}
                    <Text
                      onClick={() => {
                        onFeeOpen();
                      }}
                      fontWeight="bold"
                      textDecoration="underline"
                    >
                      See Fees Breakdown
                    </Text>
                  </FormHelperText>
                  {error && (
                    <Center>
                      <FormErrorMessage textAlign="center">
                        {errorMessage}
                      </FormErrorMessage>
                    </Center>
                  )}
                </FormControl>

                <Button
                  isLoading={fundCardLoading}
                  loadingText={"Please wait.."}
                  disabled={error}
                  bg={bg}
                  color={color}
                  w="full"
                  borderRadius="20"
                  _hover={{
                    bg: "blue.500",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    addFunds();
                  }}
                >
                  Add Funds
                </Button>
              </Stack>
            </Box>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isWithdrawOpen} onClose={onWithdrawClose} size={"md"}>
        <ModalOverlay />
        <ModalContent color={textColorSecondary} background={backgroundColor}>
          <ModalHeader color={textColorSecondary}>Withdraw Funds</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box align="center" width="100%">
              <Stack spacing={4} w={"full"} maxW={"md"} p={3}>
                <FormControl isInvalid={errorBuy}>
                  <FormLabel fontWeight="bold" color={textColorSecondary}>
                    USD Amount
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color={inputColor}
                      fontSize="1.2em"
                      children="$"
                    />
                    <Input
                      type="number"
                      placeholder="Enter USD amount"
                      isInvalid={error}
                      color={inputColor}
                      errorBorderColor="crimson"
                      onChange={(e) => {
                        setAmount(e.target.value);

                        let fee =
                          parseFloat(e.target.value) * settings.buyRate >=
                          settings.buyFeeCap
                            ? settings.buyFeeCap
                            : parseFloat(e.target.value) * settings.buyRate;
                        let amountIncoming = parseFloat(e.target.value) - fee;
                        amountIncoming = !isNaN(amountIncoming)
                          ? amountIncoming
                          : 0;

                          if(isNaN(fee)){
                            fee = 0;
                          }

                        setBuyTransactionInfo(
                          "Fee: " +
                          new Intl.NumberFormat('en-US', {style: 'decimal' ,minimumFractionDigits: 2, maximumFractionDigits: 3}).format(fee)
                           +
                            " USD, Wallet will be funded with " +
                            new Intl.NumberFormat('en-US', {style: 'decimal' ,minimumFractionDigits: 2, maximumFractionDigits: 3}).format(amountIncoming)
                           +
                            " USD"
                        );
                        const cardBalance =
                          card !== null
                            ? parseFloat(card.cardData.balance) * 0.01
                            : 0;
                        if (parseFloat(e.target.value) > cardBalance) {
                          setErrorBuy(true);
                          setErrorBuyMessage("Insufficient card balance");
                        } else {
                          setErrorBuy(false);
                        }
                      }}
                    />
                  </InputGroup>
                  <FormHelperText>{buyTransactionInfo}</FormHelperText>
                  {errorBuy && (
                    <FormErrorMessage>{errorBuyMessage}</FormErrorMessage>
                  )}
                </FormControl>

                <Button
                  isLoading={withdrawCardLoading}
                  loadingText={"Please Wait.."}
                  disabled={errorBuy}
                  bg={bg}
                  color={color}
                  w="full"
                  borderRadius="20"
                  _hover={{
                    bg: "blue.500",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    withdrawFunds();
                  }}
                >
                  Withdraw
                </Button>
              </Stack>
            </Box>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
          gap="10px"
        >
          <Box p="0">
            <HStack
              columns={{ base: 3, sm: 3, md: 3, lg: 3, xl: 3 }}
              gap="0"
              padding="0px"
            >
              <Box w="5%" position="relative">
                <Card
                  align="center"
                  p="15px"
                  backgroundImage={`url(${CardBg})`}
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                  backgroundColor="#0A369D"
                  h="160px"
                  position="absolute"
                  top="-80px"
                  left="15px"
                ></Card>
              </Box>
              <Box w="90%" zIndex="2">
              {!cardLoading && !walletLoading?
                <VCard card={card} viewCard={viewCard} />:
                <LoadingCard />
              }
              </Box>
              <Box w="5%" position="relative">
                <Card
                  align="center"
                  p="15px"
                  backgroundImage={`url(${CardBg})`}
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                  backgroundColor="#001C55"
                  h="160px"
                  position="absolute"
                  top="-80px"
                  right="15px"
                ></Card>
              </Box>
            </HStack>
            {card !== null && (
              <Card align="center" p="20px" mt="3">
                <SimpleGrid columns={{ base: 1, md: "2" }}>
                  <Box textAlign="start" mt={{ base: 2, sm: 2 }}>
                    <Flex>
                      <Text
                        fontSize={{
                          base: "xs",
                          sm: "sm",
                          md: "md",
                          lg: "sm",
                        }}
                        my={{ base: "auto", "2xl": "10px" }}
                        color={brandColor}
                        onClick={(e) => {
                          if (viewCard) {
                            setViewCard(false);
                          } else {
                            setViewCard(true);
                          }
                        }}
                      >
                        &nbsp;
                        <Icon as={FiEye} color={brandColor} w="20px" h="20px" /> {viewCard ? "Hide Card Details" : "View Card Details"}
                      </Text>
                      {card !== null && viewCard && (
                        <Text
                          fontSize={{
                            base: "xs",
                            sm: "sm",
                            md: "md",
                            lg: "sm",
                          }}
                          my={{ base: "auto", "2xl": "10px" }}
                          color={brandColor}
                        >
                          {card.cardData.bilingAddress}
                        </Text>
                      )}
                    </Flex>
                  </Box>
                  <Box textAlign="start" mt={{ base: 2, sm: 2 }}>
                    <Flex>
                      {card !== null && !card.card.frozen && (
                        <>
                          <Text
                            fontSize={{
                              base: "xs",
                              sm: "sm",
                              md: "md",
                              lg: "sm",
                            }}
                            my={{ base: "auto", "2xl": "10px" }}
                            color={brandColor}
                            onClick={freezeVirtualCard}
                          >
                           <Icon
                            as={FiPause}
                            color={brandColor}
                            w="20px"
                            h="20px"
                          /> &nbsp;Freeze Card
                          </Text>
                        </>
                      )}

                      {card !== null && card.card.frozen && (
                        <>
                          <Icon
                            as={FiPlay}
                            color={brandColor}
                            w="20px"
                            h="20px"
                          />
                          <Text
                            fontSize={{
                              base: "xs",
                              sm: "sm",
                              md: "md",
                              lg: "sm",
                            }}
                            my={{ base: "auto", "2xl": "10px" }}
                            color={brandColor}
                            onClick={unfreezeVirtualCard}
                          >
                            &nbsp;Unfreeze Card
                          </Text>
                        </>
                      )}
                    </Flex>
                  </Box>
                </SimpleGrid>
              </Card>
            )}
          </Box>
          <Box
            p={{ base: "2px", lg: "2px" }}
            pl={{ base: "0px", sm: "0px", md: "10px", lg: "10px" }}
          >
            {userLoading || cardLoading || walletLoading ? (
              <LoadingCard />
            ) : card !== null ? (
              <>
                <Card align="center" p="20px" backgroundColor="#051d6d">
                  <SimpleGrid
                    spacing="0"
                    minh="170px"
                    columns={{ sm: 1, md: 2 }}
                  >
                    <Box
                      h="100%"
                      backgroundColor="#051d6d"
                      p="5"
                      borderLeftRadius="20px"
                    >
                      <HStack>
                        <Box w="70%">
                          <Text
                            color="#fff"
                            fontSize="md"
                            my={{ base: "auto", "2xl": "10px" }}
                            mx="auto"
                            fontWeight="bold"
                            textAlign="start"
                          >
                            Top Up
                          </Text>
                          <Text
                            color={"white"}
                            fontSize={{
                              base: "xs",
                              sm: "sm",
                              md: "md",
                              lg: "sm",
                            }}
                            my={{ base: "auto", "2xl": "10px" }}
                            mx="auto"
                            textAlign="start"
                          >
                            Add funds to your card from your wallet
                          </Text>
                        </Box>
                        <Box w="30%" onClick={onOpen}>
                          <Circle size="40px" bg="#fff" color="white">
                            <Icon
                              as={FiArrowUp}
                              w="25px"
                              h="25px"
                              color="blue"
                            />
                          </Circle>
                        </Box>
                      </HStack>
                    </Box>

                    <Box
                      h="100%"
                      backgroundColor="#051d6d"
                      p="5"
                      borderRightRadius="20px"
                    >
                      <HStack>
                        <Box w="70%">
                          <Text
                            color="#fff"
                            fontSize="md"
                            fontWeight="bold"
                            my={{ base: "auto", "2xl": "10px" }}
                            mx="auto"
                            textAlign="start"
                          >
                            Withdraw
                          </Text>
                          <Text
                            color={"white"}
                            fontSize={{
                              base: "xs",
                              sm: "sm",
                              md: "md",
                              lg: "sm",
                            }}
                            my={{ base: "auto", "2xl": "10px" }}
                            mx="auto"
                            textAlign="start"
                          >
                            Remove funds from your card to your wallet
                          </Text>
                        </Box>
                        <Box w="30%" onClick={onWithdrawOpen}>
                          <Circle size="40px" bg="white" color="white">
                            <Icon
                              as={FiArrowDown}
                              w="25px"
                              h="25px"
                              color="blue"
                            />
                          </Circle>
                        </Box>
                      </HStack>
                    </Box>
                  </SimpleGrid>
                </Card>
              </>
            ) : (
              ((wallet !== null &&
                user !== null &&
                user.kycStatus !== "completed") ||
                (wallet !== null && user !== null && card === null)) &&
              (cardIssued === "false" ? (
                <KycInfo
                  wallet={wallet}
                  user={user}
                  card={card}
                  getVirtualCard={getVirtualCard}
                  getUser={getUser}
                  settings={settings}
                  cardLoading={cardLoading}
                  page="virtualcard"
                  onFundingOpen={""}
                />
              ) : (
                <Technical user={user} />
              ))
            )}
          </Box>
        </SimpleGrid>

        <Box mt="5">
          {transactionLoading ? (
            <LoadingTransaction />
          ) : (
            <Transactions transactions={transactions} />
          )}
          {transactions.length > 0 && (
            <Box width="100%" mt="5">
              <Paginate
                pageCount={pageCount}
                setFrom={setFrom}
                getRecords={getTransactions}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

const LoadingCard = () => {
  return (
    <Card align="center" p="20px">
      <Stack>
        <Skeleton height="30px" />
        <SkeletonText mt="5" noOfLines={5} spacing="4" />
        <Skeleton height="30px" />
      </Stack>
    </Card>
  );
};

const LoadingTransaction = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};
