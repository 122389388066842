import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Box,
  SimpleGrid,
  Center,
  Button,
  Text,
  Heading,
  Icon,
  Modal,
  Stack,
  Skeleton,
  SkeletonText,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiPlus, FiArrowLeft } from "react-icons/fi";

import Card from "components/card/Card.js";
import Bank from "./components/Bank.js";
import Paginate from "components/navigation/Paginate.js";

export default function Banks() {
  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.800", "white");

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pageCount, setPageCount] = useState(0);
  const [accountId, setAccountId] = useState(null);
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(20);
  const [accounts, setAccounts] = useState([]);

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();

  const getRecords = async (from) => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/accounts",
        { from, limit },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setAccounts(response.data.accounts);
      }
      setLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const deleteAccount = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/deleteaccount",
        { accountId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        getRecords(from);
        setLoading(false);
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getRecords(from);
  }, []);

  useEffect(() => {}, [accountId]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={textColorSecondary}>
            <Icon
              as={FiArrowLeft}
              onClick={onClose}
              width="20px"
              height="20px"
              color={textColorSecondary}
            />
          </ModalHeader>
          <ModalCloseButton color={textColorSecondary} />
          <ModalBody>
            <SimpleGrid>
              <Box textAlign="center">
                <Heading
                  fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}
                >
                  Are you sure you want to delete this?
                </Heading>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="primary"
              background="brand.500"
              isLoading={loading}
              loadingText="Please Wait"
              onClick={(e) => {
                e.preventDefault();
                deleteAccount();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid columns="1" spacing="2">
          <Box textAlign="right">
            <Link to="/user/addaccount">
              <Button
                fontSize={{ base: "xs", sm: "xs", md: "sm", lg: "sm" }}
                rightIcon={
                  <Icon as={FiPlus} width="15px" height="15px" color="white" />
                }
                variant="primary"
                background="brand.500"
              >
                Create Beneficiary
              </Button>
            </Link>
          </Box>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, sm: 1, md: 3, lg: 3 }} spacing="2">
          {accounts.length > 0 &&
            accounts.map((account) => (
              <Bank
                key={account.accountNumber}
                account={account}
                onOpen={onOpen}
                setAccountId={setAccountId}
              />
            ))}

          {loading && <AccountsLoading />}
        </SimpleGrid>
        <SimpleGrid columns="1" mt="10">
          <Box width="100%" textAlign="center">
            <Center width="100%">
              {accounts.length > 0 && (
                <Box>
                  <Paginate
                    pageCount={pageCount}
                    setFrom={setFrom}
                    getRecords={getRecords}
                  />
                </Box>
              )}
            </Center>
          </Box>
          {!loading && accounts.length === 0 && (
            <Box width="100%" mt="10" textAlign="center">
              <Center width="100%">
                <Text>No beneficiary added yet</Text>
              </Center>
            </Box>
          )}
        </SimpleGrid>
      </Box>
    </>
  );
}

const AccountsLoading = () => {
  return (
    <>
      <Card align="center" p="20px" mt="10px">
        <Stack>
          <Skeleton height="30px" />
          <SkeletonText mt="5" noOfLines={5} spacing="4" />
          <Skeleton height="30px" />
        </Stack>
      </Card>
      <Card align="center" p="20px" mt="10px">
        <Stack>
          <Skeleton height="30px" />
          <SkeletonText mt="5" noOfLines={5} spacing="4" />
          <Skeleton height="30px" />
        </Stack>
      </Card>
    </>
  );
};
