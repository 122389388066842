import React from "react";
// Chakra imports
import {
  Box,
  SimpleGrid,
  Hide,
  Show,
  HStack,
  Stack,
  Flex,
  Tag,
  Center,
  Icon,
  Text,
  useColorModeValue,
  Circle,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { FiArrowUp, FiArrowDown } from "react-icons/fi";
// Custom components
import Card from "components/card/Card.js";
// Assets
import { MdUpload } from "react-icons/md";
const { DateTime } = require("luxon");

export default function Transactions(props) {
  const { transactions, ...rest } = props;
  // Chakra Color Mode
  const colors = {
    completed: "#00798c",
    pending: "#F79327",
    cancelled: "#d1495b",
  };

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromISO(timestamp);
    return myDateTime.toLocaleString(DateTime.DATETIME_MED);
  };

  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.800", "white");
  return (
    <>
      {transactions !== null &&
        transactions.map((transaction) => (
          <Card width="100%" p="5" mt="3" key={transaction._id}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="4">
              <Box>
                <Flex>
                  <Circle size="40px" bg="brand.500" color="white">
                    {transaction.leg !== null &&
                    transaction.leg === "credit" ? (
                      <Icon as={FiArrowDown} w="25px" h="25px" color="#fff" />
                    ) : (
                      <Icon as={FiArrowUp} w="25px" h="25px" color="#fff" />
                    )}
                  </Circle>

                  <VStack p="0" w="80%" ml="2">
                    <Box w="100%" textAlign={"start"}>
                      <Heading
                        as="h4"
                        color={textColorSecondary}
                        fontFamily="ManropeSemiBold"
                        fontWeight="normal"
                        fontSize={{
                          base: "sm",
                          sm: "sm",
                          md: "md",
                          lg: "lg",
                        }}
                      >
                        {transaction.type}
                      </Heading>
                    </Box>
                    <Box w="100%" textAlign={"start"}>
                      <Text
                        color={textColorPrimary}
                        fontSize={{
                          base: "sm",
                          sm: "sm",
                          md: "md",
                          lg: "md",
                        }}
                      >
                        {" "}
                        {formatTime(transaction.createdAt)}{" "}
                      </Text>
                    </Box>
                  </VStack>

                  <Show above="sm">
                    <Text
                      below="md"
                      fontWeight={600}
                      color={textColorSecondary}
                      fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                    >
                      <Text as="span"> 
                      {new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(transaction.amount)} </Text>
                     <Text fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "md" }} as="span">USD</Text>
                    </Text>
                  </Show>
                </Flex>
              </Box>

              <Box>
                <Flex width="100%" mt={{sm:3, md:0}}>
                  <Box width="50%">
                    <Hide above="sm">
                      <Text
                        fontWeight={600}
                        color={textColorSecondary}
                        fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                      >
                       <Text as="span"> {new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(transaction.amount)}
                        </Text>
                        <Text as="span">{" USD"}</Text>
                      </Text>
                    </Hide>
                    <Text
                      color={textColorPrimary}
                      fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                      mt="0"
                      ml={{sm:12, md:0}}
                    >
                      {transaction.narration}
                    </Text>
                  </Box>
                  <Box width="50%" textAlign="right">
                    <Tag
                      mt="3"
                      colorScheme="blue"
                      background={
                        transaction.status !== null
                          ? colors[transaction.status]
                          : colors["completed"]
                      }
                      color="white"
                      borderRadius="full"
                      fontSize="8"
                      size="sm"
                    >
                      {transaction.status}
                    </Tag>
                  </Box>
                </Flex>
              </Box>
            </SimpleGrid>
          </Card>
        ))}
    </>
  );
}
