import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Box,
  SimpleGrid,
  Button,
  HStack,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  Flex,
  Image,
  Heading,
  Stack,
  Center,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";

import Card from "components/card/Card.js";

export default function ConfirmTransfer() {
  const navigate = useNavigate();
  const { state } = useLocation();
  // Access the state variables
  const { amount, account } = state || {};
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);
  const [walletLoading, setWalletLoading] = useState(true);
  const [wallet, setWallet] = useState(null);
  const [rate, setRate] = useState(null);
  const [settings, setSettings] = useState(null);
  const [fee, setFee] = useState(0);
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");
  const [otp, setOtp] = useState("");

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();

  const getWallet = async () => {
    try {
      const { data: response } = await axios.get(SERVER_URL + "/wallet/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setWalletLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setWalletLoading(false);
        return;
      }
      if (response.success) {
        setWallet(response.data);
        setWalletLoading(false);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const transerFund = async () => {
    try {
      if (parseFloat(amount) > wallet.balance) {
        toast({
          title: "You have insufficient balance to make this transfer",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (parseFloat(amount) < 1) {
        toast({
          title: "Minimum withdrawal is 1 USD",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        })
        return;
      }
      if (otp === "") {
        toast({
          title:
            "Please enter your 6 Digit Pin or go to settings to create one",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/transferfiat",
        { amount, account, otp },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        navigate("/user/transfer/index");
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getSettings = async () => {
    try {
      const { data: response } = await axios.get(SERVER_URL + "/settings", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.success) {
        setSettings(response.data);
        const fee = parseFloat(amount) * response.data.withdrawalFee + 0.1;
        setFee(fee);
      }
    } catch (error) {}
  };

  const getRate = async () => {
    try {
      const { data: response } = await axios.get(SERVER_URL + "/rate", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.success) {
        const rate = response.data - 20;
        setRate(rate);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getWallet();
    getSettings();
    getRate();
  }, []);

  useEffect(() => {
    setOtp(pin1 + "" + pin2 + "" + pin3 + "" + pin4 + "" + pin5 + "" + pin6);
  }, [pin1, pin2, pin3, pin4, pin5, pin6, otp]);

  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.700", "gray.100");
  const inputColor = useColorModeValue("brand.500", "gray.100");
  const backgroundColor = useColorModeValue("white", "brand.700");
  const bg = useColorModeValue("brand.500", "gray.100");
  const color = useColorModeValue("white", "brand.500");

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }}>
          <Card p="20px">
            <Box width="100%" textAlign="center">
              <Heading size="md" fontWeight="bold" fontFamily="ManropeSemiBold">
                Withdrawal Summary
              </Heading>
            </Box>

            <Box
              width="100%"
              mt="5"
              p="5"
              background="gray.200"
              borderRadius={15}
            >
              <Box>
                <Text
                  fontSize={{
                    base: "sm",
                    sm: "sm",
                    md: "md",
                    lg: "md",
                  }}
                  color="brand.700"
                  fontFamily="ManropeSemiBold"
                >
                  Sending
                </Text>
              </Box>
              <HStack mt="3">
                <Box width="50%">
                  <Text
                    fontSize={{
                      base: "sm",
                      sm: "sm",
                      md: "md",
                      lg: "md",
                    }}
                    fontWeight="bold"
                    fontFamily="ManropeRegular"
                  >
                    Amount
                  </Text>
                </Box>
                <Box width="50%">
                  <Text
                    fontSize={{
                      base: "sm",
                      sm: "sm",
                      md: "md",
                      lg: "md",
                    }}
                    textAlign="right"
                    fontWeight="bold"
                    fontFamily="ManropeRegular"
                  >
                    $ {amount}
                  </Text>
                </Box>
              </HStack>
            </Box>

            <Box
              width="100%"
              mt="5"
              p="5"
              background="gray.200"
              borderRadius={15}
            >
              <Box>
                <Text
                  fontSize={{
                    base: "sm",
                    sm: "sm",
                    md: "md",
                    lg: "md",
                  }}
                  color="brand.700"
                  fontFamily="ManropeSemiBold"
                >
                  Receiving
                </Text>
              </Box>
              <HStack mt="2">
                <Box width="50%">
                  <Text
                    fontSize={{
                      base: "sm",
                      sm: "sm",
                      md: "md",
                      lg: "md",
                    }}
                    fontWeight="bold"
                    fontFamily="ManropeRegular"
                  >
                    Amount
                  </Text>
                </Box>
                <Box width="50%">
                  <Text
                    fontSize={{
                      base: "sm",
                      sm: "sm",
                      md: "md",
                      lg: "md",
                    }}
                    textAlign="right"
                    fontWeight="bold"
                    fontFamily="ManropeRegular"
                  >
                     {rate !== null
                        ? new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format((rate * amount) - (fee * rate))
                        : "**"} NGN
                  </Text>
                </Box>
              </HStack>
              <Box width="100%">
                <Text
                  fontSize={{
                    base: "xs",
                    sm: "xs",
                    md: "sm",
                    lg: "sm",
                  }}
                  fontFamily="ManropeRegular"
                >
                  {account.bankName} - {account.accountNumber}
                </Text>
              </Box>
            </Box>

            <Box
              width="100%"
              mt="5"
              p="5"
              background="gray.200"
              borderRadius={15}
            >
              <Box>
                <Heading
                  color="brand.700"
                  fontFamily="ManropeSemiBold"
                  fontSize={{ base: "md", sm: "md", md: "lg", lg: "lg" }}
                >
                  {amount} USD
                </Heading>
              </Box>
              <HStack mt="3">
                <Box width="50%">
                  <Text
                    fontSize={{
                      base: "xs",
                      sm: "xs",
                      md: "sm",
                      lg: "sm",
                    }}
                    fontFamily="ManropeRegular"
                  >
                    Withdrawal Amount
                  </Text>
                </Box>
                <Box width="50%">
                  <Text
                    fontFamily="ManropeSemiBold"
                    fontSize={{
                      base: "xs",
                      sm: "xs",
                      md: "sm",
                      lg: "sm",
                    }}
                    textAlign="right"
                  >
                     {rate !== null
                        ? new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format((rate * amount))
                        : "**"} NGN
                        
                  </Text>
                </Box>
              </HStack>

              <HStack mt="1">
                <Box width="50%">
                  <Text
                    fontFamily="ManropeRegular"
                    fontSize={{
                      base: "xs",
                      sm: "xs",
                      md: "sm",
                      lg: "sm",
                    }}
                  >
                    Fee
                  </Text>
                </Box>
                <Box width="50%">
                  <Text
                    fontFamily="ManropeSemiBold"
                    fontSize={{
                      base: "xs",
                      sm: "xs",
                      md: "sm",
                      lg: "sm",
                    }}
                    textAlign="right"
                  >
                    {rate !== null
                        ? new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format((fee * rate))
                        : "**"} NGN
                  </Text>
                </Box>
              </HStack>

              <HStack mt="1">
                <Box width="50%">
                  <Text
                    fontFamily="ManropeRegular"
                    fontSize={{
                      base: "xs",
                      sm: "xs",
                      md: "sm",
                      lg: "sm",
                    }}
                  >
                    Exchange Rate
                  </Text>
                </Box>
                <Box width="50%">
                    <Text
                      textAlign="right"
                      fontFamily="ManropeSemiBold"
                      fontSize={{
                        base: "xs",
                        sm: "xs",
                        md: "sm",
                        lg: "sm",
                      }}
                    >
                      $1 -{" "}
                      {rate !== null
                        ? new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(rate)
                        : "**"}{" "}
                      NGN
                    </Text>
                </Box>
              </HStack>
            </Box>

            <Stack spacing={4} w={"full"} maxW={"md"} p={6} my={5}>
              <Box pt="3" pb="5">
                <Box width="100%">
                  <Text
                    textAlign="center"
                    fontSize="14"
                    color={textColorSecondary}
                    fontFamily="ManropeRegular"
                  >
                    Enter your 6 digit security pin
                  </Text>
                </Box>
                <Center width="100%" mt="1">
                  <PinInput otp>
                    <PinInputField
                      w="45px"
                      h="45px"
                      color={inputColor}
                      onChange={(e) => {
                        setPin1(e.target.value);
                      }}
                    />
                    <PinInputField
                      w="45px"
                      h="45px"
                      color={inputColor}
                      onChange={(e) => {
                        setPin2(e.target.value);
                      }}
                    />
                    <PinInputField
                      w="45px"
                      h="45px"
                      color={inputColor}
                      onChange={(e) => {
                        setPin3(e.target.value);
                      }}
                    />
                    <PinInputField
                      w="45px"
                      h="45px"
                      color={inputColor}
                      onChange={(e) => {
                        setPin4(e.target.value);
                      }}
                    />
                    <PinInputField
                      w="45px"
                      h="45px"
                      color={inputColor}
                      onChange={(e) => {
                        setPin5(e.target.value);
                      }}
                    />
                    <PinInputField
                      w="45px"
                      h="45px"
                      color={inputColor}
                      onChange={(e) => {
                        setPin6(e.target.value);
                      }}
                    />
                  </PinInput>
                  {loading && <Spinner ml="1" />}
                </Center>
              </Box>

              <Box w="100%" minH="40px" mt="4" p="4">
                <Button
                  fontSize="sm"
                  variant="brand"
                  bg={bg}
                  color={color}
                  fontWeight="500"
                  w="100%"
                  h="50"
                  _hover={{
                    bg: "blue.500",
                    color: "white",
                  }}
                  disabled={walletLoading}
                  isLoading={loading}
                  loadingText="Please Wait.."
                  mb="24px"
                  onClick={(e) => {
                    e.preventDefault();
                    transerFund();
                  }}
                >
                  Transfer
                </Button>

                <Text textAlign="center">
                  Transfer are processed with 24 hours
                </Text>
              </Box>
            </Stack>
          </Card>
        </SimpleGrid>
      </Box>
    </>
  );
}
