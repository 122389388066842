// Chakra imports
import {
  Box,
  VStack,
  HStack,
  SimpleGrid,
  Flex,
  Button,
  SkeletonText,
  Circle,
  Icon,
  Text,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
// Custom components
import Card from "components/card/Card.js";
import { FiPause, FiPlay, FiMail, FiUser, FiPhone, FiEdit } from "react-icons/fi";
import React from "react";
// Assets

export default function Basic(props) {
  const { user, card, unfreezeVirtualCard, freezeVirtualCard, changeUserStatus} = props;
  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.800", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  return (
    <Card align="center" p="20px">
      <Stack direction={{ base: "column", sm: "column", md: "row", lg: "row" }}>
        <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}>
          <VStack width="100%">
            <Box width="100%">
              <Flex>
                <Box
                  textAlign="right"
                  ml={{ base: "0px", sm: "0px", md: "13px", lg: "13px" }}
                >
                  <Circle height="60px" width="60px" background="brand.400">
                    <Icon as={FiUser} height="45px" width="45px" color="#fff" />
                  </Circle>
                </Box>
                <Box ml="8px">
                  <Text
                    color={textColorPrimary}
                    my={{ base: "auto", "2xl": "10px" }}
                    mx="auto"
                    textAlign="start"
                    fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                  >
                    Name
                  </Text>
                  <Text
                    textAlign="start"
                    color={textColorSecondary}
                    fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                    mt={{ base: "5px", "2xl": "5px" }}
                  >
                    {user !== null ? (
                      user.firstName + " " + user.lastName
                    ) : (
                      <SkeletonText noOfLines={2} />
                    )}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box width="100%">
              <SimpleGrid columns={{ base: 1, sm: 1, md: 3, lg: 3 }} mt="5">
                <Box>
                  <HStack>
                    <Box
                      ml={{ base: "2px", sm: "2px", md: "20px", lg: "20px" }}
                    >
                      <Circle width="40px" height="40px" background="brand.400">
                        <Icon
                          as={FiMail}
                          width="20px"
                          height="20px"
                          color="#fff"
                        />
                      </Circle>
                    </Box>
                    <Box>
                      <Text
                        color={textColorPrimary}
                        fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                        my={{ base: "auto", "2xl": "10px" }}
                        textAlign="start"
                      >
                        Email
                      </Text>
                      <Text
                        fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                        color={textColorSecondary}
                        my={{ base: "auto", "2xl": "10px" }}
                        textAlign="start"
                      >
                        {user !== null ? (
                          user.email
                        ) : (
                          <SkeletonText noOfLines={2} />
                        )}
                      </Text>
                    </Box>
                  </HStack>
                </Box>

                <Box mt={{ base: "10px", sm: "10px", md: "0px", lg: "0px" }}>
                  <HStack>
                    <Box>
                      <Circle width="40px" height="40px" background="brand.400">
                        <Icon
                          as={FiPhone}
                          width="20px"
                          height="20px"
                          color="#fff"
                        />
                      </Circle>
                    </Box>
                    <Box>
                      <Text
                        color={textColorPrimary}
                        fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                        my={{ base: "auto", "2xl": "10px" }}
                        textAlign="start"
                      >
                        Phone
                      </Text>
                      <Text
                        fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                        color={textColorSecondary}
                        my={{ base: "auto", "2xl": "10px" }}
                        textAlign="start"
                      >
                        {user !== null ? (
                          user.phone !== null ? (
                            user.phone
                          ) : (
                            "(-----)"
                          )
                        ) : (
                          <SkeletonText noOfLines={2} />
                        )}{" "}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box textAlign="start" mt={{ base: 2, sm: 2 }}>
                    <Flex>
                      {card !== null && !card.card.frozen && (
                        <>
                          <Icon
                            as={FiPause}
                            color={brandColor}
                            w="20px"
                            h="20px"
                          />
                          <Text
                            fontSize={{
                              base: "xs",
                              sm: "sm",
                              md: "md",
                              lg: "sm",
                            }}
                            my={{ base: "auto", "2xl": "10px" }}
                            color={brandColor}
                            onClick={freezeVirtualCard}
                          >
                            &nbsp;Freeze Card
                          </Text>
                        </>
                      )}

                      {card !== null && card.card.frozen && (
                        <>
                          <Icon
                            as={FiPlay}
                            color={brandColor}
                            w="20px"
                            h="20px"
                          />
                          <Text
                            fontSize={{
                              base: "xs",
                              sm: "sm",
                              md: "md",
                              lg: "sm",
                            }}
                            my={{ base: "auto", "2xl": "10px" }}
                            color={brandColor}
                            onClick={unfreezeVirtualCard}
                          >
                            &nbsp;Unfreeze Card
                          </Text>
                        </>
                      )}
                    </Flex>

                    <Flex mt="5">
                     {user !== null && user.status && (
                        <>
                          <Icon
                            as={FiPause}
                            color={brandColor}
                            w="20px"
                            h="20px"
                          />
                          <Text
                            fontSize={{
                              base: "xs",
                              sm: "sm",
                              md: "md",
                              lg: "sm",
                            }}
                            my={{ base: "auto", "2xl": "10px" }}
                            color={brandColor}
                            onClick={changeUserStatus}
                          >
                            &nbsp; Disable User
                          </Text>
                        </>
                      )}

                      {user !== null && !user.status && (
                        <>
                          <Icon
                            as={FiPlay}
                            color={brandColor}
                            w="20px"
                            h="20px"
                          />
                          <Text
                            fontSize={{
                              base: "xs",
                              sm: "sm",
                              md: "md",
                              lg: "sm",
                            }}
                            my={{ base: "auto", "2xl": "10px" }}
                            color={brandColor}
                            onClick={changeUserStatus}
                          >
                            &nbsp; Enable User
                          </Text>
                        </>
                      )}
                    </Flex>
                </Box>
              </SimpleGrid>
            </Box>
          </VStack>
        </Box>
      </Stack>
    </Card>
  );
}
