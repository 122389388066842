import {
  Container,
  SimpleGrid,
  Center,
  Circle,
  Image,
  Heading,
  Text,
  Box,
  Stack,
  VStack,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";

import { FaKey, FaRegHandPeace, FaLock } from "react-icons/fa";
import { FiShield, FiCreditCard, FiHeadphones } from "react-icons/fi";

import React from "react";
import Card1 from "assets/images/illustrationimage3.png";
import Pattern from "assets/images/inspiration-geometry.png";

const Feature = ({ text, icon }) => {
  return (
    <Stack direction={"row"} align={"start"} pt="0" pb="0" mt="0" mb="0">
      <Circle w={8} h={8} bg="orange.500">
        {icon}
      </Circle>
      <Text
        fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
        fontWeight="ManropeLight"
        color="#000000"
      >
        {text}
      </Text>
    </Stack>
  );
};

const WhatMakesUs = () => {
  return (
      <Container maxW={"5xl"}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Box>
            <Center width="100%" height="100%">
              <VStack>
                <Box>
                  {" "}
                  <Heading
                    textAlign="start"
                    as="h3"
                    fontSize={{ base: "xl", sm: "2xl", md: "4xl" }}
                    fontFamily="TrenchThin"
                    fontWeight="400"
                    color="black"
                  >
                    What makes us{" "}
                    <Text as="span" color="brand.500">
                      better?
                    </Text>
                  </Heading>
                </Box>
                <Box>
                  {" "}
                  <Text
                    textAlign="center"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                    fontFamily="ManropeLight"
                    color="#000"
                  >
                    {" "}
                    Our virtual card solution offers;{" "}
                    <span color="brand.600">Low Transaction Fees</span> -{" "}
                    <span color="brand.600">Immediate Loading</span> -{" "}
                    <span color="brand.600">
                      Access to thousands of Global Merchant
                    </span>
                    .
                  </Text>
                </Box>
              </VStack>
            </Center>
          </Box>
          <Box>
            <SimpleGrid columns="2" spacing="5">
              <Box>
                <VStack spacing="5">
                  <Box
                    boxShadow="lg"
                    background="brand.500"
                    width="100%"
                    height="180px"
                    borderRadius="15px"
                  >
                    <Center w="100%" height="100%">
                      <Stack>
                        <Box textAlign="center">
                          <Icon
                            as={FiShield}
                            color="white"
                            width="8"
                            height="8"
                          />
                          <Heading
                            color="white"
                            fontSize={{
                              base: "sm",
                              sm: "md",
                              md: "lg",
                              lg: "2xl",
                            }}
                            fontFamily="TrenchThin"
                            fontWeight="300"
                          >
                            Top Security
                          </Heading>
                        </Box>
                      </Stack>
                    </Center>
                  </Box>
                  <Box
                    boxShadow="lg"
                    background="#bfcfff"
                    width="100%"
                    height="180px"
                    borderRadius="15px"
                  >
                    <Center w="100%" height="100%">
                      <Stack>
                        <Box
                          textAlign="center"
                          fontWeight="bold"
                          color={'brand.500'}
                          fontSize={{
                            base: "sm",
                            sm: "sm",
                            md: "md",
                            lg: "lg",
                          }}
                          fontFamily="ManropeSemiBold"
                        >
                          <Text color={'brand.500'}>5K USD</Text>
                          <Heading
                            color={'brand.500'}
                            fontSize={{
                              base: "sm",
                              sm: "md",
                              md: "lg",
                              lg: "2xl",
                            }}
                            fontFamily="TrenchThin"
                            fontWeight="300"
                          >
                            Monthly Limit
                          </Heading>
                        </Box>
                      </Stack>
                    </Center>
                  </Box>
                </VStack>
              </Box>
              <Box>
                <VStack spacing="5" mt="20">
                  <Box
                    boxShadow="lg"
                    background="brand.500"
                    width="100%"
                    height="180px"
                    borderRadius="15px"
                  >
                    <Center w="100%" height="100%">
                      <Stack>
                        <Box textAlign="center">
                          <Icon
                            as={FiCreditCard}
                            color="white"
                            width="8"
                            height="8"
                          />
                          <Heading
                            textAlign="center"
                            color="white"
                            fontSize={{
                              base: "sm",
                              sm: "md",
                              md: "lg",
                              lg: "2xl",
                            }}
                            fontFamily="TrenchThin"
                            fontWeight="300"
                          >
                            Cross Border
                          </Heading>
                        </Box>
                      </Stack>
                    </Center>
                  </Box>
                  <Box
                    boxShadow="lg"
                    background="#bfcfff"
                    width="100%"
                    height="180px"
                    borderRadius="15px"
                  >
                    <Center w="100%" height="100%">
                      <Stack>
                        <Box textAlign="center">
                          <Icon
                            as={FiHeadphones}
                            color={'brand.500'}
                            width="8"
                            height="8"
                          />
                          <Heading
                            textAlign="center"
                            color={'brand.500'}
                            fontSize={{
                              base: "sm",
                              sm: "md",
                              md: "lg",
                              lg: "2xl",
                            }}
                            fontFamily="TrenchThin"
                            fontWeight="300"
                          >
                            24/7 Support
                          </Heading>
                        </Box>
                      </Stack>
                    </Center>
                  </Box>
                </VStack>
              </Box>
            </SimpleGrid>
          </Box>
        </SimpleGrid>
      </Container>
  );
};

export default function About() {
  return (
    <>
      <Box 
      width="100%" pb="5"
      background="#e4ecff"
      backgroundImage={`url(${Pattern})`}
      backgroundPosition="right center"
      backgroundSize="30% auto"
      backgroundRepeat="repeat">
        <Container
          maxW={"5xl"}
          py={{ sm: 5, md: 20 }}
        >
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Box>
              <Center width="100%" height="100%">
                <VStack spacing="4">
                  <Box>
                    <Center width="100%" height="100%">
                      <Image
                        src={Card1}
                        borderRadius="15"
                        width={{ base: "90%", sm: "90%", md: "90%" }}
                        height="auto"
                        alt="Swaptrex Virtual Card"
                      />
                    </Center>
                  </Box>
                </VStack>
              </Center>
            </Box>
            <Box>
              <VStack mt={{ sm: 0, md: 50 }}>
                <Box>
                  <Box textAlign="left">
                    {" "}
                    <Heading
                      as="h3"
                      textAlign="left"
                      fontSize={{ base: "xl", sm: "2xl", md: "4xl" }}
                      fontFamily="TrenchThin"
                      fontWeight="400"
                      color="brand.500"
                    >
                      {" "}
                      Pay anywhere{" "}
                      <Text as="span" color="#000000">
                        and anytime
                      </Text>
                    </Heading>
                  </Box>

                  <Text
                    color={"#000000"}
                    textAlign="justify"
                    mt="3"
                    fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                    fontWeight="ManropeLight"
                  >
                    Swaptrex offers a seamless solution for global payments. Our platform transforms the way you transact, providing USD-denominated virtual Visa cards that bridge the gap between traditional currencies and international payment. With Swaptrex, you can enjoy the freedom to spend anywhere in the world.
                  </Text>
                </Box>
                {/*<Box width="100%">
                  <Stack mt="4" spacing={4}>
                    <Feature
                      icon={<Icon as={FaKey} color={"white"} w={5} h={5} />}
                      iconBg={useColorModeValue("white", "white")}
                      text={"Access to your funds anywhere and anytime"}
                    />
                    <Feature
                      icon={
                        <Icon as={FaRegHandPeace} color={"white"} w={5} h={5} />
                      }
                      iconBg={useColorModeValue("white", "white")}
                      text={
                        "Easy to use—simply add funds with your virtual wallet and start spending"
                      }
                    />
                    <Feature
                      icon={<Icon as={FaLock} color={"white"} w={5} h={5} />}
                      iconBg={useColorModeValue("white", "white")}
                      text={
                        "Secured as card is not connected to your actual bank account"
                      }
                    />
                  </Stack>
                    </Box>*/}
              </VStack>
            </Box>
          </SimpleGrid>
        </Container>

        <WhatMakesUs />
      </Box>
    </>
  );
}
