import {
  Box,
  Flex,
  Button,
  Divider,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  HStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { Helmet } from "react-helmet";
import Header from "components/navigation/Header.js";

const Tariff = () => {
  const colorTextLight = "white";
  const bgColorLight = "brand.500";
  const colorTextDark = "white";
  const bgColorDark = "brand.500";

  return (
    <>
      <Helmet>
        <title>Tariff</title>
        <meta
          name="description"
          content="Swaptrex transparent pricing and charges"
        />
        <meta
          name="keywords"
          content="Virtual Card, virtual payment,virtual card,virtual credit card,virtual debit card,virtual visa card,virtual pay,virtual payment cards,virtual visa,generate a virtual credit card,instant virtual credit card,virtual pay card,virtual card for online payment,instant virtual card,mastercard virtual card,virtual mastercard,virtual bank card,virtual visa card instant,online virtual credit card,instant virtual debit card,generate virtual credit card,create virtual credit card"
        />
      </Helmet>
      <Header />
      <Box py={6} px={5}>
        <Stack spacing={4} width={"100%"} direction={"column"}>
          <Stack
            p={5}
            alignItems={"center"}
            justifyContent={{
              base: "flex-start",
              md: "space-around",
            }}
            direction={{
              base: "column",
              md: "row",
            }}
          >
            <Stack
              mt="10"
              width={{
                base: "100%",
                md: "40%",
              }}
              textAlign={"center"}
            >
              <Heading size={"lg"}>
                We have a transparent <Text color="brand.500">pricing</Text>
              </Heading>
            </Stack>
            <Stack
              width={{
                base: "100%",
                md: "60%",
              }}
            >
              <Text textAlign={"center"}></Text>
            </Stack>
          </Stack>
        </Stack>
      </Box>

      <Box py={6} px={5} width="100%">
        <Flex width="100%" alignContents="center" justifyContent="center">
          <HStack
            height="40"
            width={{ sm: "100%", md: "80%" }}
            p={3}
            py={3}
            columns={3}
            justifyContent={{
              base: "flex-start",
              md: "space-around",
            }}
            alignItems={{ md: "center" }}
          >
            <Box width="33%">
              <Heading size={"md"}>Issuance</Heading>
            </Box>
            <Box width="33%">
              <List spacing={3} textAlign="start">
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Card Issuance
                </ListItem>
              </List>
            </Box>
            <Box width="33%">
              <Heading size={"md"}>$1</Heading>
            </Box>
          </HStack>
        </Flex>

        <Divider />

        {/*
        <Flex width="100%" alignContents="center" justifyContent="center">
          <HStack
            height="40"
            width={{ sm: "100%", md: "80%" }}
            p={3}
            py={3}
            columns={3}
            justifyContent={{
              base: "flex-start",
              md: "space-around",
            }}
            alignItems={{ md: "center" }}
          >
            <Box width="33%">
              <Heading size={"md"}>Deposit</Heading>
            </Box>
            <Box width="33%">
              <List spacing={3} textAlign="start">
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  USD
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  BUSD
                </ListItem>
              </List>
            </Box>
            <Box width="33%">
              <Heading size={"md"}>1%</Heading>
            </Box>
          </HStack>
        </Flex>
        <Divider />*/}

        <Flex width="100%" alignContents="center" justifyContent="center">
          <HStack
            height="40"
            width={{ sm: "100%", md: "80%" }}
            p={3}
            py={3}
            columns={3}
            justifyContent={{
              base: "flex-start",
              md: "space-around",
            }}
            alignItems={{ md: "center" }}
          >
            <Box width="33%">
              <Heading size={"md"}>Loading</Heading>
            </Box>
            <Box width="33%">
              <List spacing={3} textAlign="start">
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Card Loading Fee
                </ListItem>
              </List>
            </Box>
            <Box width="33%">
              <Heading size={"md"}>2.2%</Heading>
            </Box>
          </HStack>
        </Flex>
        <Divider />

        <Flex width="100%" alignContents="center" justifyContent="center">
          <HStack
            height="40"
            width={{ sm: "100%", md: "80%" }}
            p={3}
            py={3}
            columns={3}
            justifyContent={{
              base: "flex-start",
              md: "space-around",
            }}
            alignItems={{ md: "center" }}
          >
            <Box width="33%">
              <Heading size={"md"}>Unloading</Heading>
            </Box>
            <Box width="33%">
              <List spacing={3} textAlign="start">
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Card Unloading Fee
                </ListItem>
              </List>
            </Box>
            <Box width="33%">
              <Heading size={"md"}>No Fee</Heading>
            </Box>
          </HStack>
        </Flex>
        <Divider />

        <Flex width="100%" alignContents="center" justifyContent="center">
          <HStack
            height="40"
            width={{ sm: "100%", md: "80%" }}
            p={3}
            py={3}
            columns={3}
            justifyContent={{
              base: "flex-start",
              md: "space-around",
            }}
            alignItems={{ md: "center" }}
          >
            <Box width="33%">
              <Heading size={"md"}>Transaction</Heading>
            </Box>
            <Box width="33%">
              <List spacing={3} textAlign="start">
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  POS Transaction Fee
                </ListItem>
              </List>
            </Box>
            <Box width="33%">
              <Heading size={"md"}>0.8% ($0.8 Minimum, $5 Maximum)</Heading>
            </Box>
          </HStack>
        </Flex>
        <Divider />

        <Flex width="100%" alignContents="center" justifyContent="center">
          <HStack
            height="40"
            width={{ sm: "100%", md: "80%" }}
            p={3}
            py={3}
            columns={3}
            justifyContent={{
              base: "flex-start",
              md: "space-around",
            }}
            alignItems={{ md: "center" }}
          >
            <Box width="33%">
              <Heading size={"md"}>Withdrawal</Heading>
            </Box>
            <Box width="33%">
              <List spacing={3} textAlign="start">
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Withdrawal Fee
                </ListItem>
              </List>
            </Box>
            <Box width="33%">
              <Heading size={"md"}></Heading>
            </Box>
          </HStack>
        </Flex>
        <Divider />

        <Flex width="100%" alignContents="center" justifyContent="center">
          <HStack
            height="40"
            width={{ sm: "100%", md: "80%" }}
            p={3}
            py={3}
            columns={3}
            justifyContent={{
              base: "flex-start",
              md: "space-around",
            }}
            alignItems={{ md: "center" }}
          >
            <Box width="33%">
              <Heading size={"md"}>Maintenance Fee</Heading>
            </Box>
            <Box width="33%">
              <List spacing={3} textAlign="start">
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Maintenance Fee
                </ListItem>
              </List>
            </Box>
            <Box width="33%">
              <Heading size={"md"}>$1 (Monthly)</Heading>
            </Box>
          </HStack>
        </Flex>
        <Divider />
      </Box>
    </>
  );
};

export default Tariff;
