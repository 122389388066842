import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Box,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  Heading,
  VStack,
  Input,
  NumberInput,
  NumberInputField,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Skeleton,
  SkeletonText,
  Center,
  Icon,
  useToast,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";

import Paginate from "components/navigation/Paginate.js";
import Card from "components/card/Card.js";
import Transactions from "views/user/transfer/components/Transactions";

export default function Transfer() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);
  const [walletLoading, setWalletLoading] = useState(true);
  const [transactionLoading, setTransactionLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [wallet, setWallet] = useState(null);
  const [settings, setSettings] = useState(null);
  const [amount, setAmount] = useState(0);
  const [fee, setFee] = useState(0);
  const [address, setAddress] = useState("");
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState("createdAt");

  const {
    isOpen: isTransferOpen,
    onOpen: onTransferOpen,
    onClose: onTransferClose,
  } = useDisclosure();

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();

  const getWallet = async (from) => {
    try {
      const { data: response } = await axios.get(SERVER_URL + "/wallet/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setWalletLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setWalletLoading(false);
        return;
      }
      if (response.success) {
        setWallet(response.data);
        setWalletLoading(false);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const transerFund = async () => {
    try {
      if (parseFloat(amount) > wallet.balance) {
        toast({
          title: "You have insufficient balance to make this transfer",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }

      /*
      if (parseFloat(amount) < 3) {
        toast({
          title: "Minimum withdrawal is 3 USDT",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        })
        return;
      }*/
      if (otp === "") {
        toast({
          title:
            "Please enter your 6 Digit Pin or go to settings to create one",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/transfer",
        { amount, address, otp },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setAmount("");
        setAddress("");
        onTransferClose();
        getWallet();
        getTransactions();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getTransactions = async () => {
    try {
      const { data: response } = await axios.get(
        SERVER_URL +
          "/transfer/transactions?from=" +
          from +
          "&limit=" +
          limit +
          "&orderBy=" +
          orderBy +
          "&sortOrder=" +
          sortOrder,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setTransactions(response.data.transactions);
      }
      setTransactionLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const getSettings = async () => {
    try {
      const { data: response } = await axios.get(SERVER_URL + "/settings", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.success) {
        setSettings(response.data);
      }
    } catch (error) {
    }
  };


  useEffect(() => {
    getWallet();
    getTransactions();
    getSettings();
  }, []);

  useEffect(() => {
    setOtp(pin1 + "" + pin2 + "" + pin3 + "" + pin4 + "" + pin5 + "" + pin6);
  }, [pin1, pin2, pin3, pin4, pin5, pin6, otp]);

  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.700", "gray.100");
  const inputColor = useColorModeValue("brand.500", "gray.100");
  const backgroundColor = useColorModeValue("white", "brand.700");
  const bg = useColorModeValue("brand.500", "gray.100");
  const color = useColorModeValue("white", "brand.500");

  return (
    <>
      <Modal isOpen={isTransferOpen} onClose={onTransferClose} size={"md"}>
        <ModalOverlay />
        <ModalContent color={textColorSecondary} background={backgroundColor}>
          <ModalHeader color={textColorSecondary}>
            <Icon
              as={FiArrowLeft}
              onClick={onTransferClose}
              width="20px"
              height="20px"
              color={textColorPrimary}
            />
          </ModalHeader>
          <ModalCloseButton color={textColorSecondary} />
          <ModalBody>
            <SimpleGrid>
              <Box>
                <Heading
                  as="h5"
                  color={textColorSecondary}
                  size={{ base: "sm", lg: "md" }}
                  fontFamily="ManropeRegular"
                >
                  Confirm Transfer
                </Heading>
              </Box>

              <Box>
                <VStack spacing={2} align="stretch">
                  <Box w="100%" mt="2">
                    <Center width="100%" fontFamily="ManropeRegular">You are transfering</Center>
                  </Box>
                  <Box w="100%">
                    <Center width="100%">
                      <Text fontWeight="bold" fontSize="17">
                        {amount} {" USD"}
                      </Text>
                    </Center>
                  </Box>

                  <Box w="100%">
                    <Center width="100%">
                      <Text fontWeight="bold" textAlign="center" fontSize="16">
                        {address}
                      </Text>
                    </Center>
                  </Box>

                  <Box pt="3" pb="5">
                    <Box width="100%">
                      <Text
                        textAlign="center"
                        fontSize="14"
                        color={textColorSecondary}
                        fontFamily="ManropeRegular"
                      >
                        Enter your 6 digit security pin
                      </Text>
                    </Box>
                    <Center width="100%" mt="1">
                      <PinInput otp>
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin1(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin2(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin3(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin4(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin5(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin6(e.target.value);
                          }}
                        />
                      </PinInput>
                      {loading && <Spinner ml="1" />}
                    </Center>
                  </Box>

                  <Box w="100%" minH="40px" mt="4" p="4">
                    <Button
                      fontSize="sm"
                      variant="brand"
                      bg={bg}
                      color={color}
                      fontWeight="500"
                      w="100%"
                      h="50"
                      _hover={{
                        bg: "blue.500",
                        color: "white",
                      }}
                      disabled={walletLoading}
                      isLoading={loading}
                      loadingText="Please Wait.."
                      mb="24px"
                      onClick={(e) => {
                        e.preventDefault();
                        transerFund();
                      }}
                    >
                      Transfer
                    </Button>

                    <Text textAlign="center">
                      Transfer are processed with 24 hours
                    </Text>
                  </Box>
                </VStack>
              </Box>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }}>
          <Card align="center" p="20px">

            <Link to="/user/transferfiat">
              <Box width="100%" textAlign="center">
                <Heading size="md">Withdraw to Crypto Wallet</Heading>
              </Box>
            </Link> 


            <Stack spacing={4} w={"full"} maxW={"md"} p={6} my={5}>
              <FormControl isInvalid={error}>
                <FormLabel color={textColorSecondary} fontWeight="bold">
                  Amount
                </FormLabel>
                <NumberInput precision={2}>
                  <NumberInputField
                    color={inputColor}
                    borderRadius="20"
                    placeHolder="Enter USD Amount"
                    defaultValue={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                      const fee =
                        new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 5,
                        }).format((parseFloat(e.target.value) * settings.withdrawalFee) + 0.3);
                      
                      if(!isNaN(fee))
                       {setFee(fee)}
                       else{setFee(0)}

                      if (parseFloat(e.target.value) > wallet.balance) {
                        setError(true);
                        setErrorMessage("Insufficient balance");
                      } else {
                        setError(false);
                      }
                    }}
                  />
                </NumberInput>
                {error && (
                  <Center>
                    <FormErrorMessage textAlign="center">
                      {errorMessage}
                    </FormErrorMessage>
                  </Center>
                )}
              </FormControl>

              <FormControl>
                <FormLabel color={textColorSecondary} fontWeight="bold">
                  USDT Address (BEP-20)
                </FormLabel>
                <Input
                  type="text"
                  color={inputColor}
                  defaultValue={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  borderRadius="20"
                />
              </FormControl>

              <Button
                isLoading={loading}
                bg={useColorModeValue("brand.500", "gray.100")}
                color={useColorModeValue("white", "brand.500")}
                w="full"
                mt="2"
                disabled={error}
                borderRadius="20"
                _hover={{
                  bg: "blue.500",
                  color: "white",
                }}
                loadingText="Wait.."
                onClick={(e) => {
                  onTransferOpen();
                }}
              >
                Proceed
              </Button>

              <Text>
                <b>Withdrawal Fee:</b> {settings!==null && fee} USDT
              </Text>
            </Stack>

            <Link to="/user/transferfiat">
              <Box width="100%" textAlign="center">
                <Text textDecoration={"underline"}>Withdraw to Bank Account</Text>
              </Box>
            </Link> 


          </Card>
        </SimpleGrid>

        <Box mt="5">
          {transactionLoading ? (
            <LoadingTransaction />
          ) : (
            <Transactions transactions={transactions} />
          )}
          {transactions.length > 0 && (
            <Box width="100%" mt="5">
              <Paginate
                pageCount={pageCount}
                setFrom={setFrom}
                getRecords={getTransactions}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

const LoadingTransaction = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};
