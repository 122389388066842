// Chakra imports
import {
  Box,
  VStack,
  HStack,
  SimpleGrid,
  StackDivider,
  Circle,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
// Custom components
import Card from "components/card/Card.js";
import {
  FiShield,
  FiKey,
  FiHome,
  FiChevronRight,
  FiUser,
  FiPhone,
} from "react-icons/fi";
import React from "react";
// Assets
import Thumb1 from "assets/images/thumb2.png";
import { MdUpload } from "react-icons/md";

export default function Basic(props) {
  const { ...rest } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.800", "white");
  const borderColor = useColorModeValue("gray.300", "gray.500");
  const chevronColor = useColorModeValue("gray.600", "white");
  return (
    <Card {...rest} align="center" p="20px" mt="10px">
      <VStack
        divider={<StackDivider borderColor={borderColor} />}
        spacing={4}
        align="stretch"
      >
        <Link to="/user/changename">
          <Box w="100%" h="40px">
            <HStack>
              <Box width={{ base: "15%", sm: "10%" }}>
                <Circle width="30px" height="30px" background="brand.400">
                  <Icon as={FiUser} width="20px" height="20px" color="#fff" />
                </Circle>
              </Box>
              <Box
                width={{ base: "75%", sm: "80%" }}
                textAlign="start"
                fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                fontFamily="ManropeRegular"
                color={textColorSecondary}
              >
                {" "}
                Change Name{" "}
              </Box>
              <Box width="10%">
                <Icon
                  as={FiChevronRight}
                  width="20px"
                  height="20px"
                  color={chevronColor}
                />
              </Box>
            </HStack>
          </Box>
        </Link>

        <Link to="/user/security">
          <Box w="100%" h="40px">
            <HStack>
              <Box width={{ base: "15%", sm: "10%" }}>
                <Circle width="30px" height="30px" background="brand.400">
                  <Icon as={FiShield} width="20px" height="20px" color="#fff" />
                </Circle>
              </Box>
              <Box
                width={{ base: "75%", sm: "80%" }}
                textAlign="start"
                fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                fontFamily="ManropeRegular"
                color={textColorSecondary}
              >
                {" "}
                Security{" "}
              </Box>
              <Box width="10%">
                <Icon
                  as={FiChevronRight}
                  width="20px"
                  height="20px"
                  color={chevronColor}
                />
              </Box>
            </HStack>
          </Box>
        </Link>

        <Link to="/user/password">
          <Box w="100%" h="40px">
            <HStack>
              <Box width={{ base: "15%", sm: "10%" }}>
                <Circle width="30px" height="30px" background="brand.400">
                  <Icon as={FiKey} width="20px" height="20px" color="#fff" />
                </Circle>
              </Box>
              <Box
                width={{ base: "75%", sm: "80%" }}
                textAlign="start"
                fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                fontFamily="ManropeRegular"
                color={textColorSecondary}
              >
                {" "}
                Change Password{" "}
              </Box>
              <Box width="10%">
                <Icon
                  as={FiChevronRight}
                  width="20px"
                  height="20px"
                  color={chevronColor}
                />
              </Box>
            </HStack>
          </Box>
        </Link>

        <Link to="/user/accounts">
          <Box w="100%" h="40px">
            <HStack>
              <Box width={{ base: "15%", sm: "10%" }}>
                <Circle width="30px" height="30px" background="brand.400">
                  <Icon as={FiHome} width="20px" height="20px" color="#fff" />
                </Circle>
              </Box>
              <Box
                width={{ base: "75%", sm: "80%" }}
                textAlign="start"
                fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                fontFamily="ManropeRegular"
                color={textColorSecondary}
              >
                {" "}
                Bank Accounts
              </Box>
              <Box width="10%">
                <Icon
                  as={FiChevronRight}
                  width="20px"
                  height="20px"
                  color={chevronColor}
                />
              </Box>
            </HStack>
          </Box>
        </Link>
      </VStack>
    </Card>
  );
}
