import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Box,
  SimpleGrid,
  Button,
  Flex,
  Icon,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  PinInput,
  PinInputField,
  Stack,
  VStack,
  Center,
  Text,
  useDisclosure,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiChevronLeft, FiArrowLeft } from "react-icons/fi";
import Card from "components/card/Card.js";

export default function ChangePhone() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const {
    isOpen: isOtpOpen,
    onOpen: onOtpOpen,
    onClose: onOtpClose,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+234");
  const [phone, setPhone] = useState("");
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");
  const [otp, setOtp] = useState("");
  const [firstCode, setFirstCode] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0); // 60 seconds = 1 minute
  const [showResendLink, setShowResendLink] = useState(false);
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();
  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.700", "gray.100");
  const inputColor = useColorModeValue("brand.500", "gray.100");
  const backgroundColor = useColorModeValue("white", "brand.700");

  const changePhone = async () => {
    try {
      const userPhone = countryCode + "" + phone;
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/changephone",
        { phone: userPhone },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setFirstCode(true);
        setRemainingTime(90);
        setShowResendLink(false);
        setLoading(false);
        onOtpOpen();
        return;
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const resetPassword = async () => {
    try {
      setResetLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/resetPhone",
        { otp },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setResetLoading(false);
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setResetLoading(false);
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onOtpClose();
        setResetLoading(false);
        setLoading(false);
        navigate("/user/profile");
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setResetLoading(false);
      setLoading(false);
    }
  };

  const getOtp = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/getOtp",
        { userId: userState._id },
        {
          headers: {},
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setFirstCode(true);
        setRemainingTime(90);
        setShowResendLink(false);
      }
      setLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    setOtp(pin1 + "" + pin2 + "" + pin3 + "" + pin4 + "" + pin5 + "" + pin6);
  }, [pin1, pin2, pin3, pin4, pin5, pin6, otp]);
  
  useEffect(() => {
    let timerId = null;
    if (remainingTime > 0) {
      timerId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setShowResendLink(true);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [remainingTime]);

  return (
    <>
      <Modal isOpen={isOtpOpen} onClose={onOtpClose} size={"md"}>
        <ModalOverlay />
        <ModalContent color={textColorSecondary} background={backgroundColor}>
          <ModalHeader color={textColorSecondary}>
            <Icon
              as={FiArrowLeft}
              onClick={onOtpClose}
              width="20px"
              height="20px"
              color={textColorPrimary}
            />
          </ModalHeader>
          <ModalCloseButton color={textColorSecondary} />
          <ModalBody>
            <SimpleGrid>
              <Box>
                <Heading
                  as="h5"
                  color={textColorPrimary}
                  size={{ base: "sm", lg: "md" }}
                  fontFamily="ManropeRegular"
                >
                  Enter OTP Code
                </Heading>
              </Box>

              <Box>
                <VStack spacing={4} align="stretch">
                  <Box w="100%" minH="40px" mt="4" p="4" borderRadius={"10"}>
                    <Center width="100%">
                      <PinInput otp>
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin1(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin2(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin3(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin4(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin5(e.target.value);
                          }}
                        />
                        <PinInputField
                          w="45px"
                          h="45px"
                          color={inputColor}
                          onChange={(e) => {
                            setPin6(e.target.value);
                          }}
                        />
                      </PinInput>
                      {showResendLink ? (
                        firstCode ? (
                          <Button size="xs" onClick={getOtp}>
                            Send
                          </Button>
                        ) : (
                          <Button size="xs" onClick={getOtp}>
                            Send
                          </Button>
                        )
                      ) : (
                        <Text>&nbsp;{remainingTime}s </Text>
                      )}
                    </Center>
                  </Box>

                  <Box w="100%" minH="40px" mt="4" p="4">
                    <Button
                      fontSize="sm"
                      variant="brand"
                      bg={useColorModeValue("brand.500", "gray.100")}
                      color={useColorModeValue("white", "brand.500")}
                      fontWeight="500"
                      w="100%"
                      h="50"
                      _hover={{
                        bg: "blue.500",
                        color: "white"
                      }}
                      isLoading={loading}
                      loadingText="Please Wait"
                      mb="24px"
                      onClick={(e) => {
                        e.preventDefault();
                        resetPassword();
                      }}
                    >
                      Proceed
                    </Button>
                  </Box>
                </VStack>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 2, lg: 2 }}
          spacing="2"
          mb="3"
        >
          <Box textAlign="start">
            <Heading
              bg="inherit"
              fontWeight="bold"
              fontFamily="ManropeSemiBold"
              fontSize={{ base: "md", sm: "md", md: "lg", lg: "xl" }}
              mb="5"
            >
              Change Phone
            </Heading>
          </Box>
          <Box textAlign="right">
            <Link to="/user/profile">
              <Flex direction={"row-reverse"}>
                <Text mt="2" as="span">Back</Text>
                <Icon as={FiChevronLeft} w={8} h={8} mt="1"/> 
              </Flex>
            </Link>
          </Box>
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }}>
          <Card align="center" p="20px">
            <Stack
              spacing={4}
              w={"full"}
              maxW={"md"}
              p={6}
              my={5}
            >
              <HStack>
                <Box width="30%">
                  <FormControl id="code" isRequired>
                    <FormLabel fontFamily="ManropeSemiBold">Phone</FormLabel>
                    <Input type="text" maxLength={4} value="+234" color={inputColor} readOnly />
                  </FormControl>
                </Box>
                <Box width="70%">
                  <FormControl id="phone">
                    <FormLabel fontFamily="ManropeSemiBold">&nbsp;</FormLabel>
                    <Input
                      type="text"
                      color={inputColor}
                      maxLength={10}
                      placeholder="8012345678"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </FormControl>
                </Box>
              </HStack>

              <Box width="100%">
                <Button
                  variant="primary"
                  bg={useColorModeValue("brand.500", "gray.100")}
                  color={useColorModeValue("white", "brand.500")}
                  isLoading={loading}
                  loadingText="Wait"
                  w="full"
                  borderRadius="20"
                  _hover={{
                    bg: "blue.500",
                    color: "white"
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    changePhone();
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Stack>
          </Card>
        </SimpleGrid>
      </Box>
    </>
  );
}
