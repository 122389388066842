import {
  Box,
  chakra,
  Container,
  Image,
  Heading,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { BiMailSend } from 'react-icons/bi';
import LogoWhite from "assets/images/logowhite.png";

const Logo = (props) => {
  return (
    <>
    <Flex>
      <Image src={LogoWhite} width={35} height={35} alt="Swaptrex Virtual Card" />  
      <Heading mt="1" ml="2" as="h4" color="white" fontSize={{base:'md',sm:'lg',md:'xl',lg:'2xl'}} fontFamily="ManropeSemiBold"> Swaptrex </Heading>
    </Flex>
    </>
  );
};

const SocialButton = ({children,label,href}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
      color="#fff">
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({children}) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2} color="#fff" fontFamily="ManropeSemiBold">
      {children}
    </Text>
  );
};

export default function Footer() {
  return (
    <Box
      bg="#22225e"
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
          spacing={8}>
          <Stack spacing={6}>
            <Box>
              <Logo color={useColorModeValue('gray.700', 'white')} />
            </Box>
            <Text fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}} style={{color:"#fff", fontFamily:"ManropeLight"}}>
             support@swaptrex.com
            </Text>
            <Text fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}} style={{color:"#fff", fontFamily:"ManropeLight"}}>
             Unit 1411, 14/Floor, Cosco Tower, 183 Queen's Road Central, Sheung Wan, Hong Kong
            </Text>
          
            <Stack direction={'row'} spacing={6}>
              <SocialButton label={'Twitter'} href={'https://twitter.com/swaptrex'}>
                <FaTwitter />
              </SocialButton>
              <SocialButton label={'Facebook'} href={'https://facebook.com/swaptrex'}>
                <FaFacebook />
              </SocialButton>
              <SocialButton label={'Instagram'} href={'https://instagram.com/swaptrexdotcom'}>
                <FaInstagram />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack align={'flex-start'} fontSize={{base:'sm',sm:'md',md:'lg',lg:'xl'}}>
            <ListHeader fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}} fontWeight="bold" fontFamily="ManropeSemiBold">Company</ListHeader>
            <Link fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}} to={'/web/about'} style={{color:"#fff", fontFamily:"ManropeLight"}}>About us</Link>
            <Link  fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}} to={'/web/terms'} style={{color:"#fff", fontFamily:"ManropeLight"}}>Terms of Service</Link>
            <Link  fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}} to={'/web/privacy'} style={{color:"#fff", fontFamily:"ManropeLight"}}>Privacy Policy</Link>
            <Link  fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}} to={'/web/aml'} style={{color:"#fff", fontFamily:"ManropeLight"}}>AML Policy</Link>
            <Link  fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}} to={'/web/tariff'} style={{color:"#fff", fontFamily:"ManropeLight"}}>Tariff</Link>
          </Stack>
          <Stack align={'flex-start'} fontSize={{base:'sm',sm:'md',md:'lg',lg:'xl'}}>
            <ListHeader fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}} fontWeight="bold" fontFamily="ManropeSemiBold">Support</ListHeader>
            <a  fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}} href={'https://swaptrex.tawk.help/'} style={{color:"#fff", fontFamily:"ManropeLight"}}>Help Center</a>
            <a  fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}} href={'https://medium.com/@swaptrex'} style={{color:"#fff", fontFamily:"ManropeLight"}}>Blog</a>
          </Stack>
          <Stack align={'flex-start'} fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}}>

            <Text fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}}color="#fff" fontFamily="ManropeLight">          
             Swaptrex (Tradersfield Ltd) acts as a card program distributor, but not a bank. Cards are issued by a licensed bank and Member of the FDIC pursuant to license by Visa U.S.A. Inc.
            </Text> 
            <Text mt="5" fontSize={{base:'sm',sm:'md',md:'lg',lg:'lg'}} color="#fff" fontFamily="ManropeLight">
              © 2023 Swaptrex. All rights reserved
            </Text>

          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}