import React from "react";
import {Box} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import BannerWithSearch from "components/banners/BannerWithSearch.js";
import HowItWorks from "./components/HowItWorks.js";
import FAQs from "./components/FAQs.js";
import About from "./components/About.js";
import Testimonials from "./components/Testimonials.js";
import CallToAction from "./components/CallToAction.js";

export default function Home() {
  return (
    <Box minH='100vh'>
      <Helmet>
        <title>Swaptrex: Pay smoothly with your customized Virtual Card</title>
        <meta name="description" content="Get your virtual debit card issued instantly and manage all your transactions easily. Our service provides the best security and convenience for all your payments." />
        <meta  name="keywords" content="Virtual Card, virtual payment,virtual card,virtual credit card,virtual debit card,virtual visa card,virtual pay,virtual payment cards,virtual visa,generate a virtual credit card,instant virtual credit card,virtual pay card,virtual card for online payment,instant virtual card,mastercard virtual card,virtual mastercard,virtual bank card,virtual visa card instant,online virtual credit card,instant virtual debit card,generate virtual credit card,create virtual credit card" />
      </Helmet>
       <BannerWithSearch />
       <About />
       <HowItWorks />
       <FAQs />
       <CallToAction />
       <Testimonials />
    </Box>
  );
}