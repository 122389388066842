import React from "react";
import { Link } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  VStack,
  Text
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";

export default function Info(props) {
  const {user} = props;
  return (
    <Card shadow="md" background="blue">
    <VStack>
      <Box textAlign='start' width="100%">
      <Text
        color="white"
        fontSize={{base:'xs',sm:'sm',md:'md',lg:'lg'}}
        my={{ base: "auto", "2xl": "10px" }}
        textAlign='start'>
        Hi
      </Text>
      <Text
        color="white"
        fontWeight="bold"
        fontSize={{ base: "md", sm: "md", md: "lg", lg: "lg" }}
        my={{ base: "auto", "2xl": "10px" }}
        textAlign='start'>
        {user.firstName} {user.lastName}
      </Text>
       <Text as="h3"
          color="white"
          textAlign='start'
          fontSize={{base:'xl',sm:'xl',md:'2xl',lg:'2xl'}}
          mt={{ base: "5px", "2xl": "5px" }}>
          We are happy to see you today {/* <Text as="span" color="#61E8E1">Details</Text> physical USDT card will be launching soon */ }
      </Text>
      </Box>
      <Box width="100%">
         <Link to="/user/virtualcard">
           <Button
            mt="5"
            colorScheme="white"
            background="#fff"
            color="#000"
            variant="solid"
            fontSize={{ base: "xs", sm: "sm", md: "sm", lg: "md" }}
            >
            View Card
          </Button>
          </Link>  
        </Box>
    </VStack>
  </Card>
  );
}
