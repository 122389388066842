/* eslint-disable */
import {
  Flex,
  Stack,
  HStack,
  Center,
  Circle,
  VStack,
  Text,
  Box,
  Button,
  Heading,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { FiUser } from "react-icons/fi";

export default function UsersTable(props) {
  const { users, initMakeAdmin } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.800", "white");

  return (
    <>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Users Table
        </Text>
      </Flex>

      {users.map((user) => (
        <Card width="100%" p="5" columns={3} mt="3" key={user._id}>
          <Stack direction={{ base: "row", lg: "row" }}>
            <Box width="80%">
              <HStack columns={2} spacing="3">
                <Box w="10%" textAlign={"end"}>
                  <Center width="100%">
                    <Circle size="40px" bg="brand.500" color="white">
                      <Icon as={FiUser} w="25px" h="25px" color="#fff" />
                    </Circle>
                  </Center>
                </Box>
                <Box w="50%">
                  <VStack p="0" w="100%">
                    <Box w="100%" textAlign={"start"}>
                      <Heading
                        as="h4"
                        color={textColorSecondary}
                        fontFamily="ManropeSemiBold"
                        fontWeight="normal"
                        fontSize={{
                          base: "sm",
                          sm: "sm",
                          md: "md",
                          lg: "lg",
                        }}
                      >
                        {user.firstName} {user.lastName}
                      </Heading>
                    </Box>
                    <Box w="100%" textAlign={"start"}>
                      <Text
                        color={textColorPrimary}
                        fontSize={{
                          base: "sm",
                          sm: "sm",
                          md: "md",
                          lg: "md",
                        }}
                      >
                        {user.email}
                      </Text>
                    </Box>
                  </VStack>
                </Box>
                <Box width="40%">{user.phone}</Box>
              </HStack>
            </Box>
            <Box width="20%" align="center" justify="center">
              <Flex>
              {!user.isAdmin && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    initMakeAdmin(user._id);
                  }}
                >
                  Make Admin
                </Button>
              )}{" "}
              <Link to={"/admin/users/profile/" + user._id}>
                <Button>View</Button>
              </Link>
              </Flex>
            </Box>
          </Stack>
        </Card>
      ))}
    </>
  );
}
