import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Skeleton,
  Stack,
  SkeletonText,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Card from "components/card/Card.js";
import Transactions from "./components/Transactions.js";
import Paginate from "components/navigation/Paginate.js";
import DateFilter from "components/filter/DateFilter";
const {DateTime} = require("luxon");

export default function Index() {
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [transactionLoading, setTransactionLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(200);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [date,setDate] = useState(DateTime.now().toFormat('yyyy-MM-dd'));

  const toast = useToast();
  // Chakra Color Mode

  const getTransactions = async () => {
    try {
      const { data: response } = await axios.get(
        SERVER_URL +
          "/transaction/all/?from=" +
          from +
          "&limit=" +
          limit +
          "&orderBy=" +
          orderBy +
          "&sortOrder=" +
          sortOrder,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setTransactions(response.data.transactions);
      }
      setTransactionLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setTransactionLoading(false);
    }
  };

  const handleFilterSubmit = async()=>{
    try{
      setTransactionLoading(true);
      setPageCount(0);
      const {data:response} = await axios.post(SERVER_URL+'/transaction/filter',{createdAt:date},{
        headers:{
          "Authorization":`Bearer ${token}`
        }
      });
      setTransactionLoading(false);
      if(response.error){
        toast({
          title: response.error || "An error occured",
          status: 'error',
          duration: 9000,
          position:'top-right',
          isClosable: true,
        })
        return;
      }
      if(!response.success){
        toast({
          title: response.message || "An error occured",
          status: 'error',
          duration: 9000,
          position:'top-right',
          isClosable: true,
        })
        return;
      }
      if(response.success){
          setTransactions(response.data);
         return
       }
     }
    catch(error){
      toast({
        title: error.message || "An error occured",
        status: 'error',
        duration: 9000,
        position:'top-right',
        isClosable: true,
      })
      setTransactionLoading(false);
    }
}

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
       <DateFilter date={date} setDate={setDate} initFilterSubmit={handleFilterSubmit} />
        {transactionLoading ? (
          <LoadingTransaction />
        ) : (
          <Transactions transactions={transactions} />
        )}
        {pageCount > 0 && (
          <Box width="100%" mt="5">
            <Paginate
              pageCount={pageCount}
              setFrom={setFrom}
              getRecords={getTransactions}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

const LoadingTransaction = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};
