import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Flex,
  Box,
  Image,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  HStack,
  Heading,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/images/illustration1.png";
import Logo from "assets/images/logogold.png";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function Forgot() {
  const toast = useToast();
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("+234");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const inputColor = useColorModeValue("brand.500", "gray.100");

  const forgot = async () => {
    try {
      if (!validateCaptcha(captchaToken)) {
        setCaptchaToken("");
        toast({
          title: "Invalid Captcha Entered",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const userPhone = countryCode + "" + phone;
      const { data: response } = await axios.post(
        SERVER_URL + "/user/forgot",
        { phone: userPhone },
        {
          headers: {
            Authorization: "",
          },
          mode: "cors",
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (response.success) {
        setLoading(false);
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setEmail(response.data.email);
        navigate("/auth/otp", { state: { email: response.data.email } });
        return;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(4, "#292a73", "#ffffff", "upper");
  }, []);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        mb="10"
        h="100%"
        alignItems="start"
        justifyContent="center"
        flexDirection="column"
      >
        <Flex minH={"100vh"} width={"100%"} mt="10" justify={"center"}>
          <Stack
            spacing={8}
            mx={"auto"}
            w={{ base: "95%", sm: "95%", md: "70%", lg: "65%" }}
          >
            <Stack align={"center"}>
              <Image
                src={Logo}
                alt="Swaptrex"
                height="auto"
                width="70px"
                mb="5"
              />
              <Heading
                fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                textAlign={"center"}
                fontFamily="ManropeSemiBold"
              >
                Forgot Password
              </Heading>
              <Text fontSize={"lg"} fontFamily="ManropeRegular">
                Enter your phone to proceed
              </Text>
            </Stack>
            <Box rounded={"lg"} p={2} width="100%">
              <Stack spacing={4} width="100%">
                <HStack>
                  <Box width="30%">
                    <FormControl id="code" isRequired>
                      <FormLabel fontFamily="ManropeSemiBold">Phone</FormLabel>
                      <Input
                        color={inputColor}
                        type="text"
                        maxLength={4}
                        value="+234"
                        readOnly
                      />
                    </FormControl>
                  </Box>
                  <Box width="70%">
                    <FormControl id="phone">
                      <FormLabel fontFamily="ManropeSemiBold">&nbsp;</FormLabel>
                      <Input
                        type="text"
                        color={inputColor}
                        maxLength={10}
                        placeholder="8012345678"
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Box>
                </HStack>

                {/*<FormControl id="email" isRequired>
                  <FormLabel fontFamily="ManropeSemiBold">Email address</FormLabel>
                  <Input type="email" maxLength={100} onChange={(e)=>{
                             setEmail(e.target.value);
                            }} />
                 </FormControl>*/}

                <Stack spacing={10} pt={2}>
                  <Flex columns={{ sm: 2, md: 2 }} direction={{sm:"row", md:"row"}}>
                    <Box>
                      <LoadCanvasTemplate reloadText="Reload" />
                    </Box>
                    <Box>
                      <Input
                        borderRadius="0"
                        type={"text"}
                        defaultValue={captchaToken}
                        height="30"
                        maxLength={6}
                        onChange={(e) => {
                          setCaptchaToken(e.target.value);
                        }}
                        color={inputColor}
                        placeholder="Enter Captcha"
                      />
                    </Box>
                  </Flex>

                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    isLoading={loading}
                    loadingText="Please Wait"
                    mb="24px"
                    onClick={(e) => {
                      e.preventDefault();
                      forgot();
                    }}
                  >
                    Reset Password
                  </Button>
                </Stack>
                <Stack pt={0}>
                  <Text align={"center"}>
                    Don't have an account yet?{" "}
                    <Link color={"blue.400"} to="/auth/signup">
                      <u>
                        <b>Sign Up</b>
                      </u>
                    </Link>
                  </Text>
                </Stack>
                <Stack pt={0}>
                  <Text align={"center"}>
                    Remember Password?{" "}
                    <Link color={"blue.400"} to="/auth/index">
                      <u>
                        <b>Login</b>
                      </u>
                    </Link>
                  </Text>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Forgot;
