import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Flex,
  Box,
  FormControl,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  Image,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import axios from "axios";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/images/illustration1.png";
import Logo from "assets/images/logogold.png";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function SignUp() {
  const toast = useToast();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState("+234");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const inputColor = useColorModeValue("brand.500", "gray.100");

  const register = async () => {
    try {
      if(!validateCaptcha(captchaToken)) {
        setCaptchaToken("");
        toast({
          title: "Invalid Captcha Entered",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const userPhone = countryCode + "" + phone;
      const { data: response } = await axios.post(
        SERVER_URL + "/user/register",
        {
          firstName,
          lastName,
          phone: userPhone,
          email,
          password
        },
        {
          headers: {
            Authorization: "",
          },
          mode: "cors",
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        setLoading(false);
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        navigate("/auth/activate", { state: { email: email } });
        return;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(4, '#292a73', '#ffffff', 'upper');
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign Up | Create an account</title>
        <meta
          name="description"
          content=" Create an account to enjoy a personalized experience, receive updates, and access exclusive offers. Register now and join our community"
        />
        <meta
          name="keywords"
          content="Virtual Card, virtual payment,virtual card,virtual credit card,virtual debit card,virtual visa card,virtual pay,virtual payment cards,virtual visa,generate a virtual credit card,instant virtual credit card,virtual pay card,virtual card for online payment,instant virtual card,mastercard virtual card,virtual mastercard,virtual bank card,virtual visa card instant,online virtual credit card,instant virtual debit card,generate virtual credit card,create virtual credit card"
        />
      </Helmet>
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          maxW={{ base: "100%", md: "100%" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          mb="10"
          h="100%"
          alignItems="start"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex minH={"100vh"} width={"100%"} mt="10" justify={"center"}>
            <Stack
              spacing={8}
              mx={"auto"}
              w={{ base: "95%", sm: "95%", md: "70%", lg: "65%" }}
            >
              <Stack align={"center"}>
                <Image src={Logo} alt="Swaptrex" height="auto" width="60px" />
                {/*<Heading fontSize={{base:"19px", sm: "22px", md:"23px"}} mb="5">Swaptrex</Heading> */}
                <Text
                  fontFamily="ManropeLight"
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                >
                  Let's get you started
                </Text>
                <Heading
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                  textAlign={"center"}
                  fontFamily="ManropeSemiBold"
                >
                  Sign up
                </Heading>
              </Stack>
              <Box rounded={"lg"} p={2}>
                <Stack spacing={4}>
                  <HStack>
                    <Box>
                      <FormControl id="firstName" isRequired>
                        {/*<FormLabel fontFamily="ManropeSemiBold">
                               First Name
                          </FormLabel>*/}
                        <Input
                          type="text"
                          placeholderTextColor="#000000"
                          maxLength={20}
                          color={inputColor}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                          placeholder="First Name"
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl id="lastName" isRequired>
                        {/*<FormLabel fontFamily="ManropeSemiBold">
                        Last Name
                      </FormLabel>*/}
                        <Input
                          type="text"
                          maxLength={20}
                          placeholderTextColor="#000"
                          color={inputColor}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                          placeholder="Last Name"
                        />
                      </FormControl>
                    </Box>
                  </HStack>

                  <FormControl id="email" isRequired>
                    {/*<FormLabel fontFamily="ManropeSemiBold">
                    Email Address
                      </FormLabel>*/}
                    <Input
                      type="email"
                      placeholderTextColor="#000000"
                      color={inputColor}
                      maxLength={100}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      placeholder="Email Address"
                    />
                  </FormControl>

                  <HStack>
                    <Box width="30%">
                      <FormControl id="code" isRequired>
                        {/*<FormLabel fontFamily="ManropeSemiBold">Phone</FormLabel>*/}
                        <Input
                         placeholderTextColor="#000000"
                          color={inputColor}
                          type="text"
                          maxLength={4}
                          value="+234"
                          readOnly
                        />
                      </FormControl>
                    </Box>
                    <Box width="70%">
                      <FormControl id="phone">
                        {/*<FormLabel fontFamily="ManropeSemiBold">&nbsp;</FormLabel>*/}
                        <Input
                          type="text"
                          placeholderTextColor="#000000"
                          maxLength={10}
                          color={inputColor}
                          placeholder="8012345678"
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                      </FormControl>
                    </Box>
                  </HStack>

                  <FormControl id="password" isRequired>
                    {/* <FormLabel fontFamily="ManropeSemiBold">Password</FormLabel> */}
                    <InputGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        maxLength={100}
                        color={inputColor}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        placeholder="Password"
                      />
                      <InputRightElement h={"full"}>
                        <Button
                          variant={"ghost"}
                          bg={useColorModeValue("brand.500", "gray.100")}
                          color={useColorModeValue("white", "brand.500")}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                        >
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <Stack spacing={10} pt={2}>
                   
                  <Flex columns={{ sm: 2, md: 2 }} direction={{sm:"row", md:"row"}}>
                    <Box>
                      <LoadCanvasTemplate reloadText="Reload" />
                    </Box>
                    <Box>
                      <Input
                        borderRadius="0"
                        type={"text"}
                        defaultValue={captchaToken}
                        height="30"
                        maxLength={6}
                        onChange={(e) => {
                          setCaptchaToken(e.target.value);
                        }}
                        color={inputColor}
                        placeholder="Enter Captcha"
                      />
                    </Box>
                  </Flex>

                    <Button
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      w="100%"
                      h="50"
                      isLoading={loading}
                      loadingText="Please Wait"
                      mb="4"
                      onClick={(e) => {
                        e.preventDefault();
                        register();
                      }}
                    >
                      <b>Sign Up</b>
                    </Button>
                  </Stack>
                  <Stack pt={2}>
                    <Text align={"center"}>
                      Already a user?{" "}
                      <Link color={"blue.400"} to="/auth/index">
                        <b>Login</b>
                      </Link>
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Flex>
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default SignUp;
