import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Flex,
  Text,
  Heading,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  Input,
  Icon,
  Stack,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Card from "components/card/Card.js";
import CustomSelect from "./components/CustomSelect.js";
import { FiChevronLeft } from "react-icons/fi";

export default function AddAccount() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);
  const [accountNotLoaded, setAccountNotLoaded] = useState(true);
  const [banks, setBanks] = useState([]);
  const [bankCode, setBankCode] = useState(null);
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountName, setAccountName] = useState(null);
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();
  const navigate = useNavigate();
  const textColorSecondary = useColorModeValue("gray.700", "gray.100");
  const inputColor = useColorModeValue("brand.500", "gray.100");

  const addAccount = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/addAccount",
        { accountNumber, accountName, bankName, bankCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      navigate("/user/accounts");
      setLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getBanks = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.get(SERVER_URL + "/misc/banks", {
        headers: {},
      });
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (response.success) {
        setBanks(response.data);
      }
      setLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getAccountInfo = async () => {
    try {
      setAccountNotLoaded(true);
      if (accountNumber.length !== 10) {
        return;
      }
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/misc/getAccountInfo",
        { accountNumber, bankCode },
        {
          headers: {},
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setAccountName(response.data);
        setAccountNotLoaded(false);
        setLoading(false);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  useEffect(() => {
    getAccountInfo();
  }, [accountNumber, bankCode]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }} spacing="2" mb="3">
        <Box textAlign="start">
          <Heading
            bg="inherit"
            fontWeight="bold"
            fontFamily="ManropeSemiBold"
            fontSize={{ base: "md", sm: "md", md: "lg", lg: "xl" }}
            mb="5"
          >
            Create Beneficiary
          </Heading>
        </Box>
        <Box textAlign="right">
          <Link to="/user/accounts">
            <Flex direction={"row-reverse"}>
              <Text mt="2" as="span">
                Back
              </Text>
              <Icon as={FiChevronLeft} w={8} h={8} mt="1" />
            </Flex>
          </Link>
        </Box>
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }}>
        <Card align="center" p="20px">
          <Stack
            spacing={4}
            w={"full"}
            maxW={"md"}
            p={6}
            my={5}
          >
            <FormControl>
              <FormLabel color={textColorSecondary}  fontWeight="bold">Bank</FormLabel>
              <CustomSelect
                options={banks}
                setBankCode={setBankCode}
                setBankName={setBankName}
                accountNumber={accountNumber}
                getAccountInfo={getAccountInfo}
                loading={loading}
              />
            </FormControl>

            <FormControl>
              <FormLabel color={textColorSecondary}  fontWeight="bold">Account Number</FormLabel>
              <Input
                type="text"
                onKeyUp={(e) => {
                  setAccountNumber(e.target.value);
                  if (bankCode !== null && e.target.value.length === 10) {
                    getAccountInfo();
                  }
                }}
                borderRadius="20"
                maxLength="10"
                color={inputColor}
              />
            </FormControl>

            <FormControl>
              <FormLabel color={textColorSecondary}  fontWeight="bold">Account Name</FormLabel>
              <Input
                type="text"
                value={accountName}
                borderRadius="20"
                readOnly={"true"}
                color={inputColor}
              />
            </FormControl>

            <Button
              isLoading={loading}
              bg={useColorModeValue("brand.500", "gray.100")}
              color={useColorModeValue("white", "brand.500")}
              w="full"
              disabled={accountNotLoaded}
              borderRadius="20"
              _hover={{
                bg: "blue.500",
                color: "white"
              }}
              onClick={(e) => {
                e.preventDefault();
                addAccount();
              }}
            >
              Submit
            </Button>
          </Stack>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
