import React from "react";
import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import CallToAction from "./components/CallToAction.js";
import AboutSection from "./components/About.js";
import Header from "components/navigation/Header.js";

export default function About() {
  return (
    <Box minH="100vh">
      <Helmet>
        <title>About Us | Get to know our story</title>
        <meta
          name="description"
          content="Apply for a virtual debit card and get it issued instantly with our online application process. Our service is secure and reliable, and you can start using your virtual card for online purchases and payments immediately."
        />
        <meta
          name="keywords"
          content="Virtual Card, virtual payment,virtual card,virtual credit card,virtual debit card,virtual visa card,virtual pay,virtual payment cards,virtual visa,generate a virtual credit card,instant virtual credit card,virtual pay card,virtual card for online payment,instant virtual card,mastercard virtual card,virtual mastercard,virtual bank card,virtual visa card instant,online virtual credit card,instant virtual debit card,generate virtual credit card,create virtual credit card"
        />
      </Helmet>
      <Header />
      <AboutSection />
      <CallToAction />
    </Box>
  );
}
