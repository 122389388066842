import {
  Box,
  SimpleGrid,
  Stack,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Button,
  Switch,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

export default function Settings() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);
  const [buyRate, setBuyRate] = useState(0);
  const [sellRate, setSellRate] = useState(0);
  const [depositFeeRate, setDepositFeeRate] = useState(0);
  const [depositFeeCapAmount, setDepositFeeCapAmount] = useState(0);
  const [depositFeeCapRate, setDepositFeeCapRate] = useState(0);
  const [cardCreationFee, setCardCreationFee] = useState(0);
  const [cardCreationRevenue, setCardCreationRevenue] = useState(0);

  const [withdrawalFeeRevenue, setWithdrawalFeeRevenue] = useState(0);
  const [sellRateRevenue, setSellRateRevenue] = useState(0);
  const [exchangeRateDifferential, setExchangeRateDifferential] = useState(0);
  
  const [buyFeeCap, setBuyFeeCap] = useState(0);
  const [sellFeeCap, setSellFeeCap] = useState(0);

  const [withdrawalFee, setWithdrawalFee] = useState(0);
  const [disableWithdrawal, setDisableWithdrawal] = useState(false);
  const [disableBuy, setDisableBuy] = useState(false);
  const [disableSell, setDisableSell] = useState(false);

  const inputColor = useColorModeValue("brand.500", "gray.100");
  const color = useColorModeValue("white", "brand.500");

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const { data: response } = await axios.patch(
        SERVER_URL + "/settings",
        {
          buyRate,
          sellRate,
          withdrawalFee,
          withdrawal: disableWithdrawal,
          depositFeeRate,
          buyFeeCap,
          sellFeeCap,
          depositFeeCapAmount,
          depositFeeCapRate,
          cardCreationFee,
          withdrawalFeeRevenue,
          sellRateRevenue,
          cardCreationRevenue,
          buy: disableBuy,
          sell: disableSell,
          exchangeRateDifferential
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (response.success) {
        toast({
          title: response.message || "User updated",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const getSettings = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.get(SERVER_URL + "/settings", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (response.success) {
        setLoading(false);
        setBuyRate(response.data.buyRate);
        setSellRate(response.data.sellRate);
        setWithdrawalFee(response.data.withdrawalFee);
        setDisableWithdrawal(response.data.withdrawal);
        setDepositFeeRate(response.data.depositFeeRate);
        setBuyFeeCap(response.data.buyFeeCap);
        setSellFeeCap(response.data.sellFeeCap);
        setDepositFeeCapAmount(response.data.depositFeeCapAmount);
        setDepositFeeCapRate(response.data.depositFeeCapRate);
        setWithdrawalFeeRevenue(response.data.withdrawalFeeRevenue);
        setSellRateRevenue(response.data.sellRateRevenue);
        setCardCreationFee(response.data.cardCreationFee);
        setCardCreationRevenue(response.data.cardCreationRevenue);
        setDisableBuy(response.data.buy);
        setDisableSell(response.data.sell);
        setExchangeRateDifferential(response.data.exchangeRateDifferential);
       
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <Box
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      overflow="hidden"
      width={{ base: "100%", sm: "100%", md: "100%" }}
    >
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }}>
        <Card align="center" p="20px">
          <Stack spacing={2} w={"full"} p={6} my={5}>
            <FormControl id="sellRate" isRequired>
              <FormLabel>
                <b>Loading Fee Rate</b>
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                value={sellRate}
                onChange={(event) => setSellRate(event.target.value)}
              />
              <FormHelperText>
                The rate at which users loads card in %
              </FormHelperText>
            </FormControl>

            <FormControl id="sellRate" isRequired>
              <FormLabel>
                <b>Loading Revenue</b>
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                value={sellRateRevenue}
                onChange={(event) => setSellRateRevenue(event.target.value)}
              />
              <FormHelperText>Loading Revenue in %</FormHelperText>
            </FormControl>

            <FormControl id="buyRate" isRequired>
              <FormLabel>
                <b>Unload Fee Rate</b>
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                value={buyRate}
                onChange={(event) => setBuyRate(event.target.value)}
              />
              <FormHelperText>
                The rate at which users unloads card in %
              </FormHelperText>
            </FormControl>

            <FormControl id="withdrawalFee" isRequired>
              <FormLabel>
                <b>Withdrawal Fee</b>
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                value={withdrawalFee}
                onChange={(event) => setWithdrawalFee(event.target.value)}
              />
              <FormHelperText>
                The fee for withdrawing USD funds (%)
              </FormHelperText>
            </FormControl>

            <FormControl id="withdrawalFee" isRequired>
              <FormLabel>
                <b>Withdrawal Fee Revenue</b>
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                value={withdrawalFeeRevenue}
                onChange={(event) =>
                  setWithdrawalFeeRevenue(event.target.value)
                }
              />
              <FormHelperText>Withdrawal Revenue (%)</FormHelperText>
            </FormControl>

            <FormControl id="sellFeeCap" isRequired>
              <FormLabel>
                <b>Loading Fee Cap</b>
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                value={sellFeeCap}
                onChange={(event) => setSellFeeCap(event.target.value)}
              />
              <FormHelperText>The fee cap for loading card (fixed).</FormHelperText>
            </FormControl>

            <FormControl id="buyFeeCap" isRequired>
              <FormLabel>
                <b>Unloading Fee Cap</b>
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                value={buyFeeCap}
                onChange={(event) => setBuyFeeCap(event.target.value)}
              />
              <FormHelperText>The fee cap for unloading card (fixed).</FormHelperText>
            </FormControl>

            <FormControl id="depositFeeRate" isRequired>
              <FormLabel>
                <b>Deposit Fee Rate</b>
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                value={depositFeeRate}
                onChange={(event) => setDepositFeeRate(event.target.value)}
              />
              <FormHelperText>Deposit fee in %</FormHelperText>
            </FormControl>

            <FormControl id="depositFeeRate" isRequired>
              <FormLabel>
                <b>Deposit Fee Cap Rate</b>
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                value={depositFeeCapRate}
                onChange={(event) => setDepositFeeCapRate(event.target.value)}
              />
              <FormHelperText>Deposit fee cap in %</FormHelperText>
            </FormControl>

            <FormControl id="depositFeeRate" isRequired>
              <FormLabel>
                <b>Deposit Fee Cap Amount</b>
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                value={depositFeeCapAmount}
                onChange={(event) => setDepositFeeCapAmount(event.target.value)}
              />
              <FormHelperText>Deposit fee cap amount (fixed)</FormHelperText>
            </FormControl>

            <FormControl id="depositFeeRate" isRequired>
              <FormLabel>
                <b>Card Creation Fee</b>
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                value={cardCreationFee}
                onChange={(event) => setCardCreationFee(event.target.value)}
              />
              <FormHelperText>Card creation fee cap amount (fixed)</FormHelperText>
            </FormControl>

            <FormControl id="depositFeeRate" isRequired>
              <FormLabel>
                <b>Card Creation Revenue</b>
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                value={cardCreationRevenue}
                onChange={(event) => setCardCreationRevenue(event.target.value)}
              />
              <FormHelperText>Card creation revenue amout (fixed)</FormHelperText>
            </FormControl>

            <FormControl id="exchangeRateDifferential" isRequired>
              <FormLabel>
                <b>Exchange Rate Differential</b>
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                value={exchangeRateDifferential}
                onChange={(event) => setExchangeRateDifferential(event.target.value)}
              />
              <FormHelperText>Naira Excahnge Rate Difference Factor</FormHelperText>
            </FormControl>


            <FormControl display="flex" alignItems="center">
              <Switch
                id="disableWithdrawal"
                isChecked={disableWithdrawal}
                onChange={(event) => setDisableWithdrawal(event.target.checked)}
              />
              <FormLabel htmlFor="disableWithdrawal" mb="0">
                <b>&nbsp;Disable Withdrawal</b>
              </FormLabel>
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <Switch
                id="disableBuy"
                isChecked={disableBuy}
                onChange={(event) => setDisableBuy(event.target.checked)}
              />
              <FormLabel htmlFor="disableBuy" mb="0">
                <b>&nbsp;Disable Buy</b>
              </FormLabel>
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <Switch
                id="disableSell"
                isChecked={disableBuy}
                onChange={(event) => setDisableSell(event.target.checked)}
              />
              <FormLabel htmlFor="disableSell" mb="0">
                <b>&nbsp;Disable Sell</b>
              </FormLabel>
            </FormControl>

            <Button
              bg={"blue.400"}
              color={"white"}
              w="full"
              borderRadius="20"
              _hover={{
                bg: "blue.500",
              }}
              mt={10}
              isLoading={loading}
              loadingText={"Wait..."}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Stack>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
