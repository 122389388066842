import React from "react";
import { Link } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  VStack,
  Text
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";

export default function Technical(props) {
  const {user} = props;
  return (
    <Card shadow="md" background="blue">
    <VStack>
      <Box textAlign='start' width="100%">
      <Text
        color="white"
        fontSize={{base:'xs',sm:'sm',md:'md',lg:'lg'}}
        my={{ base: "auto", "2xl": "10px" }}
        textAlign='start'>
        Hi
      </Text>
      <Text
        color="white"
        fontWeight="bold"
        fontSize={{ base: "md", sm: "md", md: "lg", lg: "lg" }}
        my={{ base: "auto", "2xl": "10px" }}
        textAlign='start'>
        {user.firstName} {user.lastName}
      </Text>
       <Text as="h3"
          color="white"
          textAlign='start'
          fontSize={{base:'l',sm:'l',md:'xl',lg:'xl'}}
          mt={{ base: "5px", "2xl": "5px" }}
          mb={{ base: "5px", "2xl": "5px" }}>
          We are currently experiencing a technical issue at the moment, be rest assured that we are working to restore card services shortly
      </Text>
      </Box>
    </VStack>
  </Card>
  );
}
