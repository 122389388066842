import React from "react";
import { Box, Flex, Image, Heading } from "@chakra-ui/react";
import LogoGold from "assets/images/logogold.png";
import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <Link to="/web/index">
      <Box>
        <Flex>
          <Image
            boxSize="50px"
            objectFit="cover"
            src={LogoGold}
            alt="Swaptrex Virtual Card"
            mt="2"
          />
          <Heading
            as="h3"
            mt="4"
            ml="2"
            fontSize={{ base: "xl", sm: "2xl", md: "2xl" }}
          >
            Swaptrex
          </Heading>
        </Flex>
      </Box>
    </Link>
  );
}
