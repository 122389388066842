import React from "react";
// Chakra imports
import {
  Box,
  HStack,
  Stack,
  Tag,
  Center,
  Icon,
  Text,
  useColorModeValue,
  Circle,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { FiArrowUp, FiArrowDown } from "react-icons/fi";
// Custom components
import Card from "components/card/Card.js";
// Assets
const { DateTime } = require("luxon");

export default function Transactions(props) {
  const { transactions, ...rest } = props;
  // Chakra Color Mode
  const colors = {
    completed: "#00798c",
    pending: "#e28743",
    cancelled: "#d1495b",
  };

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromISO(timestamp);
    return myDateTime.toLocaleString(DateTime.DATETIME_MED);
  };

  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.800", "white");
  return (
    <>
      {transactions !== null &&
        transactions.map((transaction) => (
          <Card width="100%" p="5" columns={3} mt="3" key={transaction._id}>
            <Stack direction={{ base: "row", lg: "row" }}>
              <Box width="80%">
                <HStack columns={2} spacing="3">
                  <Box w="10%" textAlign={"end"}>
                    <Center width="100%">
                      <Circle size="40px" bg="brand.500" color="white">
                        {transaction.leg !== null &&
                        transaction.leg === "credit" ? (
                          <Icon
                            as={FiArrowDown}
                            w="25px"
                            h="25px"
                            color="#fff"
                          />
                        ) : (
                          <Icon as={FiArrowUp} w="25px" h="25px" color="orange.500" />
                        )}
                      </Circle>
                    </Center>
                  </Box>
                  <Box w="65%">
                    <VStack p="0" w="100%">
                      <Box w="100%" textAlign={"start"}>
                        <Heading
                          as="h4"
                          color={textColorSecondary}
                          fontFamily="ManropeSemiBold"
                          fontWeight="normal"
                          fontSize={{
                            base: "sm",
                            sm: "sm",
                            md: "md",
                            lg: "lg",
                          }}
                        >
                          {transaction.type}
                        </Heading>
                      </Box>
                      <Box w="100%" textAlign={"start"}>
                        <Text
                          color={textColorPrimary}
                          fontSize={{
                            base: "sm",
                            sm: "sm",
                            md: "md",
                            lg: "md",
                          }}
                        >
                          {" "}
                          {formatTime(transaction.createdAt)}{" "}
                        </Text>
                      </Box>
                    </VStack>
                  </Box>
                  <Box width="25%">
                    <Text
                      fontWeight={600}
                      color={textColorSecondary}
                      fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg" }}
                    >
                      {transaction.currency}{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(transaction.amount)}
                    </Text>
                    <Text
                      color={textColorPrimary}
                      fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                    >
                      {transaction.narration}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box width="20%" align="center" justify="center">
                <Tag
                  mt="3"
                  colorScheme="blue"
                  background={
                    transaction.status !== null
                      ? colors[transaction.status]
                      : colors["completed"]
                  }
                  color="white"
                  borderRadius="full"
                  size="sm"
                >
                  {transaction.status}
                </Tag>
              </Box>
            </Stack>
          </Card>
        ))}
    </>
  );
}
