import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Box,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  Flex,
  Input,
  NumberInput,
  NumberInputField,
  Stack,
  Center,
  Icon,
  Skeleton,
  SkeletonText,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiChevronLeft, FiPlus, FiArrowLeft } from "react-icons/fi";

import Card from "components/card/Card.js";
import Bank from "./components/Bank.js";
import Paginate from "components/navigation/Paginate.js";

export default function Fiat() {
  const navigate = useNavigate();
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);
  //const [settings, setSettings] = useState(null);
  const [walletLoading, setWalletLoading] = useState(true);
  const [accountsIsLoading, setAccountsIsLoading] = useState(true);
  const [wallet, setWallet] = useState(null);
  const [transfer, setTransfer] = useState(false);
  const [fee, setFee] = useState(0);
  const [amount, setAmount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [from, setFrom] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();

  const getAccounts = async (from) => {
    try {
      const { data: response } = await axios.post(
        SERVER_URL + "/user/accounts",
        { from, limit },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setAccountsIsLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setAccountsIsLoading(false);
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setAccounts(response.data.accounts);
        setAccountsIsLoading(false);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setAccountsIsLoading(false);
    }
  };

  const getWallet = async (from) => {
    try {
      const { data: response } = await axios.get(SERVER_URL + "/wallet/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setWalletLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setWalletLoading(false);
        return;
      }
      if (response.success) {
        setWallet(response.data);
        setWalletLoading(false);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const transerFund = async () => {
    try {
      if (amount >= wallet.balance) {
        toast({
          title: "You have insufficient balance to make this transfer",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      navigate("/user/confirmtransfer", { state: { amount: amount, account: account } });
    } catch (error) {
        toast({
          title: error.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
  };

  /*const getSettings = async () => {
    try {
      const { data: response } = await axios.get(SERVER_URL + "/settings", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.success) {
        setSettings(response.data);
      }
    } catch (error) {
    }
  };*/

  useEffect(() => {
    getAccounts();
    getWallet();
    //getSettings();
  }, []);

  return (
    <>
      {transfer === false ? (
        <Accounts
          accounts={accounts}
          setAccount={setAccount}
          setTransfer={setTransfer}
          setFrom={setFrom}
          pageCount={pageCount}
          getAccounts={getAccounts}
          accountsIsLoading={accountsIsLoading}
        />
      ) : (
        <Transfer
          setError={setError}
          setErrorMessage={setErrorMessage}
          error={error}
          errorMessage={errorMessage}
          //settings={settings}
          fee={fee}
          setFee={setFee}
          wallet={wallet}
          account={account}
          transerFund={transerFund}
          setTransfer={setTransfer}
          setAmount={setAmount}
          loading={loading}
        />
      )}
    </>
  );
}

const AccountsLoading = () => {
  return (
    <>
      <Card align="center" p="20px" mt="10px">
        <Stack>
          <Skeleton height="30px" />
          <SkeletonText mt="5" noOfLines={5} spacing="4" />
          <Skeleton height="30px" />
        </Stack>
      </Card>
      <Card align="center" p="20px" mt="10px">
        <Stack>
          <Skeleton height="30px" />
          <SkeletonText mt="5" noOfLines={5} spacing="4" />
          <Skeleton height="30px" />
        </Stack>
      </Card>
    </>
  );
};

const Transfer = (props) => {
  const { wallet, account, transerFund, setTransfer, setAmount, fee, setFee, setError, setErrorMessage, error, errorMessage, loading } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.800", "white");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }} spacing="2" mb="3">
        <Box textAlign="start">
          <Flex
            onClick={(e) => {
              setTransfer(false);
            }}
          >
            <Icon as={FiChevronLeft} w={10} h={10} /> <Text mt="2">Back</Text>
          </Flex>
        </Box>
        <Box textAlign="right"></Box>
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }}>
        <Card align="center" p="20px">
          <Stack
            spacing={4}
            w={"full"}
            maxW={"md"}
            bg={useColorModeValue("white", "gray.700")}
            p={6}
            my={5}
          >
            <FormControl>
              <FormLabel color={textColorSecondary} fontWeight="bold">Bank Name</FormLabel>
              <Input
                type="text"
                value={account.bankName}
                borderRadius="20"
                readOnly
              />
            </FormControl>

            <FormControl>
              <FormLabel color={textColorSecondary} fontWeight="bold">Beneficiary</FormLabel>
              <Input
                type="text"
                value={account.accountNumber + "(" + account.accountName + ")"}
                borderRadius="20"
                readOnly
              />
            </FormControl>

            <FormControl>
              <FormLabel color={textColorSecondary} fontWeight="bold">Amount</FormLabel>
              <NumberInput precision={2}>
                <NumberInputField
                  borderRadius="20"
                  onChange={(e) => {
                    setAmount(e.target.value);
                   /*const fee =
                    new Intl.NumberFormat("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 5,
                    }).format((parseFloat(e.target.value) * settings.withdrawalFee) + 0.3);
                  
                  if(!isNaN(fee))
                   {setFee(fee)}
                   else{setFee(0)}*/

                  if (parseFloat(e.target.value) > wallet.balance) {
                    setError(true);
                    setErrorMessage("Insufficient balance");
                  } else {
                    setError(false);
                  }
                  }}
                />
              </NumberInput>
              {error && (
                  <Center>
                    <FormErrorMessage textAlign="center">
                      {errorMessage}
                    </FormErrorMessage>
                  </Center>
              )}
            </FormControl>

            <Button
              isLoading={loading}
              bg={"brand.500"}
              color={"white"}
              w="full"
              borderRadius="20"
              _hover={{
                bg: "blue.500",
              }}
              loadingText="Wait.."
              onClick={(e) => {
                e.preventDefault();
                transerFund();
              }}
            >
              Transfer
            </Button>

            {/*<Text>
                <b>Withdrawal Fee:</b> {settings!==null && fee} USDT
            </Text>*/}

          </Stack>
        </Card>
      </SimpleGrid>
    </Box>
  );
};

const Accounts = (props) => {
  const {
    accounts,
    setAccount,
    getAccounts,
    pageCount,
    setTransfer,
    setFrom,
    accountsIsLoading,
  } = props;
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }} spacing="2">
        <Box textAlign="right">
          <FormControl>
            <Input
              type="text"
              placeHolder="Search for beneficiary"
              onKeyUp={(e) => {}}
              borderRadius="20"
              maxLength="10"
            />
          </FormControl>
        </Box>
        <Box textAlign="right">
          <Link to="/user/addaccount">
            <Button
              fontSize={{ base: "xs", sm: "xs", md: "sm", lg: "sm" }}
              rightIcon={
                <Icon as={FiPlus} width="15px" height="15px" color="white" />
              }
              variant="primary" background="brand.500"
            >
              Create Beneficiary
            </Button>
          </Link>
        </Box>
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, sm: 1, md: 3, lg: 3 }} spacing="2">
        {!accountsIsLoading ? (
          accounts.map((bankaccount) => (
            <Bank
              key={bankaccount.accountNumber}
              account={bankaccount}
              setTransfer={setTransfer}
              setAccount={setAccount}
            />
          ))
        ) : (
          <AccountsLoading />
        )}
      </SimpleGrid>
      {accounts.length > 0 && (
        <Box width="100%" mt="10" textAlign="center">
          <Center width="100%">
            <Paginate
              pageCount={pageCount}
              setFrom={setFrom}
              getRecords={getAccounts}
            />
          </Center>
        </Box>
      )}
      {accounts.length === 0 && (
        <Box width="100%" mt="10" textAlign="center">
          <Center width="100%">
            <Text>No accounts added yet</Text>
          </Center>
        </Box>
      )}
    </Box>
  );
};
