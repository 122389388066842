import React from "react";
import {Box,
        VStack,
        Heading,
        Text,
        List,
        ListIcon,
        ListItem,
        OrderedList} from "@chakra-ui/react";
import {MdCheckCircle} from "react-icons/md";
import { Helmet } from "react-helmet";
import Header from "components/navigation/Header.js";

export default function Privacy() {
  return (
    <Box minH='100vh'>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Read our privacy policy to learn how we handle your personal and financial information, and how we protect your privacy and security. We are committed to keeping your information safe and secure, and to being transparent about our privacy practices." />
        <meta  name="keywords" content="Virtual Card, virtual payment,virtual card,virtual credit card,virtual debit card,virtual visa card,virtual pay,virtual payment cards,virtual visa,generate a virtual credit card,instant virtual credit card,virtual pay card,virtual card for online payment,instant virtual card,mastercard virtual card,virtual mastercard,virtual bank card,virtual visa card instant,online virtual credit card,instant virtual debit card,generate virtual credit card,create virtual credit card" />
      </Helmet>
       <Header />

       <VStack spacing="2"  color="black">
        <Box> <Heading as="h3" mt="10"> Privacy Policy</Heading>  </Box>
        <Box p={{base:'4',sm:'10', md:'20'}}> 
          <OrderedList spacing="4">
            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Introduction</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">Welcome to <Text as="span" fontWeight="bold">Swaptrex</Text> (“Company”, “we”, “our”, “us”)!</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">Tradersfield Ltd</Text> (“us”, “we”, or “our”) operates <Text as="span" fontWeight="bold">http://swaptrex.com</Text> (hereinafter referred to as “Service”).</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">Our Terms and Conditions <Text as="span" fontWeight="bold">(“Terms”)</Text> govern all use of our Service and together with the Privacy Policy constitutes your agreement with us  <Text as="span" fontWeight="bold">(“agreement”)</Text>.</Text>
            </ListItem>
            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Definitions</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}><Text as="span" fontWeight="bold">SERVICE</Text> means the http://swaptrex.com website operated by Tradersfield Limited.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">PERSONAL DATA</Text> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">USAGE DATA</Text> is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">COOKIES</Text> are small files stored on your device (computer or mobile device).</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">DATA CONTROLLER</Text> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">DATA PROCESSORS (OR SERVICE PROVIDERS)</Text> means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">DATA SUBJECT</Text> is any living individual who is the subject of Personal Data.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"><Text as="span" fontWeight="bold">THE USER</Text> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data</Text>
              
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Information Collection and Use</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>We collect several different types of information for various purposes to provide and improve our Service to you.</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Types of Data Collected</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} fontWeight="bold">Personal Data</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</Text>
               <List spacing={3}>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  Email address
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                <ListIcon as={MdCheckCircle} color='green.500' />
                 First name and last name
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Phone number
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Address, Country, State, Province, ZIP/Postal code, City
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                 <ListIcon as={MdCheckCircle} color='green.500' />
                  Cookies and Usage Data
                </ListItem>
              </List>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} fontWeight="bold">Usage Data</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through any device (“Usage Data”).</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} fontWeight="bold">Location Data</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customize our Service.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">You can enable or disable location services when you use our Service at any time by way of your device settings.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} fontWeight="bold">Tracking Cookies Data</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">Examples of Cookies we use:</Text>
              <List spacing={3}>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span" fontWeight="bold">Session Cookies:</Text> We use Session Cookies to operate our Service.
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span" fontWeight="bold">Preference Cookies:</Text> We use Preference Cookies to remember your preferences and various settings.
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span" fontWeight="bold">Security Cookies:</Text> We use Security Cookies for security purposes.
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span" fontWeight="bold">Advertising Cookies:</Text> Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.
                </ListItem>
              </List>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2" fontWeight="bold">Other Data</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">While using our Service, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, NDA agreements, information on bonuses and compensation, information on marital status, family members, social security (or other taxpayer identification) number, office location and other data.</Text>
            </ListItem>



            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Use of Data</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>Swaptrex uses the collected data for various purposes:</Text>
              <List spacing={3}>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span">to provide and maintain our Service;</Text> 
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span">to notify you about changes to our Service;</Text> 
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span">to allow you to participate in interactive features of our Service when you choose to do so;</Text> 
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span">to provide customer support;</Text> 
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span">to gather analysis or valuable information so that we can improve our Service;</Text> 
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span">to monitor the usage of our Service;</Text> 
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span">to detect, prevent and address technical issues;</Text> 
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span">to fulfil any other purpose for which you provide it;</Text> 
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span">to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</Text> 
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span">to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;</Text> 
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span">to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</Text> 
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span">in any other way we may describe when you provide the information;</Text> 
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text as="span">for any other purpose with your consent.</Text> 
                </ListItem>
              </List>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Retention of Data</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Transfer of Data</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">If you are located outside Nigeria and choose to provide information to us, please note that we transfer the data, including Personal Data, to Nigeria and process it there.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">Swaptrex will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Disclosure of Data</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>We may disclose personal information that we collect, or you provide:</Text>
              <List spacing={3}>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text fontWeight="bold" as="span"> Business Transaction.</Text> 
                  <Text>If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</Text>
                </ListItem>
                <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                  <ListIcon as={MdCheckCircle} color='green.500' />
                  <Text fontWeight="bold"  as="span">Other cases. We may disclose your information also:</Text> 
                  <Text>If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.</Text>
                     <List spacing={3} ml="4">
                       <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                        <ListIcon as={MdCheckCircle} color='green.500' />
                        <Text  as="span">to our subsidiaries and affiliates;</Text>
                      </ListItem>
                      <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                        <ListIcon as={MdCheckCircle} color='green.500' />
                        <Text  as="span">to contractors, service providers, and other third parties we use to support our business;</Text>
                      </ListItem>
                      <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                        <ListIcon as={MdCheckCircle} color='green.500' />
                        <Text  as="span">to fulfill the purpose for which you provide it;</Text>
                      </ListItem>
                      <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                        <ListIcon as={MdCheckCircle} color='green.500' />
                        <Text  as="span">for the purpose of including your company’s logo on our website;</Text>
                      </ListItem>
                      <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                        <ListIcon as={MdCheckCircle} color='green.500' />
                        <Text  as="span">for any other purpose disclosed by us when you provide the information;</Text>
                      </ListItem>
                      <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                        <ListIcon as={MdCheckCircle} color='green.500' />
                        <Text  as="span">with your consent in any other cases;</Text>
                      </ListItem>
                      <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                        <ListIcon as={MdCheckCircle} color='green.500' />
                        <Text  as="span">if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</Text>
                      </ListItem>
                     </List>
                </ListItem>
              </List>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Security of Data</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Your Data Protection Rights Under General Data Protection Regulation (GDPR)</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at <Text as="span" fontWeight="bold">support@swaptrex.com</Text>.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">In certain circumstances, you have the following data protection rights:</Text>
              <List spacing={3} ml="4"> 
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text  as="span">the right to access, update or to delete the information we have on you;</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text  as="span">the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text  as="span">the right to object. You have the right to object to our processing of your Personal Data;</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text  as="span">the right of restriction. You have the right to request that we restrict the processing of your personal information;</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text  as="span">the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text  as="span">the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;</Text>
                  </ListItem>
              </List>

              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to provide Service without some necessary data</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</Text>
              
            </ListItem>


            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require a person or company in the United States (and conceivable the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">According to CalOPPA we agree to the following:</Text>
             
              <List spacing={3} ml="4"> 
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text as="span">users can visit our site anonymously;</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text as="span">our Privacy Policy link includes the word “Privacy”, and can easily be found on the home page of our website;</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text as="span">users will be notified of any privacy policy changes on our Privacy Policy Page;</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text as="span">users are able to change their personal information by emailing us at support@swaptrex.com.</Text>
                  </ListItem>
              </List>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">Our Policy on “Do Not Track” Signals:</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track browser mechanism is in place. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</Text>
            </ListItem>


            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Your Data Protection Rights under the California Consumer Privacy Act (CCPA)</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>If you are a California resident, you are entitled to learn what data we collect about you, ask to delete your data and not to sell (share) it. To exercise your data protection rights, you can make certain requests and ask us:</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} fontWeight="bold" mt="2">What personal information we have about you. If you make this request, we will return to you:</Text>
              
               <List spacing={3} ml="4"> 
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text as="span">The categories of personal information we have collected about you.</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text as="span">The categories of sources from which we collect your personal information.</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text as="span">The business or commercial purpose for collecting or selling your personal information.</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text as="span">The categories of third parties with whom we share personal information.</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text as="span">The specific pieces of personal information we have collected about you.</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text as="span">A list of categories of personal information that we have sold, along with the category of any other company we sold it to. If we have not sold your personal information, we will inform you of that fact</Text>
                  </ListItem>
                  <ListItem fontSize={{base:'md', sm:'lg', md:'xl'}}>
                    <ListIcon as={MdCheckCircle} color='green.500' />
                    <Text as="span">A list of categories of personal information that we have disclosed for a business purpose, along with the category of any other company we shared it with.</Text>
                    <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2"> Please note, you are entitled to ask us to provide you with this information up to two times in a rolling twelve-month period. When you make this request, the information provided may be limited to the personal information we collected about you in the previous 12 months.</Text>
                  </ListItem>
                </List>

                <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">Please note, you are entitled to ask us to provide you with this information up to two times in a rolling twelve-month period. When you make this request, the information provided may be limited to the personal information we collected about you in the previous 12 months.</Text>
              
                <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">To delete your personal information. If you make this request, we will delete the personal information we hold about you as of the date of your request from our records and direct any service providers to do the same. In some cases, deletion may be accomplished through de-identification of the information. If you choose to delete your personal information, you may not be able to use certain functions that require your personal information to operate</Text>
                
                <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">To stop selling your personal information. We don’t sell or rent your personal information to any third parties for any purpose. We do not sell your personal information for monetary consideration. However, under some circumstances, a transfer of personal information to a third party, or within our family of companies, without monetary consideration may be considered a “sale” under California law. You are the only owner of your Personal Data and can request disclosure or deletion at any time</Text>
                
                <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">If you submit a request to stop selling your personal information, we will stop making such transfers</Text>
                
                <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">Please note, if you ask us to delete or stop selling your data, it may impact your experience with us, and you may not be able to participate in certain programs or membership services which require the usage of your personal information to function. But in no circumstances, we will discriminate against you for exercising your rights.</Text>
                
                <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">To exercise your California data protection rights described above, please send your request(s) by email: support@swaptrex.com.</Text>
                
                <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">Your data protection rights, described above, are covered by the CCPA, short for the California Consumer Privacy Act. To find out more, visit the official California Legislative Information website. The CCPA took effect on 01/01/2020.</Text>
              
            </ListItem>


            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Service Providers</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>We may employ third party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Analytics</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>We may use third-party Service Providers to monitor and analyze the use of our Service.</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>CI/CD tools</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>We may use third-party Service Providers to automate the development process of our Service.</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Behavioral Remarketing</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>We may use remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Payments</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Links to Other Sites</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Children’s Privacy</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>Our Services are not intended for use by children under the age of 18 (“Child” or “Children”).</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Changes to This Privacy Policy</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}} mt="2">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</Text>
            </ListItem>

            <ListItem>
              <Text fontWeight="bold" fontSize={{base:'md', sm:'lg', md:'xl'}}>Contact Us</Text>
              <Text fontSize={{base:'md', sm:'lg', md:'xl'}}>If you have any questions about this Privacy Policy, please contact us by email: support@swaptrex.com.</Text>
            </ListItem>

         </OrderedList>  
        </Box>

       </VStack>
       
    </Box>
  );
}