import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// Chakra imports
import {
  Box,
  SimpleGrid,
  HStack,
  Icon,
  Square,
  Text,
  Spinner,
  useColorModeValue,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import {
  FiArrowDown,
  FiChevronRight,
  FiCreditCard,
  FiPlus,
} from "react-icons/fi";
import Pattern from "assets/images/pattern1.png";

export default function Fiat(props) {
  const { wallet, settings, user, orders, verifyDepositLoading, onOpen, ...rest } = props;
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  return (
    <>
      <Card
        {...rest}
        align="center"
        p="20px"
        shadow="md"
        background="brand.500"
        backgroundImage={`url(${Pattern})`}
        backgroundPosition="center center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      >
        <HStack spacing="24px">
          <Box w="70%">
            <Text
              color={"white"}
              fontSize={{ base: "md", sm: "md", md: "lg", lg: "lg" }}
              fontFamily="ManropeRegular"
              my={{ base: "auto", "2xl": "10px" }}
              mx="auto"
              textAlign="start"
            >
              My Wallet Balance
            </Text>
            <Text
              color={"white"}
              fontSize={{base:'xl', sm:'xl', md:'2xl', lg:'2xl'}}
              fontFamily="ManropeSemiBold"
              textAlign="start"
              mt={{ base: "5px", "2xl": "5px" }}
            >
              {"USD "}
              {new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(parseFloat(wallet.balance))}
            </Text>
          </Box>
          <Box w="30%" h="40px">
            <Square
              size="50px"
              bg="#f4f4f4"
              color="white"
              borderRadius={5}
              onClick={() => {
                onOpen();
              }}
            >
              <Icon as={FiPlus} w="40px" h="40px" color="blue.500" />
            </Square>
            <Text
              color={"white"}
              fontSize={{ base: "xs", sm: "sm", md: "sm", lg: "sm" }}
              my={{ base: "auto", "2xl": "10px" }}
              mx="auto"
              textAlign="center"
            >
              Deposit
            </Text>
            {orders.length > 0 ? <Spinner xs="xs" color="white" mt="1" /> : ""}
          </Box>
        </HStack>

        <SimpleGrid columns={{ base: 2, sm: 3, md: 5 }} mt={2} pt={5}>
          <Link to="/user/transfer">
            <Box>
              <Square size="50px" bg="blue.200" color="white" borderRadius={5}>
                <Icon as={FiArrowDown} w="25px" h="25px" color="#ffffff" />
              </Square>
              <Text
                color={"white"}
                fontSize={{ base: "xs", sm: "sm", md: "sm", lg: "sm" }}
                my={{ base: "auto", "2xl": "10px" }}
                mx="auto"
                textAlign="center"
              >
                Transfer
              </Text>
            </Box>
          </Link>
          <Link to="/user/virtualcard">
            <Box>
              <Square size="50px" bg="blue.400" color="white" borderRadius={5}>
                <Icon as={FiCreditCard} w="25px" h="25px" color="#ffffff" />
              </Square>
              <Text
                color={"white"}
                fontSize={{ base: "xs", sm: "sm", md: "sm", lg: "sm" }}
                my={{ base: "auto", "2xl": "10px" }}
                mx="auto"
                textAlign="center"
              >
                Virtual Card
              </Text>
            </Box>
          </Link>
        </SimpleGrid>
      </Card>
    </>
  );
}
