import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  Flex,
  Box,
  Image,
  PinInput,
  PinInputField,
  Spinner,
  Center,
  Stack,
  Button,
  Heading,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/images/illustration1.png";
import Logo from "assets/images/logogold.png";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function Activate() {
  //const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");
  const [otp, setOtp] = useState("");
  const [firstCode, setFirstCode] = useState(true);
  const [remainingTime, setRemainingTime] = useState(90); // 60 seconds = 1 minute
  const [showResendLink, setShowResendLink] = useState(false);
  const inputColor = useColorModeValue("brand.500", "gray.100");

  const activateAccount = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/activate",
        { email: location.state.email, code: otp },
        {
          headers: {
            "Authorization":''
          },
          mode: 'cors'
        }
      );
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "An error occured",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        navigate("/auth/index");
        return;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const getOtp = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(SERVER_URL + "/user/getOtp",{email: location.state.email},{
        headers: {
          "Authorization":''
        },
        mode: 'cors'
      });
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setFirstCode(true);
        setRemainingTime(90);
        setShowResendLink(false);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
      return;
    }
  };


  useEffect(() => {
    setOtp(pin1 + "" + pin2 + "" + pin3 + "" + pin4 + "" + pin5 + "" + pin6);
  }, [pin1, pin2, pin3, pin4, pin5, pin6, otp]);

  useEffect(() => {
    let timerId = null;
    if (remainingTime > 0) {
      timerId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setShowResendLink(true);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [remainingTime]);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        mb="10"
        h="100%"
        alignItems="start"
        justifyContent="center"
        flexDirection="column"
      >
        <Flex minH={"100vh"} width={"100%"} mt="10" justify={"center"}>
          <Stack
            spacing={8}
            mx={"auto"}
            w={{ base: "95%", sm: "95%", md: "70%", lg: "65%" }}
          >
            <Stack align={"center"}>
              <Image
                src={Logo}
                alt="Swaptrex Virtual Card"
                height="auto"
                width="70px"
                mb="5"
              />
              <Heading
                fontFamily="ManropeSemiBold"
                fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}
                textAlign={"center"}
              >
                Account Activation
              </Heading>
              <Text
                fontFamily="ManropeRegular"
                fontSize={"lg"}
                textAlign="center"
              >
                Enter the OTP code sent to your phone
              </Text>
            </Stack>
            <Box rounded={"lg"} p={2} width="100%">
              <Stack spacing={4} width="100%">
                <Box pt="3" pb="5">
                  <Center width="100%">
                    <PinInput otp>
                      <PinInputField
                        w="55px"
                        h="55px"
                        color={inputColor}
                        onChange={(e) => {
                          setPin1(e.target.value);
                        }}
                      />
                      <PinInputField
                        w="55px"
                        h="55px"
                        color={inputColor}
                        onChange={(e) => {
                          setPin2(e.target.value);
                        }}
                      />
                      <PinInputField
                        w="55px"
                        h="55px"
                        color={inputColor}
                        onChange={(e) => {
                          setPin3(e.target.value);
                        }}
                      />
                      <PinInputField
                        w="55px"
                        h="55px"
                        color={inputColor}
                        onChange={(e) => {
                          setPin4(e.target.value);
                        }}
                      />
                      <PinInputField
                        w="55px"
                        h="55px"
                        color={inputColor}
                        onChange={(e) => {
                          setPin5(e.target.value);
                        }}
                      />
                      <PinInputField
                        w="55px"
                        h="55px"
                        color={inputColor}
                        onChange={(e) => {
                          setPin6(e.target.value);
                        }}
                      />
                    </PinInput>
                    {showResendLink ? (
                      firstCode ? (
                        <Button size="xs" onClick={getOtp}>
                          Send
                        </Button>
                      ) : (
                        <Button size="xs" onClick={getOtp}>
                          Send
                        </Button>
                      )
                    ) : (
                      <Text>&nbsp;{remainingTime}s </Text>
                    )}
                  </Center>
                </Box>

                <Stack spacing={10} pt={2}>
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    isLoading={loading}
                    loadingText="Please Wait"
                    mb="24px"
                    onClick={(e) => {
                      e.preventDefault();
                      activateAccount();
                    }}
                  >
                    Proceed
                  </Button>
                </Stack>
                <Stack pt={6}>
                  <Text align={"center"}>
                    Don't have an account yet?{" "}
                    <Link color={"blue.400"} to="/auth/signup">
                      Sign Up
                    </Link>
                  </Text>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Activate;
