import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdPerson, MdHome, MdSettings } from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Users from "views/admin/users";
import Kyc from "views/admin/users/kyc";
import Settings from "views/admin/settings";
import Transactions from "views/admin/transactions";
import Withdrawals from "views/admin/withdrawals";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="white" />,
    component: <MainDashboard />,
    collapse: false,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "/users/index",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="white" />,
    component: <Users />,
    collapse: false,
  },/*
  {
    name: "Pending KYC",
    layout: "/admin",
    path: "/users/kyc",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="white" />,
    component: <Kyc />,
    collapse: false,
  },*/
  {
    name: "Transactions",
    layout: "/admin",
    path: "/transactions/all",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="white" />,
    component: <Transactions />,
    collapse: false,
  },
  {
    name: "Withdrawals",
    layout: "/admin",
    path: "/withdrawals",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="white" />,
    component: <Withdrawals />,
    collapse: false,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "/settings",
    icon: <Icon as={MdSettings} width="20px" height="20px" color="white" />,
    component: <Settings />,
    collapse: false,
  },
];

export default routes;
