import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import Basic from "./components/Basic.js";
import Navigation from "./components/Navigation.js";

export default function Profile() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [userLoading, setUserLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();

  const getUser = async () => {
    try {
      const { data: response } = await axios.get(SERVER_URL + "/user/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (response.success) {
        setUser(response.data);
        setLoading(false);
        setUserLoading(false);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const resendEmailVerification = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/resendVerification",
        { email: user.email },
        {
          headers: {
            //"Authorization":`Bearer ${token}`
          },
        }
      );
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUser();
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Basic
        user={user}
        getUser={getUser}
        loading={loading}
        resendEmailVerification={resendEmailVerification}
      />
      <Navigation />
    </Box>
  );
}
