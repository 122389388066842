import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  List,
  ListIcon,
  ListItem,
  OrderedList,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import { Helmet } from "react-helmet";
import Header from "components/navigation/Header.js";

export default function Terms() {
  return (
    <Box minH="100vh">
      <Helmet>
        <title>Terms | Terms and Conditions</title>
        <meta
          name="description"
          content="Read our terms and conditions carefully before using our virtual debit card issuance service. Our terms and conditions outline the rules, regulations, and responsibilities that govern the use of our service. By using our service, you agree to abide by our terms and conditions."
        />
        <meta
          name="keywords"
          content="Virtual Card, virtual payment,virtual card,virtual credit card,virtual debit card,virtual visa card,virtual pay,virtual payment cards,virtual visa,generate a virtual credit card,instant virtual credit card,virtual pay card,virtual card for online payment,instant virtual card,mastercard virtual card,virtual mastercard,virtual bank card,virtual visa card instant,online virtual credit card,instant virtual debit card,generate virtual credit card,create virtual credit card"
        />
      </Helmet>
      <Header />

      <VStack spacing="2">
        <Box>
          {" "}
          <Heading as="h3" mt="10" color="black">
            {" "}
            Terms of Service
          </Heading>{" "}
        </Box>
        <Box
          p={{ base: "4", sm: "10", md: "20" }}
          fontSize={{ base: "md", sm: "lg", md: "xl" }}  color="black">
          <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            Effective Date: 17th of May, 2023
          </Text>

          <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
            Welcome to <b>Swaptrex.com</b>, operated by <b>Tradersfield Ltd</b>.
            These Terms of Service ("Terms") govern your use of our virtual
            debit card issuance services. Please read these Terms carefully
            before using our website or services. By accessing or using
            swaptrex.com, you agree to be bound by these Terms.
          </Text>

          <OrderedList spacing={4} pl={4} mt="4">
            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                Account Creation and Eligibility
              </Text>
              <OrderedList spacing={2} pl={6}>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Account Creation
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        To use our virtual debit card issuance services, you
                        must create an account on{" "}
                        <Text as="span" fontWeight="bold">
                          swaptrex.com
                        </Text>
                        .
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        You agree to provide accurate, current, and complete
                        information during the account registration process.
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        You are solely responsible for maintaining the
                        confidentiality of your account credentials and are
                        liable for all activities carried out through your
                        account.
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        You may not use as a username the name of another person
                        or entity or that is not lawfully available for use, a
                        name or trademark that is subject to any rights of
                        another person or entity other than you, without
                        appropriate authorization. You may not use as a username
                        any name that is offensive, vulgar or obscene
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Eligibility
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        By using{" "}
                        <Text as="span" fontWeight="bold">
                          swaptrex.com
                        </Text>
                        , you represent and warrant that you are at least 18
                        years old and have the legal capacity to enter into
                        binding agreements.
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        You must not use our services if it is prohibited by
                        applicable laws in your jurisdiction.
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                {" "}
                Virtual Debit Card Issuance
              </Text>
              <OrderedList spacing={2} pl={6}>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Card Application and Approval
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        To obtain a virtual debit card, you must submit a card
                        application through our website and provide the required
                        information.
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        Approval of card applications is subject to our review
                        and verification processes. We reserve the right to
                        reject or cancel applications at our discretion.
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Card Usage
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        Once approved, you will be issued a virtual debit card
                        that can be used for online transactions and selected
                        offline purchases.
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        You agree to use the virtual debit card in accordance
                        with these Terms, applicable laws, and any additional
                        guidelines provided by{" "}
                        <Text as="span" fontWeight="bold">
                          swaptrex.com
                        </Text>
                        .
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Card Limits and Restrictions
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        We may impose limits on the usage of the virtual debit
                        card, including transaction limits, withdrawal limits,
                        and other restrictions as deemed necessary.
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        You agree to comply with all card limits and
                        restrictions set by{" "}
                        <Text as="span" fontWeight="bold">
                          swaptrex.com
                        </Text>
                        .
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                Fees and Charges
              </Text>
              <OrderedList spacing={2} pl={6}>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Card Fees
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        The use of our virtual debit card may be subject to
                        certain fees and charges. You will be notified of any
                        applicable fees before initiating a transaction.
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        It is your responsibility to review and understand the
                        fees associated with the use of the virtual debit card.
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Payment and Billing
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        You authorize{" "}
                        <Text as="span" fontWeight="bold">
                          swaptrex.com
                        </Text>{" "}
                        to deduct applicable fees and charges from your account
                        balance associated with the virtual debit card.
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        You are responsible for ensuring that your account has
                        sufficient funds to cover fees and charges.
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                {" "}
                Privacy and Data Protection
              </Text>
              <OrderedList spacing={2} pl={6}>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Privacy Policy
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        Our Privacy Policy governs the collection, use, and
                        disclosure of personal information provided by you
                        during the use of{" "}
                        <Text as="span" fontWeight="bold">
                          swaptrex.com
                        </Text>
                        .
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        By using our services, you consent to the collection and
                        processing of your personal information as described in
                        our Privacy Policy.
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                Intellectual Property
              </Text>
              <OrderedList spacing={2} pl={6}>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Ownership
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        All intellectual property rights associated with{" "}
                        <Text as="span" fontWeight="bold">
                          swaptrex.com
                        </Text>
                        , including its content, design, trademarks, and logos,
                        are the property of{" "}
                        <Text as="span" fontWeight="bold">
                          Tradersfield Ltd
                        </Text>{" "}
                        or its licensors.
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        You are granted a limited, non-exclusive, and
                        non-transferable license to access and use{" "}
                        <Text as="span" fontWeight="bold">
                          swaptrex.com
                        </Text>{" "}
                        for personal, non-commercial purposes.
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                Limitation of Liability
              </Text>
              <OrderedList spacing={2} pl={6}>
                <ListItem>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Disclaimer of Warranties
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        <Text as="span" fontWeight="bold">
                          Swaptrex.com
                        </Text>{" "}
                        is provided on an "as-is" and "as available" basis. We
                        do not warrant the accuracy, reliability, or
                        availability of the website or its services.
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        We disclaim all warranties, whether express or implied,
                        including but not limited to merchantability, fitness
                        for a particular purpose, and non-infringement.
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Limitation of Liability
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        To the extent permitted by law,{" "}
                        <Text as="span" fontWeight="bold">
                          Tradersfield Ltd
                        </Text>{" "}
                        and its affiliates shall not be liable for any direct,
                        indirect, incidental, consequential, or exemplary
                        damages arising from the use or inability to use{" "}
                        <Text as="span" fontWeight="bold">
                          swaptrex.com
                        </Text>{" "}
                        or its services.
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        Our total liability to you for any claim arising out of
                        or related to these Terms shall not exceed the fees paid
                        by you to us, if any, in the preceding three months.
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                Termination
              </Text>
              <OrderedList spacing={2} pl={6}>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Termination by You
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        You may terminate your account on{" "}
                        <Text as="span" fontWeight="bold">
                          swaptrex.com
                        </Text>{" "}
                        at any time by following the account closure process
                        provided on the website.
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Termination by{" "}
                    <Text as="span" fontWeight="bold">
                      Tradersfield Ltd
                    </Text>
                    <OrderedList spacing={2} pl={4}>
                      <ListItem>
                        <Text
                          as="span"
                          fontWeight="bold"
                          fontSize={{ base: "md", sm: "lg", md: "xl" }}
                        >
                          Tradersfield Ltd
                        </Text>{" "}
                        reserves the right to suspend or terminate your account,
                        access to{" "}
                        <Text as="span" fontWeight="bold">
                          swaptrex.com
                        </Text>
                        , or its services at any time for violation of these
                        Terms or for any other reason deemed necessary.
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                Governing Law and Dispute Resolution
              </Text>
              <OrderedList spacing={2} pl={6}>
                <ListItem>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Governing Law
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        These Terms shall be governed by and construed in
                        accordance with the laws of Nigeria.
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                    Dispute Resolution
                    <OrderedList spacing={2} pl={4}>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        Any disputes arising out of or relating to these Terms
                        shall be resolved through good faith negotiations
                        between the parties.
                      </ListItem>
                      <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                        If a dispute cannot be resolved through negotiation, the
                        parties agree to submit to the exclusive jurisdiction of
                        the courts of Nigeria.
                      </ListItem>
                    </OrderedList>
                  </Text>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                Purchases
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                If you wish to purchase any product or service made available
                through Service (“Purchase”), you may be asked to supply certain
                information relevant to your Purchase including but not limited
                to, your credit or debit card number, the expiration date of
                your card, your billing address, and your address, primary
                information, bank verification number (BVN), identity card,
                passport photograph.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }} mt="2">
                You represent and warrant that: (i) you have the legal right to
                use any card(s) or other payment method(s) in connection with
                any Purchase; and that (ii) the information you supply to us is
                true, correct and complete.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }} mt="2">
                We may employ the use of third party services for the purpose of
                facilitating payment and the completion of Purchases. By
                submitting your information, you grant us the right to provide
                the information to these third parties subject to our Privacy
                Policy.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }} mt="2">
                We reserve the right to refuse or cancel your order at any time
                for reasons including but not limited to: product or service
                availability, errors in the description or price of the product
                or service, error in your order or other reasons.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                We reserve the right to refuse or cancel your order if fraud or
                an unauthorized or illegal transaction is suspected.
              </Text>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                Prohibited Uses
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                You may use Service only for lawful purposes and in accordance
                with Terms. You agree not to use Service:
              </Text>

              <List spacing={3}>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  In any way that violates any applicable national or
                  international law or regulation.
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  For the purpose of exploiting, harming, or attempting to
                  exploit or harm minors in any way by exposing them to
                  inappropriate content or otherwise.
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  To transmit, or procure the sending of, any advertising or
                  promotional material, including any “junk mail”, “chain
                  letter,” “spam,” or any other similar solicitation.
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  To impersonate or attempt to impersonate Company, a Company
                  employee, another user, or any other person or entity.
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  In any way that infringes upon the rights of others, or in any
                  way is illegal, threatening, fraudulent, or harmful, or in
                  connection with any unlawful, illegal, fraudulent, or harmful
                  purpose or activity.
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  To engage in any other conduct that restricts or inhibits
                  anyone’s use or enjoyment of Service, or which, as determined
                  by us, may harm or offend Company or users of Service or
                  expose them to liability.
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  To engage in any other conduct that restricts or inhibits
                  anyone’s use or enjoyment of Service, or which, as determined
                  by us, may harm or offend Company or users of Service or
                  expose them to liability.
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  Additionally, you agree not to:
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  Use Service in any manner that could disable, overburden,
                  damage, or impair Service or interfere with any other party’s
                  use of Service, including their ability to engage in real time
                  activities through Service.
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  Use any robot, spider, or other automatic device, process, or
                  means to access Service for any purpose, including monitoring
                  or copying any of the material on Service
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  Use any manual process to monitor or copy any of the material
                  on Service or for any other unauthorized purpose without our
                  prior written consent.
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  Use any device, software, or routine that interferes with the
                  proper working of Service.
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  Introduce any viruses, trojan horses, worms, logic bombs, or
                  other material which is malicious or technologically harmful.
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  Attempt to gain unauthorized access to, interfere with,
                  damage, or disrupt any parts of Service, the server on which
                  Service is stored, or any server, computer, or database
                  connected to Service
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  Attack Service via a denial-of-service attack or a distributed
                  denial-of-service attack
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  Take any action that may damage or falsify Company rating.
                </ListItem>
              </List>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                {" "}
                Error Reporting and Feedback
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                You may provide us either directly at support@swaptrex.com or
                via third party sites and tools with information and feedback
                concerning errors, suggestions for improvements, ideas,
                problems, complaints, and other matters related to our Service
                (“Feedback”). You acknowledge and agree that:{" "}
              </Text>
              <List spacing={3}>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  You shall not retain, acquire or assert any intellectual
                  property right or other right, title or interest in or to the
                  Feedback;
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  Company may have development ideas similar to the Feedback;
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  Feedback does not contain confidential information or
                  proprietary information from you or any third party; and
                </ListItem>
                <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                  <ListIcon as={MdCheckCircle} color="green.500" />
                  Company is not under any obligation of confidentiality with
                  respect to the Feedback. In the event the transfer of the
                  ownership to the Feedback is not possible due to applicable
                  mandatory laws, you grant Company and its affiliates an
                  exclusive, transferable, irrevocable, free-of-charge,
                  sub-licensable, unlimited and perpetual right to use
                  (including copy, modify, create derivative works, publish,
                  distribute and commercialize) Feedback in any manner and for
                  any purpose.
                </ListItem>
              </List>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                {" "}
                Links To Other Web Sites
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Our Service may contain links to third party web sites or
                services that are not owned or controlled by Tradersfield Ltd.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }} mt="2">
                Swaptrex has no control over, and assumes no responsibility for
                the content, privacy policies, or practices of any third party
                web sites or services. We do not warrant the offerings of any of
                these entities/individuals or their websites.
              </Text>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                Governing Law and Dispute Resolution
              </Text>
              <OrderedList spacing={2} pl={6}>
                <ListItem>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: "md", sm: "lg", md: "xl" }}
                  >
                    {" "}
                    Governing Law
                  </Text>
                  <OrderedList spacing={2} pl={8}>
                    <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                      These Terms shall be governed by and construed in
                      accordance with the laws of Nigeria.
                    </ListItem>
                  </OrderedList>
                </ListItem>
                <ListItem>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: "md", sm: "lg", md: "xl" }}
                  >
                    {" "}
                    Dispute Resolution
                  </Text>
                  <OrderedList spacing={2} pl={8}>
                    <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                      Any disputes arising out of or relating to these Terms
                      shall be resolved through good faith negotiations between
                      the parties.
                    </ListItem>
                    <ListItem fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                      If a dispute cannot be resolved through negotiation, the
                      parties agree to submit to the exclusive jurisdiction of
                      the courts of Nigeria.
                    </ListItem>
                  </OrderedList>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                Changes To Service
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                We reserve the right to withdraw or amend our Service, and any
                service or material we provide via Service, in our sole
                discretion without notice. We will not be liable if for any
                reason all or any part of Service is unavailable at any time or
                for any period. From time to time, we may restrict access to
                some parts of Service, or the entire Service, to users,
                including registered users.
              </Text>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                Amendments To Terms
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                We may amend Terms at any time by posting the amended terms on
                this site. It is your responsibility to review these Terms
                periodically.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }} mt="2">
                Your continued use of the Platform following the posting of
                revised Terms means that you accept and agree to the changes.
                You are expected to check this page frequently so you are aware
                of any changes, as they are binding on you.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }} mt="2">
                By continuing to access or use our Service after any revisions
                become effective, you agree to be bound by the revised terms. If
                you do not agree to the new terms, you are no longer authorized
                to use Service.
              </Text>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                Waiver And Severability
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                No waiver by Company of any term or condition set forth in Terms
                shall be deemed a further or continuing waiver of such term or
                condition or a waiver of any other term or condition, and any
                failure of Company to assert a right or provision under Terms
                shall not constitute a waiver of such right or provision.
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }} mt="2">
                If any provision of Terms is held by a court or other tribunal
                of competent jurisdiction to be invalid, illegal or
                unenforceable for any reason, such provision shall be eliminated
                or limited to the minimum extent such that the remaining
                provisions of Terms will continue in full force and effect.
              </Text>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                Acknowledgement
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
                ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE
                TO BE BOUND BY THEM
              </Text>
            </ListItem>

            <ListItem>
              <Text
                fontWeight="bold"
                fontSize={{ base: "md", sm: "lg", md: "xl" }}
              >
                {" "}
                Contact Us
              </Text>
              <Text fontSize={{ base: "md", sm: "lg", md: "xl" }}>
                Please send your feedback, comments, requests for technical
                support by email:{" "}
                <Text as="span" fontWeight="bold">
                  support@swaptrex.com
                </Text>
                .
              </Text>
            </ListItem>
          </OrderedList>
        </Box>
      </VStack>
    </Box>
  );
}
