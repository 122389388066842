// Chakra imports
import {
  Box,
  Flex,
  VStack,
  HStack,
  FormControl,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
  Circle,
  Center,
  List,
  ListItem,
  ListIcon,
  Icon,
  Avatar,
  useDisclosure,
  useColorModeValue,
  useToast,
  Text,
  Select,
  Link,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Pattern from "assets/images/pattern1.png";

// Custom components
import Card from "components/card/Card.js";
// Assets
import {
  FiUserX,
  FiUserCheck,
  FiArrowLeft,
  FiChevronRight,
  FiCheck,
} from "react-icons/fi";

export default function KycInfo(props) {
  const navigate = useNavigate();
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const {
    user,
    wallet,
    card,
    getVirtualCard,
    getUser,
    settings,
    onFundingOpen,
    page,
    ...rest
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isBvnVerifyOpen,
    onOpen: onBvnVerifyOpen,
    onClose: onBvnVerifyClose,
  } = useDisclosure();

  const {
    isOpen: isRequirementOpen,
    onOpen: onRequirementOpen,
    onClose: onRequirementClose,
  } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [bvn, setBvn] = useState("");

  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const textColorSecondary = useColorModeValue("gray.800", "white");
  const backgroundColor = useColorModeValue("white", "brand.700");
  const bg = useColorModeValue("brand.500", "gray.100");
  const color = useColorModeValue("white", "brand.500");

  const goToDashboard = async () => {
    navigate("/user/dashboard");
  }

  const submitKYC = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/kyc",
        { bvn },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        setLoading(false);
        toast({
          title: response.message || "KYC request submitted sucessfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUser();
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const createVirtualCard = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.get(
        SERVER_URL + "/wallet/createVirtualCard",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "Card has been issued successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUser();
        getVirtualCard();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };


  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent color={textColorSecondary} background={backgroundColor}>
          <ModalHeader color={textColorSecondary}>
            <Icon
              as={FiArrowLeft}
              onClick={onClose}
              width="20px"
              height="20px"
              color={textColorPrimary}
            />
          </ModalHeader>
          <ModalCloseButton color={textColorSecondary} />
          <ModalBody>
            <SimpleGrid>
              <Box mt="4">
                <Heading
                  as="h4"
                  color={textColorSecondary}
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                  fontFamily="ManropeSemiBold"
                >
                  Complete KYC the Requirement
                </Heading>
              </Box>
              <Box>
                <Text
                  color={textColorSecondary}
                  fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeRegular"
                >
                  Please complete the KYC information requested in the sectons
                  below
                </Text>
              </Box>
              <Box>
                <VStack spacing={4} align="stretch">
                  <Box
                    w="100%"
                    minH="40px"
                    mt="4"
                    background="blue.300"
                    p="4"
                    borderRadius={"10"}
                    onClick={onBvnVerifyOpen}
                  >
                    <HStack>
                      <Box width="70%" textAlign="start" color="white">
                        <Text
                          fontSize={{
                            base: "xs",
                            sm: "xs",
                            md: "sm",
                            lg: "md",
                          }}
                          fontFamily="ManropeRegular"
                        >
                          BVN
                        </Text>
                        <Text fontSize="xs">Bank Verification Number</Text>
                      </Box>
                      <Box width="15%">
                        <Circle
                          width="35px"
                          height="35px"
                          background="brand.500"
                        >
                          {bvn !== "" ? (
                            <Icon
                              as={FiUserCheck}
                              width="18px"
                              height="18px"
                              color="white"
                            />
                          ) : (
                            <Icon
                              as={FiUserX}
                              width="18px"
                              height="18px"
                              color="white"
                            />
                          )}
                        </Circle>
                      </Box>
                      <Box width="15%">
                        <Icon
                          as={FiChevronRight}
                          width="20px"
                          height="20px"
                          color="white"
                        />
                      </Box>
                    </HStack>
                  </Box>

                  <Box mt="3">
                    <Button
                      variant="primary"
                      isLoading={loading}
                      loadingText="Wait"
                      bg={bg}
                      color={color}
                      w="full"
                      borderRadius="20"
                      onClick={(e) => {
                        e.preventDefault();
                        submitKYC();
                      }}
                    >
                      Submit KYC Request
                    </Button>
                  </Box>
                </VStack>
              </Box>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isRequirementOpen}
        onClose={onRequirementClose}
        size={"md"}
      >
        <ModalOverlay />
        <ModalContent color={textColorSecondary} background={backgroundColor}>
          <ModalHeader>
            <Heading
              color={textColorSecondary}
              fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
              fontFamily="ManropeSemiBold"
            >
              KYC Requirement
            </Heading>
          </ModalHeader>
          <ModalCloseButton color={textColorSecondary} />
          <ModalBody>
            <Box width="100%">
              <Text
                fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                fontFamily="ManropeRegular"
              >
                You will be asked to provide certain personal information
                including;
              </Text>
              <List mt="3" spacing={2} width="100%">
                {/*<ListItem
                  fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeRegular"
                >
                  <ListIcon as={FiCheck} color="orange.500" />
                  Your home address
                </ListItem>*/}
                <ListItem
                  fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeRegular"
                >
                  <ListIcon as={FiCheck} color="orange.500" />
                  Bank verification number (BVN)
                </ListItem>
                {/* <ListItem
                  fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeRegular"
                >
                  <ListIcon as={FiCheck} color="orange.500" />
                  Valid means of identity (We accept either of International
                  Passport, Driver's License, Voters Card, National Identity
                  Card)
                  </ListItem>*/}
              </List>
              <Text
                mt="4"
                textAlign="center"
                color={textColorPrimary}
                textDecoration={"underline"}
                fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
              >
                <Link href="https://swaptrex.tawk.help/article/understanding-kyc-know-your-customer-policy-on-swaptrex">
                  What is KYC and why is it required?
                </Link>
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isBvnVerifyOpen} onClose={onBvnVerifyClose} size={"md"}>
        <ModalOverlay />
        <ModalContent background={backgroundColor}>
          <ModalHeader color={textColorSecondary}>
            <Icon
              as={FiArrowLeft}
              onClick={onBvnVerifyClose}
              width="20px"
              height="20px"
              color={textColorSecondary}
            />
          </ModalHeader>
          <ModalCloseButton color={textColorSecondary} />
          <ModalBody>
            <SimpleGrid>
              <Box mt="4">
                <Heading
                  as="h4"
                  color={textColorSecondary}
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                  fontFamily="ManropeSemiBold"
                >
                  BVN Verification
                </Heading>
              </Box>
              <Box mt="3">
                <Text
                  color={textColorSecondary}
                  fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeRegular"
                >
                  Enter your 11 digits BVN number
                </Text>
              </Box>

              <Box mt="1">
                <FormControl id="bvn" isRequired>
                  <InputGroup>
                    <Input
                      type="text"
                      maxLength={11}
                      defaultValue={bvn}
                      color={textColorSecondary}
                      onChange={(e) => {
                        setBvn(e.target.value);
                      }}
                      borderRadius="20"
                    />
                  </InputGroup>
                </FormControl>
              </Box>

              <Box mt="3">
                <Button
                  variant="primary"
                  bg={bg}
                  color={color}
                  w="full"
                  borderRadius="20"
                  onClick={(e) => {
                    e.preventDefault();
                    onBvnVerifyClose();
                  }}
                >
                  Continue
                </Button>
              </Box>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      {
       ((!user.identityVerified && user.kycStatus === "invalid") ||
        (user.identityVerified && card === null)) && (
          <KycNotStarted
            onOpen={onOpen}
            onRequirementOpen={onRequirementOpen}
            onFundingOpen={onFundingOpen}
            loading={loading}
            createVirtualCard={createVirtualCard}
            goToDashboard={goToDashboard}
            card={card}
            page={page}
            user={user}
            wallet={wallet}
            settings={settings}
            useToast={useToast}
          />
        )}
      {/*!user.identityVerified && user.bvnVerified && <IdentityNotStarted onOpen={onOpen} user={user} />*/}
      {/*!user.bvnVerified && user.identityVerified && <BvnNotStarted onOpen={onOpen} user={user} />*/}
      {!user.identityVerified && user.kycStatus === "pending" && (
        <KycPending user={user} />
      )}
    </>
  );
}

const KycNotStarted = (props) => {
  const {
    user,
    wallet,
    loading,
    createVirtualCard,
    goToDashboard,
    card,
    settings,
    useToast,
    onOpen,
    page,
    onFundingOpen,
    onRequirementOpen,
  } = props;
  console.log(card);
  const toast = useToast();
  return (
    <>
      <Card
        shadow="md"
        background="blue.500"
        backgroundImage={`url(${Pattern})`}
        backgroundPosition="center center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      >
        <VStack>
          <Box textAlign="start">
            <Text
              color="white"
              fontSize={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
              my={{ base: "auto", "2xl": "10px" }}
              textAlign="start"
            >
              Hi
            </Text>
            <Text
              color="white"
              fontWeight="bold"
              fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
              fontFamily="ManropeSemiBold"
              my={{ base: "auto", "2xl": "10px" }}
              textAlign="start"
            >
              {user.firstName} {user.lastName}
            </Text>
            <Text
              as="h3"
              color="white"
              textAlign="start"
              fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
              fontFamily="ManropeRegular"
            >
              You are few steps away from getting your{" "}
              <Text as="span" color="orange.500">
                Virtual USD Card
              </Text>
            </Text>

            <Text
              as="h3"
              color="white"
              textAlign="start"
              fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
              fontFamily="ManropeRegular"
              mt="2"
            >
              <b>Step 1</b>
              &nbsp; &nbsp; Complete the{" "}
              <Text as="span" onClick={onRequirementOpen}>
                <u>KYC requirement</u>
              </Text>
              {user.identityVerified && user.kycStatus === "completed" && (
                <Icon mt="2" as={FiCheck} color="white" />
              )}
            </Text>

            <Text
              as="h3"
              color="white"
              textAlign="start"
              fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
              fontFamily="ManropeRegular"
              mt="2"
            >
              <b>Step 2</b>
              &nbsp; &nbsp; Fund your wallet with a minimum of 2 USD &nbsp;
              &nbsp;{" "}
              {wallet.balance >= 1.9 && (
                <Icon mt="2" as={FiCheck} color="white" />
              )}
            </Text>
          </Box>

          <Box width="100%">
            <Flex>
               
              <Button
                mt="5"
                colorScheme="white"
                background="#fff"
                color="#000"
                variant="solid"
                isLoading={loading}
                loadingText="Wait..."
                fontSize={{ base: "xs", sm: "sm", md: "sm", lg: "md" }}
                onClick={() => {
                  if (
                    !user.identityVerified &&
                    user.kycStatus !== "completed"
                  ) {
                    onOpen();
                  } else {
                    if (wallet.balance < 1 && page=="dashboard") {
                      onFundingOpen();
                    } 
                    else if (wallet.balance < 1 && page=="virtualcard") {
                      goToDashboard();
                    } 
                    else if(wallet.balance >= 1) {
                      createVirtualCard();
                    }
                  }
                }}
              >
                {user.identityVerified && wallet.balance >= 1
                  ? "Request Card"
                  : "Proceed"}
              </Button>
              {/* <Text
              color="#fff"
              mt="7"
              ml="2"
              fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
              fontFamily="ManropeRegular"
            >
              <b>{settings.cardCreationFee} USDT</b> card creation fee required
            </Text>
            */}
            </Flex>
          </Box>
        </VStack>
      </Card>
    </>
  );
};

const KycPending = (props) => {
  const { user } = props;
  return (
    <Card
      shadow="md"
      background="blue.500"
      backgroundImage={`url(${Pattern})`}
      backgroundPosition="center center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      minHeight="200"
    >
      <VStack>
        <Box textAlign="start">
          <Text
            color="white"
            fontSize={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}
            my={{ base: "auto", "2xl": "10px" }}
            textAlign="start"
          >
            Hi
          </Text>
          <Text
            color="white"
            fontWeight="bold"
            fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
            my={{ base: "auto", "2xl": "10px" }}
            textAlign="start"
          >
            {user.firstName} {user.lastName}
          </Text>
          <Text
            as="h3"
            color="white"
            textAlign="start"
            fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
            mt={{ base: "5px", "2xl": "5px" }}
          >
            We are currently reviewing your
            <Text as="span" color="#61E8E1">
              {" "}
              KYC Information.
            </Text>
            &nbsp; This process will be completed within 24 hrs
          </Text>
        </Box>
      </VStack>
    </Card>
  );
};
