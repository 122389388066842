import React, { useEffect } from "react";
import "assets/css/App.css";
import { Routes, Route, HashRouter, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import WebLayout from "layouts/web";
import AdminLayout from "layouts/admin";
import UserLayout from "layouts/user";
import WebAuthLayout from "layouts/webauth";
import routes from "routes.js";
import Home from "views/web/home";
import About from "views/web/about";
import Login from "views/web/auth";
import Register from "views/web/auth/register";
import Terms from "views/web/terms";
import Tariff from "views/web/tariff";
import Aml from "views/web/aml";
import Privacy from "views/web/privacy";
import Forgot from "views/web/auth/forgot";
import TwoFactor from "views/web/auth/twofactor";
import SmsTwoFactor from "views/web/auth/smstwofactor";
import Otp from "views/web/auth/otp";
import Activate from "views/web/auth/activate";
import Verify from "views/web/auth/verify";
import UserDashboard from "views/user/dashboard";
import VirtualCard from "views/user/virtualcard";
import Profile from "views/user/profile";
import Banks from "views/user/profile/banks";
import ChangeName from "views/user/profile/changename";
import ChangePassword from "views/user/profile/changepassword";
import AddAccount from "views/user/profile/addaccount";
import ChangePhone from "views/user/profile/changephone";
import Security from "views/user/profile/security";
import Transactions from "views/user/transactions";
import Notifications from "views/user/notifications";
import Transfer from "views/user/transfer";
import ConfirmTransfer from "views/user/transfer/confirmtransfer";
import UserProfile from "views/admin/users/profile";
import Fiat from "views/user/transfer/fiat";

function App() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && !prop.collapse) {
        return (
          <Route
            path={prop.layout + prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      if (prop.layout === "/user" && !prop.collapse) {
        return (
          <Route
            path={prop.layout + prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      if (prop.layout === "/admin" && prop.collapse) {
        return (
          <Route
            path={prop.layout + prop.path}
            element={prop.component}
            key={key}
          >
            {getRoutes(prop.items)}
          </Route>
        );
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  const WebRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/web/index" replace />} />
        <Route path="/web/*" element={<WebLayout />}>
          <Route path="index" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="aml" element={<Aml />} />
          <Route path="tariff" element={<Tariff />} />
          <Route path="*" element={<Navigate to="/web/index" replace />} />
        </Route>
      </Routes>
    );
  };

  const AuthRoutes = () => {
    return (
      <Routes>
        <Route path="/auth/*" element={<WebAuthLayout />}>
          <Route path="index" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Register />} />
          <Route path="forgot" element={<Forgot />} />
          <Route path="twofactor/:id" element={<TwoFactor />} />
          <Route path="smstwofactor/:id" element={<SmsTwoFactor />} />
          <Route path="activate" element={<Activate />} />
          <Route path="verify/:code" element={<Verify />} />
          <Route path="otp" element={<Otp />} />
          <Route path="*" element={<Navigate to="/auth/index" replace />} />
        </Route>
      </Routes>
    );
  };

  const UserRoutes = () => {
    return (
      <Routes>
        <Route path="/user/*" element={<UserLayout />}>
          <Route path="dashboard" element={<UserDashboard />} />
          <Route path="virtualcard" element={<VirtualCard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="accounts" element={<Banks />} />
          <Route path="changename" element={<ChangeName />} />
          <Route path="changephone" element={<ChangePhone />} />
          <Route path="password" element={<ChangePassword />} />
          <Route path="phone" element={<ChangePhone />} />
          <Route path="security" element={<Security />} />
          <Route path="addaccount" element={<AddAccount />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="transfer" element={<Transfer />} />
          <Route path="transferfiat" element={<Fiat />} />
          <Route path="confirmtransfer" element={<ConfirmTransfer />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="*" element={<Navigate to="/user/dashboard" replace />} />
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          {getRoutes(routes)}
          <Route path="users/profile/:id" element={<UserProfile />} />
        </Route>
      </Routes>
    );
  };

  const user = useSelector((state) => state?.user?.value);
  const isLoggedIn = user.hasOwnProperty("token") ? true : false;
  useEffect(() => {}, []);

  return (
    <HashRouter>
      <WebRoutes />
      <AuthRoutes />
      <UserRoutes />
    </HashRouter>
  );
}

export default App;
