import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Box,
  Heading,
  Text,
  Flex,
  Button,
  SimpleGrid,
  Skeleton,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  List,
  ListItem,
  Icon,
  Spinner,
  Image,
  FormControl,
  FormLabel,
  FormHelperText,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  Input,
  VStack,
  SkeletonText,
  useToast,
  useColorModeValue,
  useDisclosure,
  useClipboard,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";

import { FiCopy } from "react-icons/fi";

import Fiat from "views/user/dashboard/components/Fiat";
import KycInfo from "views/user/profile/components/KycInfo";
import Info from "views/user/dashboard/components/Info";
import Technical from "views/user/dashboard/components/Technical";
import Transactions from "views/user/transactions/components/Transactions";
import Card from "components/card/Card.js";

import Exchange from "assets/images/exchange.png";

export default function UserDashboard() {
  const search = useLocation().search;
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const tx_ref = new URLSearchParams(search).get("tx_ref");

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [userLoading, setUserLoading] = useState(true);
  const [verifyDepositLoading, setVerifyDepositLoading] = useState(true);
  const [walletLoading, setWalletLoading] = useState(true);
  const [cardLoading, setCardLoading] = useState(true);
  const [transactionLoading, setTransactionLoading] = useState(true);
  const [wallet, setWallet] = useState(null);
  const [card, setCard] = useState(null);
  const [cardIssued, setCardIssued] = useState("false");
  const [transactions, setTransactions] = useState([]);
  const [orders, setOrders] = useState([]);
  const [settings, setSettings] = useState(null);
  const [rate, setRate] = useState(null);
  const [user, setUser] = useState(null);

  const [depositLoading, setDepositLoading] = useState(false);
  const [currency, setCurrency] = useState("USDT");
  const [cryptoDepositError, setCryptoDepositError] = useState(false);
  const [amount, setAmount] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState("createdAt");

  const toast = useToast();

  const textColorSecondary = useColorModeValue("gray.800", "white");
  const backgroundColor = useColorModeValue("white", "brand.700");
  const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
  const inputColor = useColorModeValue("brand.500", "gray.100");
  const bg = useColorModeValue("brand.500", "gray.100");
  const color = useColorModeValue("white", "brand.500");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDepositNairaOpen,
    onOpen: onDepositNairaOpen,
    onClose: onDepositNairaClose,
  } = useDisclosure();

  const deposit = async () => {
    try {
      if (amount < 1) {
        toast({
          title: "Minimum deposit allowed is 1 USD",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (amount > 5000) {
        toast({
          title: "Maximum deposit allowed is 5,000 USDT",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setDepositLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/wallet/deposit",
        { amount: amount, currency: currency },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDepositLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        // redirect to payment link
        window.location.href = response.data.link;
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }
  };

  const getUser = async () => {
    try {
      const { data: response } = await axios.get(SERVER_URL + "/user/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setUser(response.data);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setUserLoading(false);
    }
  };

  const getWallet = async () => {
    try {
      const { data: response } = await axios.get(SERVER_URL + "/wallet/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWalletLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setWallet(response.data);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setWalletLoading(false);
    }
  };

  const getTransactions = async () => {
    try {
      const { data: response } = await axios.get(
        SERVER_URL +
          "/transaction?from=" +
          from +
          "&limit=" +
          limit +
          "&orderBy=" +
          orderBy +
          "&sortOrder=" +
          sortOrder,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTransactionLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setTransactions(response.data.transactions);
      }
      setTransactionLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setTransactionLoading(false);
    }
  };

  const getPendingOrders = async () => {
    try {
      const { data: response } = await axios.get(
        SERVER_URL + "/wallet/pendingorders",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.success) {
        setOrders(response.data);
      }
    } catch (error) {}
  };

  const getVirtualCard = async () => {
    try {
      setCardLoading(true);
      const { data: response } = await axios.get(
        SERVER_URL + "/wallet/virtualcard",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCardLoading(false);
      if (response.error) {
        setCardIssued(response?.issued);
        return;
      }
      if (!response.success) {
        setCardIssued(response?.issued);
        return;
      }
      if (response.success) {
        setCard(response?.data);
        setCardIssued(response?.issued);
      }
    } catch (error) {
      setCardLoading(false);
    }
  };

  const getSettings = async () => {
    try {
      const { data: response } = await axios.get(SERVER_URL + "/settings", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.success) {
        setSettings(response.data);
      }
    } catch (error) {}
  };

  const getRate = async () => {
    try {
      const { data: response } = await axios.get(SERVER_URL + "/rate", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.success) {
        setRate(response.data);
      }
    } catch (error) {}
  };


  useEffect(() => {
    /*if (tx_ref !== undefined && tx_ref !== null) {
      verifyDeposit();
    }*/
    getUser();
    getWallet();
    getVirtualCard();
    getTransactions();
    getPendingOrders();
    getSettings();
    getRate();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent color={textColorSecondary} background={backgroundColor}>
          <ModalHeader color={textColorSecondary}>
            {" "}
            <Heading
              fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
              fontFamily="ManropeSemiBold"
            >
              {" "}
              Deposit{" "}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box width="100%" mb={{ base: "0px", "2xl": "20px" }}>
              {/*<Text
                color={textColorSecondary}
                fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                fontFamily="ManropeRegular"
                mb="20px"
              >
                Enter USDT amount and click on Deposit USDT, you will be redirected
                to payment page
              </Text> */}

              <Stack spacing={4} w={"full"} maxW={"md"} p={3}>
                <FormControl isInvalid={cryptoDepositError}>
                  <FormLabel color={textColorSecondary} fontWeight="bold">
                    Amount
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color={inputColor}
                      fontSize="1.2em"
                      children="$"
                    />
                    <Input
                      placeholder="Enter USD Amount"
                      color={inputColor}
                      isInvalid={cryptoDepositError}
                      errorBorderColor="crimson"
                      onChange={(e) => {
                        setAmount(e.target.value);
                        let amountIncoming = parseFloat(e.target.value);
                        //calculate what user will get minus the transaction fee
                        /*let amountIncoming;
                        if (
                          parseFloat(e.target.value) >=
                          settings.depositFeeCapAmount
                        ) {
                          amountIncoming =
                            parseFloat(e.target.value) -
                            parseFloat(e.target.value) *
                              settings.depositFeeCapRate;
                        } else {
                          amountIncoming =
                            parseFloat(e.target.value) -
                            parseFloat(e.target.value) *
                              settings.depositFeeRate;
                        }
                        amountIncoming = !isNaN(amountIncoming)
                          ? amountIncoming
                          : 0;
                        */
                        setTransactionInfo(
                          "You will receive USD " +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 3,
                            }).format(amountIncoming)
                        );
                      }}
                    />
                  </InputGroup>
                  <FormHelperText>{transactionInfo}</FormHelperText>
                  {cryptoDepositError && <FormErrorMessage></FormErrorMessage>}
                </FormControl>

                <Button
                  isLoading={depositLoading}
                  bg={bg}
                  color={color}
                  w="full"
                  borderRadius="20"
                  _hover={{
                    bg: "blue.500",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    deposit();
                  }}
                >
                  Deposit USDT
                </Button>
                <Text
                  textAlign="center"
                  fontSize={{ base: "xs", sm: "xs", md: "xs", lg: "xs" }}
                  fontFamily="ManropeRegular"
                >
                  Network Supported: TRC-20, BEP-20, Polygon
                </Text>

                <Text
                  textAlign="center"
                  fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                  fontFamily="ManropeRegular"
                  textDecoration={"underline"}
                  color="brand.500"
                  onClick={() => {
                    onClose();
                    onDepositNairaOpen();
                  }}
                >
                  Deposit Naira
                </Text>
              </Stack>
              <Box width="100%">
                <List spacing={3}>
                  {orders.length > 0 &&
                    orders.map((order) => {
                      return (
                        <ListItem>
                          Deposit of{" "}
                          <b>
                            {order.amount} {order.currency}
                          </b>{" "}
                          pending &nbsp;
                          <a href={order.paymentLink} target="_blank">
                            <u>View Info</u>
                          </a>
                          &nbsp; &nbsp;
                          <Spinner size="xs" />
                        </ListItem>
                      );
                    })}
                </List>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDepositNairaOpen} onClose={onDepositNairaClose}>
        <ModalOverlay />
        <ModalContent color={textColorSecondary} background={backgroundColor}>
          <ModalHeader color={textColorSecondary}>
            {" "}
            <Heading
              fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
              fontFamily="ManropeSemiBold"
            >
              Deposit Naira
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box width="100%" mb={{ base: "0px", "2xl": "20px" }}>
              <Text
                color={textColorSecondary}
                fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                fontFamily="ManropeRegular"
                mb="20px"
              >
               Make a transfer into your virtual bank account below
              </Text>

              <VStack
                paddingLeft="2"
                width="100%"
                borderLeftWidth={2}
                borderLeftColor={"#0066ff"}
                borderRadius="5"
                fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md" }}
                mb="10"
              >
                <Box
                  width="100%"
                  fontFamily="ManropeRegular"
                  textAlign={"start"}
                  fontWeight="bold"
                >
                  Bank Details
                </Box>
                <Box
                  width="100%"
                  fontFamily="ManropeRegular"
                  textAlign={"start"}
                >
                  <Text as="span" textColor={textColorPrimary}>
                    Bank Name
                  </Text>{" "}
                  &nbsp; &nbsp;{" "}
                  <Text as="span" fontWeight="bold">
                    {wallet!==null && wallet.bankName!==null? wallet.bankName: "Not Assigned"}
                  </Text>
                </Box>
                <Box
                  width="100%"
                  fontFamily="ManropeRegular"
                  textAlign={"start"}
                >
                  <Text as="span" textColor={textColorPrimary}>
                    Account Number
                  </Text>{" "}
                  &nbsp; &nbsp;{" "}
                  <Text as="span" fontWeight="bold">
                  {wallet!==null && wallet.bankAccountNumber!==null? wallet.bankAccountNumber: "Not Assigned"}
                  </Text>{" "}
                  &nbsp; &nbsp; <CopyButton textToCopy={wallet!==null && wallet.bankAccountNumber!==null? wallet.bankAccountNumber: "Not Assigned"} />{" "}
                </Box>
                <Box
                  width="100%"
                  fontFamily="ManropeRegular"
                  textAlign={"start"}
                >
                  <Text as="span" textColor={textColorPrimary}>
                    Account Name
                  </Text>{" "}
                  &nbsp; &nbsp;{" "}
                  <Text as="span" fontWeight="bold">
                  {wallet!==null && wallet.bankAccountName!==null? wallet.bankAccountName: "N/A"}
                  </Text>
                </Box>
              </VStack>

              <Box width="100%" textAlign="center" mb="3">
                <Flex>
                  {" "}
                  <Image
                    h="45px"
                    w="45px"
                    src={Exchange}
                    borderRadius="8px"
                  />{" "}
                  <Heading
                    color={textColorSecondary}
                    fontSize={{ base: "md", sm: "md", md: "xl", lg: "xl" }}
                    ml={{ base: "5px", sm: "5px" }}
                    mt="3"
                    fontFamily="ManropeSemiBold"
                  >
                   $1 - {rate!==null?  new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(rate): "**" } NGN
                  </Heading>
                </Flex>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
          gap="10px"
          mb="50px"
        >
          {user !== null && wallet !== null && settings !== null ? (
            <Fiat
              wallet={wallet}
              user={user}
              orders={orders}
              verifyDepositLoading={verifyDepositLoading}
              settings={settings}
              onOpen={onOpen}
            />
          ) : (
            <LoadingFiat />
          )}
          {user !== null &&
          wallet !== null &&
          settings !== null &&
          cardLoading === false ? (
            user.kycStatus === "completed" && card !== null ? (
              <Info user={user} />
            ) : cardIssued === "false" ? (
              <KycInfo
                user={user}
                getUser={getUser}
                wallet={wallet}
                card={card}
                getVirtualCard={getVirtualCard}
                settings={settings}
                onFundingOpen={onOpen}
                page="dashboard"
              />
            ) : (
              <Technical user={user} />
            )
          ) : (
            <LoadingKyc />
          )}
        </SimpleGrid>
        <SimpleGrid columns={2} mt={2}>
          <Box>
            <Heading fontSize={{ base: "md", sm: "md", md: "lg", lg: "xl" }}>
              Transactions
            </Heading>
          </Box>
          <Box textAlign="right">
            <Link color={"blue.400"} to="/user/transactions">
              All Transactions
            </Link>
          </Box>
        </SimpleGrid>
        {transactionLoading ? (
          <LoadingTransaction />
        ) : (
          <Transactions transactions={transactions} />
        )}
      </Box>
    </>
  );
}

const LoadingFiat = () => {
  return (
    <Card align="center" p="20px">
      <Stack>
        <Skeleton height="30px" />
        <SkeletonText mt="5" noOfLines={5} spacing="4" />
        <Skeleton height="30px" />
      </Stack>
    </Card>
  );
};

const LoadingKyc = () => {
  return (
    <Card align="center" p="20px">
      <Stack>
        <Skeleton height="30px" />
        <SkeletonText mt="5" noOfLines={5} spacing="4" />
        <Skeleton height="30px" />
      </Stack>
    </Card>
  );
};

const LoadingTransaction = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};

function CopyButton({ textToCopy }) {
  const { hasCopied, onCopy } = useClipboard(textToCopy);

  return (
    <Text as="span" fontSize="xs" onClick={onCopy}>
      <Icon as={FiCopy} w="15px" h="15px" color="#000000" mt="1" mr="1" />
      {hasCopied ? "Copied!" : "Copy"}
    </Text>
  );
}
