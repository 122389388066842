import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
} from "@chakra-ui/react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

import faqOne from "assets/images/card2.png";

function FAQs() {
  return (
    <Container
      maxW={"6xl"}
      py={10}
      //background="brand.700"
      backgroundPosition="right center"
      background="#ffffff"
      backgroundRepeat="repeat"
      borderRadius="15"
    >
      <SimpleGrid width="100%" columns={{ sm: 1, md: 2 }} mt="10">
        <Box textAlign={"center"}>
          <Heading
            as="h3"
            fontSize={{ base: "xl", sm: "2xl", md: "4xl" }}
            fontFamily="TrenchThin"
            fontWeight="400"
            textAlign={"center"}
            color="#000000"
          >
            Frequently Asked <Text color="brand.500">Questions ?</Text>
          </Heading>
        </Box>
        <Box>
          <Accordion allowMultiple>
            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon as={AiOutlineMinus} boxSize={6} />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} />
                      )}
                      <Box flex="1" textAlign="left" ml="2" mt="3" mb="0.7rem">
                        <Text
                          fontSize={{
                            base: "sm",
                            sm: "md",
                            md: "lg",
                            lg: "lg",
                          }}
                          fontWeight="ManropeLight"
                          color="#000000"
                        >
                          {" "}
                          What is Swaptrex?{" "}
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text
                      fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                      fontWeight="ManropeLight"
                      color="#000000"
                    >
                      Swaptrex is a virtual card issuance platform that provides
                      virtual cards denominated in USD, a popular
                      cryptocurrency. It enables users to make seamless online
                      payments, bridging the gap between restricted bank cards
                      and international point-of-sale transactions.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon as={AiOutlineMinus} boxSize={6} />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        <Text
                          fontSize={{
                            base: "sm",
                            sm: "md",
                            md: "lg",
                            lg: "lg",
                          }}
                          fontWeight="ManropeLight"
                          color="#000000"
                        >
                          {" "}
                          How does Swaptrex work?{" "}
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{
                        base: "sm",
                        sm: "md",
                        md: "lg",
                        lg: "lg",
                      }}
                      fontWeight="ManropeLight"
                      color="#000000"
                    >
                      Swaptrex offers USD-denominated virtual cards and allows you to fund your card in your local currency. The virtual card can then be used for online purchases at any merchant accepting card payments. Swaptrex ensures secure and instant conversions and transactions for a seamless user experience.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon as={AiOutlineMinus} boxSize={6} />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        <Text
                          fontSize={{
                            base: "sm",
                            sm: "md",
                            md: "lg",
                            lg: "lg",
                          }}
                          fontWeight="ManropeLight"
                          color="#000000"
                        >
                          {" "}
                          Is Swaptrex available only in Nigeria?{" "}
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{
                        base: "sm",
                        sm: "md",
                        md: "lg",
                        lg: "lg",
                      }}
                      fontWeight="ManropeLight"
                      color="#000000"
                    >
                      No, while Swaptrex is initially launching in Nigeria,
                      there are plans to expand to other African countries in
                      the near future. Our goal is to provide access to virtual
                      cards and crypto-to-fiat spending solutions across the
                      African continent.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon as={AiOutlineMinus} boxSize={6} />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        {" "}
                        <Text
                          fontSize={{
                            base: "sm",
                            sm: "md",
                            md: "lg",
                            lg: "lg",
                          }}
                          fontWeight="ManropeLight"
                          color="#000000"
                        >
                          Can I use Swaptrex for both online and offline
                          transactions?
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{
                        base: "sm",
                        sm: "md",
                        md: "lg",
                        lg: "lg",
                      }}
                      fontWeight="ManropeLight"
                      color="#000000"
                    >
                      Swaptrex is primarily designed for online transactions, as
                      it provides virtual cards that are specifically tailored
                      for international point-of-sale payments. However, virtual
                      card details can be used for certain offline transactions
                      that accept card information without requiring physical
                      card presence.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem pt="0rem" pb="2.4rem" border="0">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      {isExpanded ? (
                        <Icon as={AiOutlineMinus} boxSize={6} />
                      ) : (
                        <Icon as={AiOutlinePlus} boxSize={6} />
                      )}
                      <Box
                        flex="1"
                        ml="2"
                        mt="3"
                        textAlign="left"
                        color="text.100"
                        mb="0.7rem"
                      >
                        {" "}
                        <Text
                          fontSize={{
                            base: "sm",
                            sm: "md",
                            md: "lg",
                            lg: "lg",
                          }}
                          fontWeight="ManropeLight"
                          color="#000000"
                        >
                          How secure is Swaptrex?
                        </Text>
                      </Box>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    fontSize="1.6rem"
                    lineHeight="2.6rem"
                    color="text.400"
                  >
                    <Text
                      fontSize={{
                        base: "sm",
                        sm: "md",
                        md: "lg",
                        lg: "lg",
                      }}
                      fontWeight="ManropeLight"
                      color="#000000"
                    >
                      Swaptrex prioritizes security and employs
                      industry-standard practices to safeguard user data and
                      funds. We utilize robust encryption techniques and adhere
                      to strict security protocols.
                    </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>



          </Accordion>
        </Box>
      </SimpleGrid>
    </Container>
  );
}

export default FAQs;
