import React from "react";
import NavBar from "components/navigation/NavBar.js";
import {
  HStack,
  VStack,
  Box,
  Text,
  Image,
  Heading,
  Stack,
  Button,
  Container,
  Hide,
  Center,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Pattern from "assets/images/pattern2.png";
import DownloadAndroid from "assets/images/downloadandroidwhite.png";
import DownloadApple from "assets/images/downloadapple.png";

const SearchForm = () => {
  return (
    <Box
      h="200px"
      p={5}
      w={{ base: "100%", md: "100%", lg: "60%" }}
      background="none"
    >
      <Center width="100%">
        <VStack align="stretch" minH="40px">
          <BannerText />
        </VStack>
      </Center>
    </Box>
  );
};

const BannerText = () => {
  return (
    <Box minH="30px" background="none">
      <Container p={0}>
        <Heading
          color="white"
          textAlign="start"
          as="h1"
          fontSize={{ base: "xl", sm: "2xl", md: "4xl", lg: "4xl" }}
          fontFamily="TrenchThin"
          fontWeight="500"
        >
          Shop and pay globally with ease using your &nbsp;
          <Text color="orange.500" as="span">
            USD{" "}
          </Text>{" "}
          virtual card
        </Heading>
        <Text
          mt={3}
          mb={1}
          color="white"
          textAlign="start"
          fontSize={{ base: "md", sm: "lg", md: "xl", lg: "xl" }}
          fontFamily="ManropeLight"
        >
          Swaptrex offers a reloadable USD virtual card for
          seamless and secure transactions
        </Text>
        {/*<Text
          mt={3}
          mb={1}
          color="white"
          textAlign="start"
          fontSize={{ base: "md", sm: "lg", md: "xl", lg: "xl" }}
          fontFamily="ManropeLight"
        >
          Payment no hard, na you dey use the wrong card
      </Text>*/}
      </Container>


      <Stack spacing={3} direction={"row"} mt="7" align="center">
        
        {/*<Link to="/auth/signup">
          <Button
            rounded={"full"}
            px={6}
            colorScheme={"orange.500"}
            bg={"orange.500"}
            _hover={{ bg: "orange.100" }}
          >
            Get Started
          </Button>
         </Link>*/}
        {/*
        <Link to="/auth/login">
          <Button rounded={"full"} px={6}>
            Login
          </Button>
        </Link>*/}
      </Stack>


      <Stack
          mt="5"
          direction={'row'}
          spacing={3}
          align={'center'}
          alignSelf={'center'}
          position={'relative'}>
          <a href="https://play.google.com/store/apps/details?id=com.swaptrex"> 
           <Image src={DownloadAndroid} width="150px" height="auto" />
          </a> 
          {/*<a href="https://apps.apple.com/ng/app/ichef-customer/id1582664187">
             <Image src={DownloadApple} width="150px" height="auto" />
           </a> */}        
        </Stack>

    </Box>
  );
};

const Illustration = () => {
  return (
    <Hide below="md">
      <Box
        h="200px"
        p={5}
        w={{ base: null, md: null, lg: "40%" }}
        background="none"
      ></Box>
    </Hide>
  );
};

const BannerWithSearch = () => {
  return (
    <Box
      w="100%"
      background="brand.500"
      backgroundImage={{ sm: "none", md: `url(${Pattern})` }}
      backgroundPosition="right center"
      backgroundSize="45% auto"
      backgroundRepeat="no-repeat"
      pb={{ sm: 0, md: 7 }}
    >
      <HStack background="none">
        <NavBar zIndex="10" />
      </HStack>
      <HStack
        minH={{ base: "250px", sm: "300px", md: "500px" }}
        pb={20}
        background="none"
      >
        <SearchForm />
        <Illustration />
      </HStack>
    </Box>
  );
};

export default BannerWithSearch;
