import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  SimpleGrid,
  Button,
  Icon,
  Text,
  FormControl,
  FormLabel,
  Flex,
  Heading,
  Input,
  Stack,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiChevronLeft, FiArrowLeft } from "react-icons/fi";
import { useSelector } from "react-redux";

import Card from "components/card/Card.js";

export default function ChangeName() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const textColorSecondary = useColorModeValue("gray.700", "gray.100");
  const inputColor = useColorModeValue("brand.500", "gray.100");

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();

  const handleChangeName = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/username",
        { firstName, lastName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUser();
        return;
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getUser = async () => {
    try {
      const { data: response } = await axios.get(SERVER_URL + "/user/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (response.success) {
        setUser(response.data);
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setLoading(false);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }} spacing="2" mb="3">
        <Box textAlign="start">
          <Heading
            bg="inherit"
            fontWeight="bold"
            fontFamily="ManropeSemiBold"
            fontSize={{ base: "md", sm: "md", md: "lg", lg: "xl" }}
            mb="5"
          >
            Change Name
          </Heading>
        </Box>
        <Box textAlign="right">
          <Link to="/user/profile">
            <Flex direction={"row-reverse"}>
              <Text mt="2" as="span">
                Back
              </Text>
              <Icon as={FiChevronLeft} w={8} h={8} mt="1" />
            </Flex>
          </Link>
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }}>
        <Card align="center" p="20px">
          <Stack
            spacing={4}
            w={"full"}
            maxW={"md"}
            bg={useColorModeValue("white", "gray.700")}
            p={6}
            my={5}
          >
            <FormControl>
              <FormLabel color={textColorSecondary} fontWeight="bold">
                First Name
              </FormLabel>
              <Input
                type="text"
                color={inputColor}
                isReadOnly={user!==null && user.kycStatus==="completed"}
                defaultValue={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                borderRadius="20"
                maxLength={20}
              />
            </FormControl>

            <FormControl>
              <FormLabel color={textColorSecondary} fontWeight="bold">
                Last Name
              </FormLabel>
              <Input
                type="text"
                color={inputColor}
                defaultValue={lastName}
                isReadOnly={user!==null && user.kycStatus==="completed"}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                borderRadius="20"
                maxLength={20}
              />
            </FormControl>

            <Button
              variant="primary"
              bg={useColorModeValue("brand.500", "gray.100")}
              color={useColorModeValue("white", "brand.500")}
              w="full"
              _hover={{
                bg: "blue.500",
                color: "white",
              }}
              borderRadius="20"
              isLoadingText="Wait.."
              disabled={user!==null && user.kycStatus==="completed"}
              isLoading={loading}
              onClick={handleChangeName}
            >
              Submit
            </Button>
          </Stack>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
