// Chakra imports
import {
    Box,
    VStack,
    HStack,
    SimpleGrid,
    StackDivider,
    Circle,
    Icon,
    Text,
    useColorModeValue,
  } from "@chakra-ui/react";
  import React from "react";
  // Custom components
  import Card from "components/card/Card.js";
  // Assets
  import { FiHash, FiHome, FiUser, FiArrowRightCircle } from "react-icons/fi";
  
  export default function Bank(props) {
  
    const { account, setAccount, setTransfer } = props;
    const textColorPrimary = useColorModeValue("secondaryGray.500", "white");
    const textColorSecondary = useColorModeValue("gray.800", "white");
    return (
      <Card align="center" p="20px" mt="10px">
        <VStack
          divider={<StackDivider borderColor="gray.200" />}
          spacing={4}
          align="stretch"
          mb="5"
        >
          <Box w="100%" minH="40px">
            <HStack>
              <Box width="15%">
                <Circle width="40px" height="40px" background="brand.500">
                  <Icon as={FiUser} width="20px" height="20px" color="white" />
                </Circle>
              </Box>
              <Box width="75%" textAlign="start">
                <Text
                  color={textColorSecondary}
                  fontWeight="bold"
                  fontSize={{ base: "sm", sm: "md", md: "md", lg: "md" }}
                >
                  {account.accountName}
                </Text>
              </Box>
              <Box width="10%" textAlign="end">
                {" "}
                <Icon
                  as={FiArrowRightCircle}
                  width="25px"
                  height="25px"
                  color={textColorSecondary}
                  onClick={(e) => {
                    setAccount(account);
                    setTransfer(true);
                  }}
                />
              </Box>
            </HStack>
          </Box>
  
          <VStack spacing="7">
            <Box w="100%" h="40px">
              <SimpleGrid columns="1">
                <Box>
                  <Text
                    textAlign="start"
                    color={textColorPrimary}
                    fontSize={{ base: "xs", sm: "xs", md: "sm", lg: "sm" }}
                  >
                    Bank Name
                  </Text>
                </Box>
                <Box>
                  <HStack>
                    <Box width="15%">
                      <Circle width="30px" height="30px" background="brand.500">
                        <Icon
                          as={FiHome}
                          width="15px"
                          height="15px"
                          color="white"
                        />
                      </Circle>
                    </Box>
                    <Box width="85%">
                      <Text
                        textAlign="start"
                        color={textColorSecondary}
                        fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                      >
                        {account.bankName}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </SimpleGrid>
            </Box>
  
            <Box w="100%" h="40px">
              <SimpleGrid columns="1">
                <Box>
                  <Text
                    textAlign="start"
                    fontSize={{ base: "xs", sm: "xs", md: "sm", lg: "sm" }}
                    color={textColorPrimary}
                  >
                    Account Number
                  </Text>
                </Box>
                <Box>
                  <HStack>
                    <Box width="15%">
                      <Circle width="30px" height="30px" background="brand.500">
                        <Icon
                          as={FiHash}
                          width="15px"
                          height="15px"
                          color="white"
                        />
                      </Circle>
                    </Box>
                    <Box width="85%">
                      <Text
                        textAlign="start"
                        color={textColorSecondary}
                        fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                      >
                        {account.accountNumber}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </SimpleGrid>
            </Box>
          </VStack>
        </VStack>
      </Card>
    );
  }
  