import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  SimpleGrid,
  Center,
  Text,
  Select,
  Spinner,
  Heading,
  PinInput,
  PinInputField,
  Button,
  FormControl,
  FormLabel,
  Input,
  Icon,
  Skeleton,
  SkeletonText,
  Stack,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Card from "components/card/Card.js";
import { FiChevronLeft } from "react-icons/fi";

export default function Security() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [pin, setPin] = useState("");
  const [currentPin, setCurrentPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [twoFaActivated, setTwoFaActivated] = useState("");
  const [firstCode, setFirstCode] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0); // 60 seconds = 1 minute
  const [showResendLink, setShowResendLink] = useState(false);

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();

  const getUser = async () => {
    try {
      const { data: response } = await axios.get(
        SERVER_URL + "/user/get2FAStatus",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      if (response.success) {
        setUser(response.data);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const activate2FA = async () => {
    try {
      if (pin === "") {
        toast({
          title: "Enter a 6 digit PIN",
          status: "warning",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (otp === "") {
        toast({
          title: "Enter a 6 Digit SMS OTP",
          status: "warning",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/activate2FA",
        { pin, otp },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUser();
        setFirstCode(false);
        setRemainingTime(0);
        setShowResendLink(false);
      }
      setLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const change2FASettings = async () => {
    try {
      if (currentPin === "") {
        toast({
          title: "Enter current PIN",
          status: "warning",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (newPin === "") {
        toast({
          title: "Enter new PIN",
          status: "warning",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (otp === "") {
        toast({
          title: "Enter SMS OTP",
          status: "warning",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/change2FA",
        { otp, currentPin, newPin },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUser();
        setNewPin("");
        setCurrentPin("");
        setFirstCode(false);
        setRemainingTime(0);
        setShowResendLink(false);
        setOtp("");
      }
      setLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getOtp = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/getOtp",
        { userId: user._id },
        {
          headers: {},
        }
      );
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setFirstCode(true);
        setRemainingTime(90);
        setShowResendLink(false);
      }
      setLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    let timerId = null;
    if (remainingTime > 0) {
      timerId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setShowResendLink(true);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [remainingTime]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }} spacing="2" mb="3">
        <Box textAlign="start">
          <Heading
            bg="inherit"
            fontWeight="bold"
            fontFamily="ManropeSemiBold"
            fontSize={{ base: "md", sm: "md", md: "lg", lg: "xl" }}
            mb="5"
          >
            Security Pin
          </Heading>
        </Box>
        <Box textAlign="right">
          <Link to="/user/profile">
            <Flex direction={"row-reverse"}>
              <Text mt="2" as="span">
                Back
              </Text>
              <Icon as={FiChevronLeft} w={8} h={8} mt="1" />
            </Flex>
          </Link>
        </Box>
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }}>
        {user !== null ? (
          user.pin === null ? (
            <Activate2FA
              user={user}
              otp={otp}
              setOtp={setOtp}
              getOtp={getOtp}
              activate2FA={activate2FA}
              loading={loading}
              setLoading={setLoading}
              showResendLink={showResendLink}
              remainingTime={remainingTime}
              firstCode={firstCode}
              setPin={setPin}
            />
          ) : (
            <Change2FA
              user={user}
              loading={loading}
              setOtp={setOtp}
              otp={otp}
              getOtp={getOtp}
              setCurrentPin={setCurrentPin}
              setNewPin={setNewPin}
              change2FASettings={change2FASettings}
              showResendLink={showResendLink}
              remainingTime={remainingTime}
              firstCode={firstCode}
            />
          )
        ) : (
          <AccountsLoading />
        )}
      </SimpleGrid>
    </Box>
  );
}

const Change2FA = (props) => {
  const {
    loading,
    setOtp,
    otp,
    getOtp,
    firstCode,
    showResendLink,
    remainingTime,
    change2FASettings,
    setCurrentPin,
    setNewPin,
  } = props;
  const textColorSecondary = useColorModeValue("gray.800", "white");
  const inputColor = useColorModeValue("brand.500", "gray.100");

  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");

  useEffect(() => {
    setOtp(pin1 + "" + pin2 + "" + pin3 + "" + pin4 + "" + pin5 + "" + pin6);
  }, [pin1, pin2, pin3, pin4, pin5, pin6, otp]);

  return (
    <>
      <Card align="center" p="20px">
        <SimpleGrid columns="1">
          {/*<Box pt="2">
             <Text fontWeight="bold" color={textColorSecondary}>
              Security PIN
             </Text>{" "}
            </Box>*/}
          <Box pt="2">
            <Text fontWeight="bold">Change your security PIN</Text>
          </Box>

          <Box pt="5">
            <FormControl id="2fa" isRequired>
              <FormLabel color={textColorSecondary} fontWeight="bold">
                Current Pin
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                maxLength={6}
                placeholder="Current Pin"
                onChange={(e) => {
                  setCurrentPin(e.target.value);
                }}
              />
            </FormControl>
          </Box>

          <Box pt="5">
            <FormControl id="2fa" isRequired>
              <FormLabel color={textColorSecondary} fontWeight="bold">
                New Pin
              </FormLabel>
              <Input
                type="number"
                color={inputColor}
                maxLength={6}
                placeholder="New Pin"
                onChange={(e) => {
                  setNewPin(e.target.value);
                }}
              />
            </FormControl>
          </Box>

          <Box pt="3" pb="5">
            <Box width="100%">
              <Text textAlign="center" fontSize="14" color={textColorSecondary}>
                Enter SMS OTP
              </Text>
            </Box>
            <Center width="100%">
              <PinInput otp>
                <PinInputField
                  color={inputColor}
                  onChange={(e) => {
                    setPin1(e.target.value);
                  }}
                />
                <PinInputField
                  color={inputColor}
                  onChange={(e) => {
                    setPin2(e.target.value);
                  }}
                />
                <PinInputField
                  color={inputColor}
                  onChange={(e) => {
                    setPin3(e.target.value);
                  }}
                />
                <PinInputField
                  color={inputColor}
                  onChange={(e) => {
                    setPin4(e.target.value);
                  }}
                />
                <PinInputField
                  color={inputColor}
                  onChange={(e) => {
                    setPin5(e.target.value);
                  }}
                />
                <PinInputField
                  color={inputColor}
                  onChange={(e) => {
                    setPin6(e.target.value);
                  }}
                />
              </PinInput>
              {showResendLink ? (
                firstCode ? (
                  <Button size="xs" onClick={getOtp}>
                    Send
                  </Button>
                ) : (
                  <Button size="xs" onClick={getOtp}>
                    Send
                  </Button>
                )
              ) : (
                <Text>&nbsp;{remainingTime}s </Text>
              )}
            </Center>
          </Box>

          <Box pt="1" pb="5">
            <Button
              variant="primary"
              bg={useColorModeValue("brand.500", "gray.100")}
              color={useColorModeValue("white", "brand.500")}
              isLoading={loading}
              loadingText="Wait"
              w="full"
              mt="5"
              borderRadius="20"
              onClick={(e) => {
                e.preventDefault();
                change2FASettings();
              }}
              _hover={{
                bg: "blue.500",
                color: "white",
              }}
            >
              Submit
            </Button>
          </Box>
        </SimpleGrid>
      </Card>
    </>
  );
};

const Activate2FA = (props) => {
  const {
    activate2FA,
    otp,
    setOtp,
    setPin,
    firstCode,
    getOtp,
    showResendLink,
    remainingTime,
    setLoading,
    loading,
  } = props;

  const textColorSecondary = useColorModeValue("gray.800", "white");
  const inputColor = useColorModeValue("brand.500", "gray.100");

  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");

  useEffect(() => {
    setOtp(pin1 + "" + pin2 + "" + pin3 + "" + pin4 + "" + pin5 + "" + pin6);
  }, [pin1, pin2, pin3, pin4, pin5, pin6, otp]);

  return (
    <>
      <Card p="20px">
        <SimpleGrid columns="1">
          <Box pt="2">
            <Text fontWeight="bold" color={textColorSecondary}>
              Create Security PIN
            </Text>{" "}
          </Box>
          <Box pt="2">
            <Text>
              To make your account safer, you need to create a personal 6 digit
              pin below
            </Text>
          </Box>

          <Box pt="3" pb="5" mt="3">
            <FormControl id="phone">
              <FormLabel fontFamily="ManropeSemiBold">Pin</FormLabel>
              <Input
                type="number"
                color={inputColor}
                maxLength={6}
                placeholder="123456"
                onChange={(e) => {
                  setPin(e.target.value);
                }}
              />
            </FormControl>
          </Box>

          <Box pt="3" pb="5">
            <Box width="100%">
              <Text textAlign="center" fontSize="14" color={textColorSecondary}>
                Enter SMS OTP
              </Text>
            </Box>
            <Center width="100%">
              <PinInput otp>
                <PinInputField
                  color={inputColor}
                  onChange={(e) => {
                    setPin1(e.target.value);
                  }}
                />
                <PinInputField
                  color={inputColor}
                  onChange={(e) => {
                    setPin2(e.target.value);
                  }}
                />
                <PinInputField
                  color={inputColor}
                  onChange={(e) => {
                    setPin3(e.target.value);
                  }}
                />
                <PinInputField
                  color={inputColor}
                  onChange={(e) => {
                    setPin4(e.target.value);
                  }}
                />
                <PinInputField
                  color={inputColor}
                  onChange={(e) => {
                    setPin5(e.target.value);
                  }}
                />
                <PinInputField
                  color={inputColor}
                  onChange={(e) => {
                    setPin6(e.target.value);
                  }}
                />
              </PinInput>
              {showResendLink ? (
                firstCode ? (
                  <Button size="xs" onClick={getOtp}>
                    Send
                  </Button>
                ) : (
                  <Button size="xs" onClick={getOtp}>
                    Send
                  </Button>
                )
              ) : (
                <Text>&nbsp;{remainingTime}s </Text>
              )}
            </Center>
          </Box>

          <Box pt="1" pb="5">
            <Button
              variant="primary"
              bg={useColorModeValue("brand.500", "gray.100")}
              color={useColorModeValue("white", "brand.500")}
              isLoading={loading}
              loadingText="Wait"
              w="full"
              mt="5"
              borderRadius="20"
              onClick={(e) => {
                e.preventDefault();
                setLoading(true);
                activate2FA();
              }}
              _hover={{
                bg: "blue.500",
                color: "white",
              }}
            >
              Submit
            </Button>
          </Box>
        </SimpleGrid>
      </Card>
    </>
  );
};

const AccountsLoading = () => {
  return (
    <>
      <Card align="center" p="20px" mt="10px">
        <Stack>
          <Skeleton height="30px" />
          <SkeletonText mt="5" noOfLines={5} spacing="4" />
          <Skeleton height="30px" />
        </Stack>
      </Card>
    </>
  );
};
