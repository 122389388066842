import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Heading,
  Skeleton,
  Stack,
  SkeletonText,
  SimpleGrid,
  Box,
  useToast,
  useColorModeValue
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Transactions from "views/admin/transactions/components/Transactions";

import Paginate from "components/navigation/Paginate.js";
import Basic from "./components/Basic.js";
import Card from "components/card/Card.js";

export default function Profile() {
  const { id } = useParams();
  const userId = id;
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [cardLoading, setCardLoading] = useState(true);
  const [transactionLoading, setTransactionLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [card, setCard] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState("createdAt");

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const toast = useToast();

  const textColorSecondary = useColorModeValue("gray.800", "white");

  const getUser = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.get(
        SERVER_URL + "/user/me/" + userId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setUser(response.data);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getTransactions = async () => {
    try {
      setTransactionLoading(true);
      const { data: response } = await axios.get(
        SERVER_URL +
          "/transaction/" +
          userId +
          "?from=" +
          from +
          "&limit=" +
          limit +
          "&orderBy=" +
          orderBy +
          "&sortOrder=" +
          sortOrder,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTransactionLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setTransactions(response.data.transactions);
      }
      setTransactionLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setTransactionLoading(false);
    }
  };

  const getVirtualCard = async () => {
    try {
      setCardLoading(true);
      const { data: response } = await axios.get(
        SERVER_URL + "/wallet/virtualcard/"+userId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCardLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "info",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setCard(response.data);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setCardLoading(false);
    }
  };

  const freezeVirtualCard = async () => {
    try {
      setCardLoading(true);
      const { data: response } = await axios.get(
        SERVER_URL + "/wallet/freezeVirtualCard/"+userId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCardLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getVirtualCard();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setCardLoading(false);
    }
  };

  const unfreezeVirtualCard = async () => {
    try {
      setCardLoading(true);
      const { data: response } = await axios.get(
        SERVER_URL + "/wallet/unfreezeVirtualCard/"+userId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCardLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getVirtualCard();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setCardLoading(false);
    }
  };

  const changeUserStatus = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.get(
        SERVER_URL + "/user/changeUserStatus/"+user._id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUser();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getTransactions();
    getVirtualCard();
  }, [user]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Basic user={user} getUser={getUser} loading={loading} card={card} unfreezeVirtualCard={unfreezeVirtualCard} freezeVirtualCard={freezeVirtualCard} changeUserStatus={changeUserStatus}/>

      <SimpleGrid columns={2} mt={2}>
        <Box>
          <Heading
            color={textColorSecondary}
            fontSize={{ base: "md", sm: "md", md: "lg", lg: "xl" }}
            ml={{ base: "5px", sm: "5px" }}
            fontFamily="ManropeSemiBold"
          >
            Transactions
          </Heading>
        </Box>
        <Box textAlign="right"></Box>
      </SimpleGrid>
      {transactionLoading ? (
        <LoadingTransaction />
      ) : (
        <Transactions transactions={transactions} />
      )}
      {transactions.length > 0 && (
        <Box width="100%" mt="5">
          <Paginate
            pageCount={pageCount}
            setFrom={setFrom}
            getRecords={getTransactions}
          />
        </Box>
      )}
    </Box>
  );
}

const LoadingTransaction = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};
